import { type InputProps } from 'react-admin'

import { CurrencySymbol } from 'components/money'
import {
    integerMask,
    integerNonNegativeSpacedMask,
    integerPositiveMask,
    integerSpacedMask,
    type MaskType,
    paymentCheckMask,
    priceMask,
    priceNonNegativeMask,
    quantityMask,
    quantityPositiveMask,
    upperCaseMask,
} from 'core'
import { IMaskInput } from 'core/masks'
import { type TextFieldProps } from 'ui'

interface MaskExtraParams extends Pick<TextFieldProps, 'inputProps' | 'InputProps'> {}

export const inputMask = (params: MaskType) => {
    return ({ inputProps, InputProps }: MaskExtraParams = {}): Partial<TextFieldProps> &
        Partial<InputProps> => {
        return {
            InputProps: {
                ...InputProps,
                inputComponent: IMaskInput,
            },
            inputProps: {
                ...inputProps,
                ...params,
            },
        }
    }
}

export const inputIntegerMask = inputMask(integerMask)
export const inputIntegerMaskParams = inputIntegerMask()

export const inputIntegerSpacedMask = inputMask(integerSpacedMask)
export const inputIntegerSpacedMaskParams = inputIntegerSpacedMask()

export const inputIntegerPositiveMask = inputMask(integerPositiveMask)
export const inputIntegerPositiveMaskParams = inputIntegerPositiveMask()

export const inputIntegerNonNegativeSpacedMask = inputMask(integerNonNegativeSpacedMask)
export const inputIntegerNonNegativeSpacedMaskParams = inputIntegerNonNegativeSpacedMask()

export const inputPaymentCheckMask = inputMask(paymentCheckMask)
export const inputPaymentCheckMaskParams = inputPaymentCheckMask()
export const inputQtyMask = inputMask(quantityMask)
export const inputQtyMaskParams = inputQtyMask()
export const inputQtyPositiveMask = inputMask(quantityPositiveMask)
export const inputQtyPositiveMaskParams = inputQtyPositiveMask()

export const inputQuantityMask = inputMask(quantityMask)
export const inputQuantityMaskParams = inputQuantityMask()

export const inputPriceMask = inputMask(priceMask)
export const inputPriceMaskParams = inputPriceMask()

export const inputPriceNonNegativeMask = inputMask(priceNonNegativeMask)
export const inputPriceNonNegativeMaskParams = inputPriceNonNegativeMask()

export const customInputIntegerNonNegativeSpacedMask = (value: number, extra?: MaskExtraParams) => {
    const newMask = { ...integerNonNegativeSpacedMask, max: Number('9'.repeat(value)) }
    const inputCustomMask = inputMask(newMask)
    return inputCustomMask(extra)
}
const textInputCostMaskExtraParams = (startAdornment): MaskExtraParams => ({
    InputProps: {
        startAdornment,
    },
})

const costMaskExtraParams = textInputCostMaskExtraParams(<CurrencySymbol />)
const costMaskExtraParamsDiscount = textInputCostMaskExtraParams(<CurrencySymbol discount />)
const percentageMaskExtraParams = textInputCostMaskExtraParams('%')

export const costMaskParams = inputPriceMask(costMaskExtraParams)
export const percentageMaskParams = inputPriceMask(percentageMaskExtraParams)
export const costMaskParamsDiscount = inputPriceMask(costMaskExtraParamsDiscount)
export const costNonNegativeMaskParams = inputPriceNonNegativeMask(costMaskExtraParams)
export const costDisabledNonNegativeMaskParams = inputPriceNonNegativeMask(costMaskExtraParams)

export const inputUppercaseMask = inputMask(upperCaseMask)
export const inputUppercaseMaskParams = inputUppercaseMask()
