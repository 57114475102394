import images from 'assets/images'
import { NoResultsCard } from 'components'
import { useShowContext } from 'core'
import { useUnitPMDrawerEditor } from 'resources/pm'
import { type UnitModel, isUnitArchived } from 'resources/units'
import { Anchor } from 'ui'

const UnitPMEmpty = ({ type }: { type: 'dependent' | 'independent' }) => {
    const open = useUnitPMDrawerEditor()
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    return (
        <NoResultsCard
            direction="row"
            height="160px"
            title="No PM"
            imageSrc={images.meter}
            imageProps={{
                width: '107px',
                height: '107px',
                mr: '32px',
            }}
            action={
                !isArchived && (
                    <Anchor
                        variant="body1"
                        onClick={() => open({ type })}
                    >
                        Create Interval
                    </Anchor>
                )
            }
        />
    )
}

export default UnitPMEmpty
