import { type ReactElement, type FC } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'

import { unitStatusResource } from '../utils'

import UnitStatusForm from './UnitStatusForm'

interface UnitStatusDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
}

const UnitStatusDrawerToggler: FC<UnitStatusDrawerTogglerProps> = ({ children, id }) => {
    const open = useOpenUtilityDrawer()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Unit Status' : 'Create Unit Status',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={serializer}
                    />
                ),
                renderContent: () => <UnitStatusForm />,
                renderBottomRight: (render) => render({ label: id ? undefined : 'Create status' }),
            },
            extraArgs: {
                id,
                resource: unitStatusResource,
            },
        })
    })
}

export default UnitStatusDrawerToggler

const serializer: Serializer = ['name', 'color', 'isDefault']
