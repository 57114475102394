import { type ComponentType, type FC } from 'react'

import { authStore } from 'core/auth'
import { renderOnFlag } from 'lib'

/**
 ** hides component body if billing is disabled
 */
export const renderOnBilling = <P = any,>(Component: ComponentType<P>): FC<P> =>
    renderOnFlag(() => !!authStore.billing, Component)
