import Icons from 'assets/icons'
import { DateInput } from 'components'
import {
    useShowContext,
    api,
    requiredValidation,
    updatedMessage,
    useConfirm,
    useNotify,
} from 'core'
import { dateTimeParse } from 'lib'
import {
    invoiceFields,
    type InvoiceModel,
    invoicesResource,
    InvoiceStatusActionKeys,
    InvoiceStatusKeys,
} from 'resources/invoices'
import { PrimaryButton } from 'ui'

const CloseInvoiceButton = () => {
    const { record } = useShowContext<InvoiceModel>()
    const confirm = useConfirm()
    const notify = useNotify()
    if (record?.status !== InvoiceStatusKeys.OPEN) {
        return null
    }

    return (
        <PrimaryButton
            onClick={() =>
                confirm({
                    title: 'Close Invoice',
                    closeOnError: false,
                    content: <CloseContent />,
                    onConfirm: async ({ formValues }) => {
                        await api.post(
                            invoicesResource.statusAction(record.id, InvoiceStatusActionKeys.CLOSE),
                            {
                                [invoiceFields.invoiceDate.source]: dateTimeParse(
                                    formValues[invoiceFields.invoiceDate.source],
                                ),
                            },
                        )
                        notify(updatedMessage)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                    awaitOnConfirm: true,
                    formProps: {
                        defaultValues: {
                            [invoiceFields.invoiceDate.source]: new Date(),
                        },
                    },
                })
            }
            disabled={!record.itemsCount}
            startIcon={<Icons.DoneOutlined />}
        >
            Close Invoice
        </PrimaryButton>
    )
}

export default CloseInvoiceButton

const CloseContent = () => {
    return (
        <>
            <DateInput
                validate={requiredValidation}
                source={invoiceFields.invoiceDate.source}
                label={invoiceFields.invoiceDate.label}
            />
        </>
    )
}
