import SelectInput from 'components/inputs/SelectInput'
import { formHelperTextClasses } from 'ui'

import { useListPure } from '../ListContext'

const orders = [
    { id: 'ASC', label: 'Ascending' },
    { id: 'DESC', label: 'Descending' },
]

// if label is ReactElement and it is created inside component, this throws error ???
// this is why it needs to be function when we need component
const sourceOptionText = (data) => {
    const label = data.label
    return typeof label === 'function' ? label() : label
}

const ListSortDrawerContent = () => {
    const state = useListPure()

    return (
        <>
            <SelectInput
                clearable={false}
                source="field"
                label="Column/Label"
                sx={{
                    marginBottom: '16px',
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                choices={state.config.sorts}
                optionText={sourceOptionText}
                helperText={false}
                disableEmptyValue
            />

            <SelectInput
                clearable={false}
                source="order"
                label="Sort Order"
                sx={{
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                choices={orders}
                optionText="label"
                helperText={false}
                disableEmptyValue
            />
        </>
    )
}

export default ListSortDrawerContent
