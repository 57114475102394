import { parseNumber, type Serializer } from 'core'
import { type WorkOrderModel } from 'resources/workOrders'

export const workOrderSerializer: Serializer<WorkOrderModel & { poNumber: string }> = [
    { name: 'unit', parse: ['emptyToNull', 'disableOnEdit'] },
    'priority',
    'repairPriorityClass',
    {
        name: 'started',
        parse: 'dateTime',
    },
    'description',
    { name: 'shop', parse: 'emptyToNull' },
    {
        name: 'vendor',
        parse: (value) => {
            if (value) {
                return value
            }
            return undefined
        },
    },
    'poNumber',
    {
        name: 'metersStart',
        parse: (value, data) => {
            return Object.keys(value).reduce((acc, meterType) => {
                if (data.unitData.settings[meterType]?.tracked) {
                    acc[meterType] = parseNumber(value[meterType])
                }
                return acc
            }, {})
        },
    },
]
