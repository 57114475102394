import { type UnitModel } from 'resources/units'

import { type SinceLastServiceTrackWidgetLabelProps } from '../components/Label'

type dataSinceLastRepairParams = {
    lastRepairMeters: UnitModel['lastCompletedWorkOrderMeters']
    meters: UnitModel['lastMeters']
    lastWorkOrder: UnitModel['lastCompletedWorkOrder']
}

type funcType = ({
    lastRepairMeters,
    meters,
}: dataSinceLastRepairParams) => SinceLastServiceTrackWidgetLabelProps

const getSinceLastRepairData: funcType = ({ lastRepairMeters, meters, lastWorkOrder }) => {
    // Calculate Distance
    let distanceSinceLastService = null
    const firstValidMeter = meters?.ODOMETER
    const firstValidRepairMeter = lastRepairMeters?.find((meter) => meter.type === 'ODOMETER')
    if (firstValidMeter && firstValidRepairMeter) {
        distanceSinceLastService =
            firstValidMeter && firstValidRepairMeter
                ? firstValidMeter.value - firstValidRepairMeter.value
                : null
    }

    // Calculate Time
    const lastWorkOrderCompletionDate = new Date(lastWorkOrder.completed)
    const currentTime = new Date()
    const differenceInTime = currentTime.getTime() - lastWorkOrderCompletionDate.getTime()
    const timeSinceLastService = differenceInTime / (1000 * 3600 * 24)

    return { distanceSinceLastService, timeSinceLastService }
}
export default getSinceLastRepairData
