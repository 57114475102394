import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { urls } from 'configs'
import { useListContext } from 'core'
import { inventorySerializer } from 'pages/Inventory/config'
import { type InventoryItem, inventoryItemsResource } from 'resources/inventory'
import { LinkButton } from 'ui'
import { pathJoin } from 'utils'

import InventoryItemFormContent, {
    type InventoryItemFormContentProps,
} from './InventoryItemFormContent'

interface InventoryItemDrawerTogglerProps extends InventoryItemFormContentProps {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    selectedPartId?: Identifier
}

export const InventoryItemDrawerToggler: FC<InventoryItemDrawerTogglerProps> = ({
    id,
    children,
    selectedPartId,
}) => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()

    return children({
        onClick: () =>
            open({
                drawerArgs: {
                    title: id ? 'Edit Inventory' : 'Add to Inventory',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            defaultValues={{
                                part: selectedPartId,
                            }}
                            successMessage={({ defaultMessages, response }) => {
                                if (id) {
                                    return defaultMessages.updated
                                }

                                const data = response as InventoryItem

                                return {
                                    title: (
                                        <>
                                            <LinkButton to={pathJoin(urls.inventory, data.id)}>
                                                {data.partData.number}
                                            </LinkButton>{' '}
                                            has been added to the {data.shopData.name} inventory.
                                        </>
                                    ),
                                }
                            }}
                            serializer={inventorySerializer}
                            {...params}
                        />
                    ),
                    renderContent: () => (
                        <InventoryItemFormContent hidePartDetails={Boolean(selectedPartId || id)} />
                    ),
                    renderBottomRight: (render) =>
                        id ? render() : render({ icon: <Icons.Add />, label: 'Add' }),
                },
                extraArgs: {
                    listContext,
                    resource: inventoryItemsResource,
                    id,
                },
            }),
    })
}
