import { reaction } from 'mobx'

import { authStore, type AuthStore } from 'core'

export const registerResource = <R = any>(sub: (store: AuthStore) => R, cb: (value: R) => void) => {
    reaction(
        () => sub(authStore),
        (v) => {
            if (authStore.user) {
                cb(v)
            } else {
                // After logout setting the value reacts faster than the auth guard
                // so we need to wait a bit to make sure the view is updated
                setTimeout(() => cb(v), 100)
            }
        },
    )
}
