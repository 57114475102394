import { type NoInfer } from 'appTypes'
import { type FilterBy, type SortBy, type Column, type DataListDetails } from 'components'

import { StateRow } from '../components/StateRow'
import { type State } from '../types'

const source = 'state' as const
const label = 'State/Province'

const value = (record: State) => {
    if (!record) {
        return null
    }

    return <StateRow record={record} />
}

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies FilterBy

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => State
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row)),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => State
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const self = {
    source,
    label,
    value,
    filter,
    sort,
    tableColumn,
    dataCardRow,
}

export default self
