import { type FC } from 'react'

import { observer } from 'mobx-react'

import { resolveIntegerSpacedMask } from 'components/format'
import { authStore } from 'core/auth'
import { CountryCodes } from 'resourcesBase'
import { capitalizeWithLowerCase } from 'utils'

interface DistanceLabelProps {
    /**
     * The value of distance
     */
    value?: number

    /**
     * If true, the label will be pluralized.
     */
    plural?: boolean

    /**
     * If true, the first letter will be capitalized.
     */
    textCase?: 'upper' | 'lower' | 'capital'

    /**
     * If true, the label will be abbreviated.
     */
    variant?: Variant
}

export const getDistanceLabel = ({ variant, plural, value, textCase }: DistanceLabelProps = {}) => {
    const countryAbbr = authStore.currentCompany.countryData?.id
    const config = variants[countryAbbr] || variants[CountryCodes.USA]
    let word = config[variant] || config.standard

    if (plural) {
        word += 's'
    }

    if (textCase === 'capital') {
        word = capitalizeWithLowerCase(word)
    } else if (textCase === 'upper') {
        word = word.toUpperCase()
    }

    if (value != null) {
        word = resolveIntegerSpacedMask(value) + ' ' + word
    }

    return word
}

export const useDistanceLabel = (config: DistanceLabelProps) => {
    return getDistanceLabel(config)
}

export const DistanceValueLabel: FC<{ children: number } & Omit<DistanceLabelProps, 'value'>> = ({
    children,
    ...props
}) => {
    if (children == null) {
        return null
    }

    return (
        <DistanceLabel
            value={children}
            {...props}
        />
    )
}

export const DistanceLabel: FC<DistanceLabelProps> = observer((props) => {
    const word = useDistanceLabel(props)

    return <>{word}</>
})

type Variant = 'standard' | 'abbr' | 'short-abbr'

const variants: { [key in CountryCodes]: { [key in Variant]: string } } = {
    [CountryCodes.USA]: {
        'short-abbr': 'm',
        abbr: 'mi',
        standard: 'mile',
    },
    [CountryCodes.CA]: {
        'short-abbr': 'km',
        abbr: 'km',
        standard: 'kilometer',
    },
}
