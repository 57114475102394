import { type Identifier } from 'appTypes'
import { urls } from 'configs'
import { LinkButton } from 'ui'

import { type IssueModel } from '../types'

const navigateTo = (id: Identifier) => urls.issues + '/' + id
const value = (record: Pick<IssueModel, 'id' | 'name'>, link: boolean = true) => {
    if (!link) {
        return <>{record.name}</>
    }

    return <LinkButton to={navigateTo(record.id)}>{record.name}</LinkButton>
}

const source: keyof IssueModel = 'name'

const name = {
    source,
    label: 'Issue Name',
    value,
    navigateTo,
}

export default name
