import { Typography, type TypographyProps } from '@mui/material'

import { useModifiers } from 'context'

const Text: typeof Typography = (props) => {
    const modifiers = useModifiers<TextModifiers>()

    return <Typography {...modifiers('text', props)} />
}

export default Text

export interface TextModifiers {
    text: TypographyProps
}
