import { type FC } from 'react'

import { SelectInput } from 'components/inputs'
import { formHelperTextClasses } from 'ui'

import { type FilterBy } from '../types'

interface Props {
    source: string
    options: FilterBy<any>[]
    onChange: () => void
}

const FilterSourceInput: FC<Props> = ({ source, options, onChange }) => {
    return (
        <SelectInput
            clearable={false}
            source={source}
            label="Column/Label"
            sx={{
                [`& .${formHelperTextClasses.root}`]: {
                    display: 'none',
                },
            }}
            onChange={onChange}
            helperText={false}
            choices={options}
            optionText="label"
            disableEmptyValue
            className="filter-source-input"
        />
    )
}

export default FilterSourceInput
