import { type NoInfer } from 'appTypes'
import {
    type FilterBy,
    FilterRangeInput,
    type SortBy,
    costMaskParams,
    formatMoney,
    type Column,
} from 'components'

import { type UnitModel } from '../types'

const source = 'costPerHour' satisfies keyof UnitModel

const label = 'Cost per Engine Hour'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => formatMoney(dataToValue(row)),
        align: 'right',
    }) as const satisfies Column

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies FilterBy

export const costPerHour = {
    source,
    label,
    tableColumn,
    sort,
    filter,
}
