import { type FC } from 'react'

import { ListBase, useListContext } from 'react-admin'

import { type ObjectAny, type WithChildrenNode } from 'appTypes'
import { useResourcePreferences } from 'core/context'
import { useDidUpdate } from 'hooks'

import { useListConfig, useListSnap } from './ListContext'
import { getPerPage } from './utils'

export interface SecondLayerProps extends WithChildrenNode {
    resource?: string
    filterValues?: ObjectAny
    disableSyncWithLocation?: boolean
    filter?: ObjectAny
    isLoading?: boolean
}

export const SecondLayer: FC<SecondLayerProps> = ({
    children,
    resource,
    filterValues,
    disableSyncWithLocation,
    filter,
    isLoading,
}) => {
    const snap = useListSnap()
    const preferences = useResourcePreferences()

    const perPage = getPerPage(snap.view, preferences.value.perPage)

    return (
        <ListBase
            perPage={perPage}
            // sort={getSort(snap, preferences.value)}
            // filterDefaultValues={getDefaultFilter(snap, preferences.value, filterValues)}
            sort={snap.config.sort}
            filterDefaultValues={filterValues}
            resource={resource}
            disableSyncWithLocation={disableSyncWithLocation}
            filter={filter}
            queryOptions={{
                enabled: !isLoading,
            }}
        >
            {children}
            <ChangePerPage />
        </ListBase>
    )
}

const ChangePerPage = () => {
    const { snap } = useListConfig()
    const listContext = useListContext()
    const view = snap.view
    const preferences = useResourcePreferences()

    useDidUpdate(() => {
        listContext.setPage(1)
        listContext.setPerPage(getPerPage(view, preferences.value.perPage))
    }, [view])

    // useEffect(() => {
    //     state.loadingCounter += listContext.isLoading ? 1 : -1
    // }, [listContext.isLoading])

    return null
}

// const getSort = (snap: State, preferences: ResourcePreferences) => {
//     const savedSort = preferences.sort

//     if (savedSort && snap.config.sorts?.some((s) => s.id === savedSort.field)) {
//         return savedSort
//     }

//     return snap.config.sort
// }

// const getDefaultFilter = (snap: State, preferences: ResourcePreferences, filterValues) => {
//     const savedFilter = preferences.filter

//     if (!savedFilter) {
//         return filterValues
//     }

//     const initialFilters = {}

//     snap.config.filters?.forEach(({ id }) => {
//         if (id in savedFilter) {
//             initialFilters[id] = savedFilter[id]
//         }
//     })

//     if (filterValues) {
//         return Object.assign(initialFilters, filterValues)
//     }

//     return initialFilters
// }
