import { InfoCard, InfoCardHeader } from 'components'
import { useRecordContext } from 'core'
import { Typography } from 'ui'

interface Model {
    cancellationReason: string
    status: 'CANCELED' | string
}

const ReasonForCancelation = <RecordType extends Model>() => {
    const record = useRecordContext<RecordType>()

    if (!record || record.status !== 'CANCELED') {
        return null
    }

    return (
        <InfoCard sx={{ mb: '24px' }}>
            <InfoCardHeader title="Reason for cancellation" />
            <Typography
                variant="body2"
                color="text.primary"
            >
                {record.cancellationReason}
            </Typography>
        </InfoCard>
    )
}

export default ReasonForCancelation
