import {
    FilterValueInput,
    type ListControllerProps,
    formatMoney,
    type CardListConfig,
    type DatagridColumnsProps,
} from 'components'
import { getListData, ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { LineItemTypeKeys } from 'resources/lineItems'
import {
    type CK14Aspects,
    ck14Aspects,
    ck14Resource,
    type CK14Aggregates,
    type CK14Model,
    ck14Fields,
} from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { PageContent } from 'ui'

import { ListControllerWithPeriod, ListUiWithPeriod, ReportsHeader } from '../components'

const aspects = [ck14Aspects.totals] as CK14Aspects[]

interface CK14ReportsModel extends CK14Model<typeof aspects> {}

const defaultSort: SortPayload<CK14ReportsModel> = {
    field: 'total',
    order: 'DESC',
}

const cardsCfg: CardListConfig<CK14ReportsModel> = {
    titleSource: ck14Fields.self.value,
    disableTitleLink: true,
    defaultImage: null,
    details: [
        { source: 'type', label: 'Repair Type' },
        woFields.total.dataCardRow(LineItemTypeKeys.PART),
        woFields.total.dataCardRow(LineItemTypeKeys.LABOR),
        woFields.total.dataCardRow(LineItemTypeKeys.SERVICE),
        woFields.total.dataCardRow(LineItemTypeKeys.FEE),
        woFields.total.dataCardRow(LineItemTypeKeys.TAX),
        woFields.total.dataCardRow(),
    ],
}
const columnsCfg: DatagridColumnsProps<CK14ReportsModel> = {
    checkboxSelection: false,
    constantColumns: {
        type: true,
        [woFields.total.getConfig().source]: true,
    },
    mainField: 'codeDescription',
    pinnedColumns: {
        left: ['type'],
        right: [woFields.total.getConfig().source],
    },
    columns: [
        {
            field: 'codeDescription',
            headerName: 'Reason for Repair',
        },
        {
            field: 'type',
            headerName: 'Repair Type',
        },
        woFields.total.tableColumn(LineItemTypeKeys.PART),
        woFields.total.tableColumn(LineItemTypeKeys.LABOR),
        woFields.total.tableColumn(LineItemTypeKeys.SERVICE),
        woFields.total.tableColumn(LineItemTypeKeys.FEE),
        woFields.total.tableColumn(LineItemTypeKeys.TAX),
        woFields.total.tableColumn(),
    ],
    actions: null,
}
const filters: ListControllerProps<CK14ReportsModel>['filters'] = [
    {
        id: 'codeDescription',
        label: 'Reason for Repair',
        withOperator: true,
        renderComponent: (params) => <FilterValueInput {...params} />,
    },
    {
        id: 'type',
        label: 'Repair Type',
    },
    woFields.total.filter(LineItemTypeKeys.PART),
    woFields.total.filter(LineItemTypeKeys.LABOR),
    woFields.total.filter(LineItemTypeKeys.SERVICE),
    woFields.total.filter(LineItemTypeKeys.FEE),
    woFields.total.filter(LineItemTypeKeys.TAX),
    woFields.total.filter(),
]

const sorts: ListControllerProps<CK14ReportsModel>['sorts'] = [
    { id: 'codeDescription', label: 'Reason for Repair' },
    { id: 'type', label: 'Repair Type' },
    woFields.total.sort(LineItemTypeKeys.PART),
    woFields.total.sort(LineItemTypeKeys.LABOR),
    woFields.total.sort(LineItemTypeKeys.SERVICE),
    woFields.total.sort(LineItemTypeKeys.FEE),
    woFields.total.sort(LineItemTypeKeys.TAX),
    woFields.total.sort(),
]

const CostPerReasonForRepairCK14 = () => {
    return (
        <ResourceContextProviderWithClearEffect value={ck14Resource}>
            <ListControllerWithPeriod
                sort={defaultSort}
                filter={{ withAspects: aspects }}
                filters={filters}
                sorts={sorts}
            >
                <ReportsHeader<CK14ReportsModel>
                    renderTotal={(list) => {
                        const listData = getListData<CK14Aggregates>(list)
                        return (
                            <>
                                {list.total} | {formatMoney(listData.total || 0)} total
                            </>
                        )
                    }}
                >
                    Cost per Reason for Repair
                </ReportsHeader>
                <PageContent>
                    <ListUiWithPeriod
                        exportFileName="cost-per-reason-for-repair"
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListControllerWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CostPerReasonForRepairCK14
