import Icons from 'assets/icons'
import { IconButton, SvgIcon, HideOnBreakpoint, Typography, BoxContainer, Button } from 'ui'

import { useListConfig } from '../../ListContext'

const FilterButton = () => {
    const { snap, state } = useListConfig()

    const isApplied = snap.filter.isApplied

    const Icon = isApplied ? Icons.FilterActive : Icons.FilterAltOutlined

    return (
        <BoxContainer>
            <HideOnBreakpoint
                render={(match) => (
                    <>
                        <Typography
                            component="div"
                            color="text"
                        >
                            {match ? (
                                <Button
                                    variant="text"
                                    color="inherit"
                                    onClick={state.functions.filter.open}
                                    startIcon={
                                        <SvgIcon
                                            inheritViewBox
                                            component={Icon}
                                        />
                                    }
                                >
                                    Filters
                                </Button>
                            ) : (
                                <IconButton
                                    aria-label="Open Filter"
                                    size="small"
                                    color="inherit"
                                    onClick={state.functions.filter.open}
                                >
                                    <SvgIcon
                                        inheritViewBox
                                        component={Icon}
                                        sx={{ width: '20px', height: '20px' }}
                                    />
                                </IconButton>
                            )}
                        </Typography>
                        {match && isApplied && (
                            <IconButton
                                aria-label="Reset Filter"
                                size="small"
                                onClick={state.functions.filter.reset}
                            >
                                <Icons.Close />
                            </IconButton>
                        )}
                    </>
                )}
            />
        </BoxContainer>
    )
}

export default FilterButton
