import { type FC } from 'react'

import Icons from 'assets/icons'
import { ListTotalBadge, ViewHeader } from 'components'
import { useRecordContext, getListData } from 'core'
import { AssignmentsDrawer, type AssignmentAggregates } from 'resources/assignments'
import { type UnitModel } from 'resources/units'
import { IconBox } from 'ui'

const Header: FC = () => {
    const record = useRecordContext<UnitModel>()
    return (
        <ViewHeader
            title="Assignments"
            loading={!record}
        >
            <ViewHeader.Content>
                <ListTotalBadge
                    renderContent={(listContext) => {
                        const { ongoingCount, completedCount } =
                            getListData<AssignmentAggregates>(listContext)
                        return `${ongoingCount || 0} Ongoing | ${completedCount || 0} Completed`
                    }}
                />
            </ViewHeader.Content>
            <ViewHeader.Content placement="rightMobile">
                {!record?.archived && (
                    <AssignmentsDrawer defaultValues={{ unit: record?.id }}>
                        {(open) => (
                            <IconBox onClick={open}>
                                <Icons.Add />
                            </IconBox>
                        )}
                    </AssignmentsDrawer>
                )}
            </ViewHeader.Content>
            <ViewHeader.Content />
        </ViewHeader>
    )
}

export default Header
