import { type FC } from 'react'

import { CombinedInputs, Form, PasswordInput } from 'components'
import {
    useAuthProvider,
    passwordValidation,
    useSubmit,
    requiredValidation,
    validatePasswordMatch,
    type Validator,
} from 'core'
import { useParams } from 'lib'
import { Typography } from 'ui'

import { AuthBody, AuthSubmitButton, NoAccountText } from '../components'

const sources = {
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword',
}

const passwordMatchValidation: Validator[] = [
    requiredValidation,
    validatePasswordMatch(sources.newPassword),
]

const ResetPasswordConfirm: FC = () => {
    const authProvider = useAuthProvider()
    const { uid, token } = useParams()

    const handleSubmit = useSubmit(
        async (formData) => {
            await authProvider.resetPasswordConfirm(formData[sources.newPassword], uid, token)
        },
        {
            successMessage: 'Password has been changed successfully!',
        },
    )

    return (
        <AuthBody>
            <Typography
                variant="h6"
                mb="20px"
            >
                Reset Password
            </Typography>
            <Typography
                variant="body2"
                mb="24px"
            >
                To recover access, enter your new password.
            </Typography>
            <Form
                onSubmit={handleSubmit}
                warnWhenUnsavedChanges={false}
            >
                <CombinedInputs
                    sources={[sources.confirmPassword]}
                    track={[sources.newPassword]}
                />
                <PasswordInput
                    source={sources.newPassword}
                    label="New Password"
                    helperText="Use at least 8 characters"
                    validate={passwordValidation}
                />
                <PasswordInput
                    source={sources.confirmPassword}
                    label="Confirm New Password"
                    validate={passwordMatchValidation}
                />

                <AuthSubmitButton label="Set Password and Login" />
            </Form>

            <NoAccountText mt="23px" />
        </AuthBody>
    )
}

export default ResetPasswordConfirm
