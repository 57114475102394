import { type FC } from 'react'

import { SelectInput } from 'components/inputs'
import { formHelperTextClasses } from 'ui'

import { filterOperators } from '../utils'

interface Props {
    source: string
}

const OperatorInput: FC<Props> = ({ source }) => {
    return (
        <SelectInput
            choices={choices}
            label="Operator"
            defaultValue={filterOperators.any}
            disableEmptyValue
            clearable={false}
            source={source}
            sx={{
                [`& .${formHelperTextClasses.root}`]: {
                    display: 'none',
                },
            }}
        />
    )
}

export default OperatorInput

const choices = [
    {
        id: filterOperators.any,
        name: 'Is Any of',
    },
    {
        id: filterOperators.none,
        name: 'Is None of',
    },
]
