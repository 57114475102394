import { type NoInfer } from 'appTypes'
import { type FilterBy, type SortBy } from 'components'
import { createResource } from 'core'

export const costCenterResource = createResource({
    name: 'cost-centers',
    resource: 'cost-centers',
})

const _source = 'costCenter'
const _label = 'Cost Center'

export const costCenterFilter = <Source extends string = typeof _source>({
    id,
    label,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || _source) as NoInfer<Source>,
        label: label || _label,
    }) as const satisfies FilterBy

export const costCenterSort = <Source extends string = typeof _source>({
    id,
    label,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || _source) as NoInfer<Source>,
        label: label || _label,
    }) as const satisfies SortBy
