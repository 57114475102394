import { ViewHeader } from 'components'
import { useShowContext } from 'core'
import { PurchaseOrderCardSkeleton } from 'pages/PurchaseOrders/components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { VendorCard } from 'resources/vendors'
import { GridContainerColumns, GridItemLayout, Section } from 'ui'

const VendorSection = () => {
    const { record } = useShowContext<PurchaseOrderModel>()

    return (
        <Section>
            <ViewHeader
                title="Vendor"
                loading={!record}
            />
            <GridContainerColumns>
                <GridItemLayout>
                    {record ? (
                        <VendorCard vendorData={record.vendorData} />
                    ) : (
                        <PurchaseOrderCardSkeleton height="219px" />
                    )}
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}

export default VendorSection
