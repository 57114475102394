import { type ReactElement, type ReactNode, type FC } from 'react'

import { type SvgIconElement, type WithChildrenNode } from 'appTypes'
import { globalClassNames, withColor } from 'lib'
import { avatarClasses, Grid, GridContainer, IconElement, Stack, Typography } from 'ui'

interface OptionProps extends WithChildrenNode {}

const Option = ({ children }: OptionProps) => {
    return (
        <Stack
            gap="8px"
            width="100%"
        >
            {children}
        </Stack>
    )
}

interface TitleProps extends WithChildrenNode {
    avatar?: ReactElement
}

const Title: FC<TitleProps> = ({ children, avatar }) => {
    return (
        <Stack
            alignItems="center"
            direction="row"
            gap="12px"
            sx={{
                [`& .${avatarClasses.root}`]: {
                    width: '42px',
                    height: '42px',
                },
            }}
        >
            {avatar}
            <Typography variant="subtitle1">{children}</Typography>
        </Stack>
    )
}

interface DetailsProps {
    children: ReactNode
}

const Details: FC<DetailsProps> = ({ children }) => {
    return (
        <Stack
            gap="8px"
            typography={(theme) => theme.typography.body2}
            sx={{
                '&:empty': {
                    display: 'none',
                },
            }}
        >
            {children}
        </Stack>
    )
}

interface RowProps {
    children: ReactNode
    label: string
    Icon: SvgIconElement
}

const Row: FC<RowProps> = ({ children, label, Icon }) => {
    return (
        <GridContainer spacing="12px">
            <Grid
                item
                xs={4}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="6px"
                    color={withColor('text.secondary')}
                >
                    <IconElement
                        component={Icon}
                        size="20px"
                    />
                    <div className={globalClassNames.ellipsis}>{label}</div>
                </Stack>
            </Grid>
            <Grid
                item
                xs={8}
                color={withColor('text.primary')}
                sx={{
                    wordWrap: 'break-word',
                }}
            >
                {children}
            </Grid>
        </GridContainer>
    )
}

export const SelectorOption = Object.assign(Option, {
    Title,
    Details,
    Row,
})
