import { useEffect, useMemo } from 'react'

import { proxy } from 'valtio'

import { type WithChildrenNode, type DataRecord } from 'appTypes'
import { useResourcePreferences } from 'core/context'
import { useMediaQuery } from 'lib'

import { ListProvider } from './ListContext'
import { type ListControllerProps, ListView, type State } from './types'
import { _defaultSort } from './utils'

export const FirstLayer = <RecordType extends DataRecord>({
    children,
    filters,
    sort,
    sorts,
}: ListControllerProps<RecordType> & WithChildrenNode) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const getView = (view: ListView) => (isSmall ? ListView.CARD : view)
    const preferences = useResourcePreferences()

    const state: State<any> = useMemo(() => {
        let initialView = ListView.TABLE
        if (typeof preferences.value.showColumns === 'boolean') {
            initialView = preferences.value.showColumns ? ListView.TABLE : ListView.CARD
        }

        return proxy<State<any>>({
            view: getView(initialView),
            _view: initialView,
            filter: {
                isApplied: false,
                isOpen: false,
            },
            sort: false,
            isDefaultSorted: true,
            config: {
                filters: removeEmpty(filters),
                sort: sort || _defaultSort,
                sorts: removeEmpty(sorts),
            },
            functions: {
                view: {
                    change: (view) => {
                        state._view = view
                        state.view = view
                        preferences.updateLocal('showColumns', view === ListView.TABLE)
                        preferences.syncLocal()
                    },
                },
                filter: {} as any,
                sort: {} as any,
            },
        })
    }, [])

    useEffect(() => {
        Object.assign(state.config, {
            filters: removeEmpty(filters),
            sorts: removeEmpty(sorts),
        })
    }, [filters, sorts])

    useEffect(() => {
        state.view = getView(state._view)
    }, [isSmall])

    return <ListProvider value={state}>{children}</ListProvider>
}

const removeEmpty = (arr: any[]) => arr?.filter(Boolean)
