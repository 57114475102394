import Icons from 'assets/icons'
import { useArrayControllerContext } from 'components/ArrayController'
import { Button } from 'ui'

import { type ArrayExtra } from '../types'

const FilterClearButton = () => {
    const { setArray, extra } = useArrayControllerContext()

    return (
        <Button
            size="large"
            variant="text"
            color="error"
            disabled={(extra as ArrayExtra).isFilterEmpty}
            startIcon={<Icons.DeleteOutlineOutlined fontSize="large" />}
            onClick={() => {
                setArray([])
            }}
        >
            CLEAR ALL
        </Button>
    )
}

export default FilterClearButton
