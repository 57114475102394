import { compareAsc, parseISO } from 'date-fns'
import { required, email, maxLength, minLength, regex, type Validator } from 'react-admin'

import { getProperty } from 'utils'

const URL_REGEX =
    /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const validateEmail = (isRequired = false): Validator[] => {
    const validators: Validator[] = [email('Enter a Valid Email Address')]

    if (isRequired) {
        validators.push(required({ message: 'Email is required' }))
    }

    return validators
}

export const emailValidation = validateEmail()

export const validateLessThan =
    (maxNum: number, message: string = `Value should be less than ${maxNum}`): Validator =>
    (value) =>
        value != null && value >= maxNum ? message : undefined

export const validateNonNegative =
    (message: string = 'Value should be non-negative'): Validator =>
    (value) =>
        value != null && value < 0 ? message : undefined
export const validateMaxNum =
    (maxNum: number, message: string = `Value should be less or equal to ${maxNum}`): Validator =>
    (value) =>
        value != null && value > maxNum ? message : undefined

export const validatePassword = (length: number | false = 8): Validator[] => {
    const validators: Validator[] = [required({ message: 'Password is required' })]

    if (length) {
        validators.push(minLength(length, `Use at least ${length} characters`))
    }

    return validators
}
export const nonNegativeValtion = validateNonNegative()
export const passwordValidation = validatePassword()

export const validateMaxLength = (length: number, text?: string): Validator =>
    maxLength(length, text || `Must be ${length} characters or less`)

export const validateDateBefore =
    (source: string, text?: string): Validator =>
    (value, values) =>
        compareAsc(parseISO(value), new Date(getProperty(values, source))) === -1
            ? text || 'Selected date cannot be before starting date'
            : undefined

export const maxLengthValidation = validateMaxLength(70)
export const maxLengthValidationText = validateMaxLength(3000)

export const requiredValidation = required('Required field')

export const url = (message = 'Please enter a valid URL!') => regex(URL_REGEX, message)

export const urlValidation = url()

export const validatePasswordMatch =
    (passwordSource: string = 'password'): Validator =>
    (value, values) => {
        const newPassword = values[passwordSource]
        if (value !== newPassword) {
            return 'Passwords do not match'
        }
        return ''
    }

export const passwordMatchValidation = validatePasswordMatch()

export { Validator }
