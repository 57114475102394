import { type FC } from 'react'

import { inject } from 'mobx-react'

import { SkeletonSliderCardContainer, ViewHeader, SliderView } from 'components'
import { useRecordContext, type AuthStore } from 'core'
import { CustomerSection } from 'resources/customers'
import { useIsVenderWo, type WorkOrderModel } from 'resources/workOrders'
import { Stack, Skeleton, GridContainer, GridItemLayout, Section } from 'ui'

import { WorkOrderEditButton } from '../../components'

import {
    CanceledInfo,
    ClosedInfo,
    DatePriorityClass,
    DescriptionCreatedOnBy,
    ReasonForCancelation,
} from './Slides'
import VendorSection from './VendorSection'
import { CanceledWorkOrderPurchaseInvoice } from './WorkOrderPurchaseInvoice'

const slides = [
    <DatePriorityClass key="date-priority-class" />,
    <DescriptionCreatedOnBy key="description-created-on-by" />,
]
const skeletonSlides = new Array(2).fill(0).map((v, i) => {
    return (
        <SkeletonSliderCardContainer
            key={i}
            sx={{
                height: '112px',
            }}
        >
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
})
const WorkOrderDetails: FC = inject('auth')(({ auth }: { auth: AuthStore }) => {
    const workOrder = useRecordContext<WorkOrderModel>()
    const isStatusCancel = workOrder?.status === 'CANCELED'
    const isStatusClosed = workOrder?.status === 'CLOSED'
    const isVendorWorkOrder = useIsVenderWo()

    return (
        <Section>
            <ViewHeader
                title="Details"
                loading={!workOrder}
            >
                <ViewHeader.Content placement="rightMobile">
                    <WorkOrderEditButton />
                </ViewHeader.Content>
            </ViewHeader>
            {isStatusCancel ? (
                <>
                    <GridContainer>
                        <GridItemLayout>
                            <Stack spacing="24px">
                                <ReasonForCancelation />
                                <CanceledInfo />
                                {slides}
                                {auth.companySettings.hasCustomers &&
                                    workOrder?.salesOrderData?.customerData && (
                                        <CustomerSection
                                            customer={workOrder?.salesOrderData?.customerData}
                                        />
                                    )}
                                {isVendorWorkOrder && <VendorSection />}
                                <CanceledWorkOrderPurchaseInvoice />
                            </Stack>
                        </GridItemLayout>
                    </GridContainer>
                </>
            ) : (
                <SliderView
                    items={
                        workOrder
                            ? isStatusClosed
                                ? [...slides, <ClosedInfo />]
                                : slides
                            : skeletonSlides
                    }
                />
            )}
        </Section>
    )
})

export default WorkOrderDetails
