import { useEffect, useRef } from 'react'

import { useFormContext } from 'react-hook-form'

import { ArrayControllerElements, useArrayControllerContext } from 'components/ArrayController'

import FilterBox from './FilterBox'
import { type FilterFormElement } from './types'

const FilterContent = () => {
    const { unregister } = useFormContext()
    return (
        <>
            <ArrayControllerElements<FilterFormElement>
                itemKey={({ item }) => item.id}
                onDelete={({ item }) => {
                    if (item.name) {
                        unregister('values.' + item.name)
                    }
                }}
            >
                <FilterBox />
            </ArrayControllerElements>
            <SrollOnAdd />
        </>
    )
}

export default FilterContent

const SrollOnAdd = () => {
    const ref = useRef<HTMLDivElement>()
    const { array } = useArrayControllerContext()
    const count = array.length
    const prefCount = useRef(count)

    useEffect(() => {
        if (count > prefCount.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }

        prefCount.current = count
    }, [count])

    return <div ref={ref} />
}
