import { inject } from 'mobx-react'
import { useResourceContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { ListController, type ListControllerProps, ListUi, datagridAvatarColumn } from 'components'
import {
    basePermissions,
    useResourcePermissions,
    renderOnPermission,
    useFinalErrorHandler,
    type AuthStore,
    type ActionChildren,
    deleteManyFromListAction,
    deleteOneAction,
    multiselectAction,
    useNotify,
    api,
} from 'core'
import { memberFields, membersPermissions } from 'resources/members'
import { MemberInvitationStatusKeys, type MemberModel } from 'resourcesBase'
import { DataAvatar, PageContent } from 'ui'

import { EditMemberAction } from '../components'

import { MembersListHeader } from './compoents'

import type { DatagridColumnsProps, CardListConfig, ListBulkActions } from 'components'

const deleteMemberAction = {
    confirmConfig: {
        title: 'Are you sure you want to delete the selected User?',
    },
}

const bulkActions: ListBulkActions = ({ children }) => {
    return [
        deleteManyFromListAction({
            children,
            confirmConfig: {
                title: 'Are you sure want to delete the selected Users?',
            },
        }),
    ]
}

const ResendAction = renderOnPermission(
    ({ children, id }: { children: ActionChildren; id: Identifier }) => {
        const notify = useNotify()
        const resource = useResourceContext()
        const errorHandler = useFinalErrorHandler()

        const resend = async () => {
            try {
                await api.post(resource + '/' + id + '/resend_invitation')
                notify('Invitation sent')
            } catch (err) {
                errorHandler(err)
            }
        }
        return children({
            Icon: Icons.RefreshOutlined,
            title: 'Resend',
            onClick: resend,
        })
    },
    membersPermissions.resendInvitation,
)

const MembersList = inject('auth')(({ auth }: { auth?: AuthStore }) => {
    const memberId = auth.user.id
    const permissions = useResourcePermissions()

    const isMemberLoggedUser = (member: MemberModel) => member.userId === memberId
    const isFullRole =
        permissions[membersPermissions.resendInvitation] &&
        permissions[basePermissions.update] &&
        permissions[basePermissions.create]

    const sorts: ListControllerProps<MemberModel>['sorts'] = [
        { id: memberFields.email.source, label: memberFields.email.label },
        { id: memberFields.name.source, label: memberFields.name.label },
        { id: memberFields.role.source, label: memberFields.role.label },
        { id: memberFields.phone.source, label: memberFields.phone.label },
        auth.companySettings.hasDomiciles
            ? {
                  id: memberFields.viewUnitsWithoutDomicile.source,
                  label: memberFields.viewUnitsWithoutDomicile.label,
              }
            : null,
        {
            id: memberFields.shops.source,
            label: memberFields.shops.label,
        },
        {
            id: memberFields.invitationStatus.source,
            label: memberFields.invitationStatus.label,
        },
        {
            id: memberFields.invitationCreated.source,
            label: memberFields.invitationCreated.label,
        },
        { id: memberFields.lastLogin.source, label: memberFields.lastLogin.label },
    ]

    const columnsCfg: DatagridColumnsProps<MemberModel> = {
        checkboxSelection: isFullRole,
        mainField: memberFields.email.source,
        avatarSource: memberFields.avatar.source,
        columns: [
            datagridAvatarColumn({
                field: memberFields.avatar.source,
                headerName: memberFields.avatar.label,
                avatar: (record) => (
                    <DataAvatar
                        imageSrc={record.avatar}
                        defaultImage={<memberFields.avatar.Value record={record} />}
                    />
                ),
            }),
            {
                field: memberFields.email.source,
                headerName: memberFields.email.label,
            },
            {
                field: memberFields.name.source,
                headerName: memberFields.name.label,
            },
            {
                field: memberFields.role.source,
                headerName: memberFields.role.label,
                renderCell: ({ row }) => memberFields.role.value(row),
            },
            {
                field: memberFields.phone.source,
                headerName: memberFields.phone.label,
                renderCell: ({ row }) => memberFields.phone.value(row),
            },
            auth.companySettings.hasDomiciles
                ? {
                      field: memberFields.viewUnitsWithoutDomicile.source,
                      headerName: memberFields.viewUnitsWithoutDomicile.label,
                      renderCell: ({ row }) => memberFields.viewUnitsWithoutDomicile.value(row),
                  }
                : null,
            {
                field: memberFields.shops.source,
                headerName: memberFields.shops.label,
                renderCell: ({ row }) => memberFields.shops.value(row),
            },
            {
                field: memberFields.invitationStatus.source,
                headerName: memberFields.invitationStatus.label,
                renderCell: ({ row }) => memberFields.invitationStatus.value(row),
            },
            {
                field: memberFields.invitationCreated.source,
                headerName: memberFields.invitationCreated.label,
                renderCell: ({ row }) => memberFields.invitationCreated.value(row),
            },
            {
                field: memberFields.lastLogin.source,
                headerName: memberFields.lastLogin.label,
                renderCell: ({ row }) => memberFields.lastLogin.value(row),
            },
        ],
        // TODO: refactor. Allow actions to disable if column 'Actions' is visible
        actions: isFullRole
            ? ({ row: member }, { children }) => {
                  const isLoggedUser = isMemberLoggedUser(member)

                  return [
                      <EditMemberAction
                          id={member.id}
                          key="edit"
                      >
                          {(editMember) =>
                              children({
                                  Icon: Icons.Edit,
                                  title: 'View/Edit',
                                  onClick: editMember,
                              })
                          }
                      </EditMemberAction>,
                      deleteOneAction({
                          children: (params) =>
                              children({
                                  ...params,
                                  disabled: isLoggedUser || params.disabled,
                                  titleOnDisabled: isLoggedUser
                                      ? 'Own account cannot be deleted'
                                      : undefined,
                              }),
                          id: member.id,
                          ...deleteMemberAction,
                      }),
                      member.invitationStatus === MemberInvitationStatusKeys.ACCEPTED ? null : (
                          <ResendAction
                              key="resend"
                              id={member.id}
                              children={(params) =>
                                  children({
                                      ...params,
                                      disabled: isLoggedUser || params.disabled,
                                  })
                              }
                          />
                      ),
                  ]
              }
            : null,
    }

    const cardsCfg: CardListConfig<MemberModel> = {
        titleSource: (record) => memberFields.email.value(record),
        subTitleSource: (record) => memberFields.role.value(record),
        disableTitleLink: true,
        imageSource: memberFields.avatar.source,
        defaultImage: (record) => <memberFields.avatar.Value record={record} />,
        details: [
            {
                source: memberFields.name.source,
                label: memberFields.name.label,
            },
            {
                source: memberFields.phone.source,
                label: memberFields.phone.label,
                render: (v, record) => memberFields.phone.value(record),
            },
            auth.companySettings.hasDomiciles
                ? {
                      source: memberFields.viewUnitsWithoutDomicile.source,
                      label: memberFields.viewUnitsWithoutDomicile.label,
                      render: (_, record) => memberFields.viewUnitsWithoutDomicile.value(record),
                  }
                : null,
            {
                source: memberFields.shops.source,
                label: memberFields.shops.label,
                render: (v, record) => memberFields.shops.value(record),
            },
            {
                source: memberFields.invitationStatus.source,
                label: memberFields.invitationStatus.label,
                render: (v, record) => memberFields.invitationStatus.value(record),
            },
            {
                source: memberFields.invitationCreated.source,
                label: memberFields.invitationCreated.label,
                render: (v, record) => memberFields.invitationCreated.value(record),
            },
            {
                source: memberFields.lastLogin.source,
                label: memberFields.lastLogin.label,
                render: (v, record) => memberFields.lastLogin.value(record),
            },
        ],
        actionsDisabled: () => !isFullRole,
        actions: (member, { children }) => {
            const isLoggedUser = isMemberLoggedUser(member)

            return [
                <EditMemberAction
                    id={member.id}
                    key="edit"
                >
                    {(editMember) =>
                        children({
                            Icon: Icons.Edit,
                            title: 'View/Edit',
                            onClick: editMember,
                        })
                    }
                </EditMemberAction>,
                multiselectAction({
                    children,
                    id: member.id,
                }),
                deleteOneAction({
                    children,
                    id: member.id,
                    disabled: isLoggedUser,
                    titleOnDisabled: isLoggedUser ? 'Own account cannot be deleted' : undefined,
                    ...deleteMemberAction,
                }),
                member.invitationStatus === MemberInvitationStatusKeys.ACCEPTED ? null : (
                    <ResendAction
                        key="resend"
                        id={member.id}
                        children={children}
                    />
                ),
            ]
        },
    }

    return (
        <ListController<MemberModel> sorts={sorts}>
            <MembersListHeader />
            <PageContent>
                <ListUi
                    exportFileName="users"
                    bulkActions={bulkActions}
                    columnsCfg={columnsCfg}
                    cardsCfg={cardsCfg}
                    listFTUProps={{
                        linkText: 'Add New Users',
                        secondaryTitle: 'Please, Add New Users',
                    }}
                />
            </PageContent>
        </ListController>
    )
})

export default MembersList
