import { type FC } from 'react'

import { urls } from 'configs'
import { useShowContext, useCreateResourcePath, type ActionChildren } from 'core'
import { Link } from 'lib'
import { inventoryFields, useInventoryContext } from 'resources/inventory'
import { type PartModel, partFields, partsResource } from 'resources/parts'

interface Props {
    children: ActionChildren
}

const ViewPartAction: FC<Props> = ({ children }: Props) => {
    const createPath = useCreateResourcePath()
    const inventoryView = useInventoryContext()
    const { record } = useShowContext<PartModel>()

    if (inventoryView) {
        const path = createPath({
            resource: partsResource.resource,
            id: record.id,
            type: 'edit',
        })
        return (
            <Link to={path}>
                {children({
                    Icon: partFields.avatar.Icon,
                    title: 'See in Catalog',
                })}
            </Link>
        )
    }

    if (record.inventoryCount) {
        const path = createPath({
            resource: urls.inventory,
            type: 'list',
            queryParams: {
                filter: JSON.stringify({
                    partNumber: [record.number],
                }),
            },
        })
        return (
            <Link to={path}>
                {children({
                    // TODO: Do we need 2 icons here?
                    Icon: inventoryFields.avatar.Icon,
                    title: 'See in Inventory',
                })}
            </Link>
        )
    }

    return children({
        Icon: inventoryFields.avatar.Icon,
        title: 'See in Inventory',
        disabled: true,
    })
}

export default ViewPartAction
