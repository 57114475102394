import { type FC } from 'react'

import { DateTimeInput } from 'components'
import { requiredValidation } from 'core'
import { MeterInput, type UnitMeterTypes } from 'resources/units'

const UnitMeterForm: FC<{ type: UnitMeterTypes }> = ({ type }) => {
    return (
        <>
            <DateTimeInput
                source="timestamp"
                label="Date & Time"
                validate={requiredValidation}
                defaultValue={new Date()}
            />
            <MeterInput
                source="value"
                label="Value"
                required
                type={type}
            />
        </>
    )
}

export default UnitMeterForm
