import { Order } from 'appTypes'
import { type SortPayload } from 'core'

import { ListView } from './types'

export const getPerPage = (view: ListView, preference: number = undefined): number => {
    if (view === ListView.CARD) {
        return 100
    }

    if (preference) {
        return preference
    }

    return 10
}

export const _defaultSort: SortPayload = {
    field: 'created',
    order: Order.DESC,
}

export const filterOperators = {
    any: '__',
    none: '!',
}
