import { type FC, type ReactElement, useEffect, useRef } from 'react'

import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

import { type Size } from 'appTypes'
import { useNavigate, cssVariables, matchPath, useParams } from 'lib'
import {
    ToggleButton,
    ToggleButtonGroup,
    toggleButtonClasses,
    PageContentSection,
    SimpleScrollbar,
} from 'ui'
import { pathJoin } from 'utils'

export interface InPageTabProps {
    value: string | number
    label: ReactElement | string | number
}

interface Props {
    tabs: InPageTabProps[]
    tabMinWidth?: Size
    basePath: string
}

const InPageTabs: FC<Props> = ({ tabs, tabMinWidth, basePath }) => {
    const navigate = useNavigate()
    const groupRef = useRef<HTMLDivElement>()
    const pageContentRef = useRef<HTMLDivElement>()
    const params = useParams()
    const tabValue = tabs.find((tab) =>
        tab
            ? matchPath(
                  {
                      path: `/${tab.value}`,
                  },
                  `/${params['*']}`,
              )
            : false,
    )

    const selectedValue = tabValue?.value

    useEffect(() => {
        const selectedElement = groupRef.current.querySelector(`.${toggleButtonClasses.selected}`)
        if (!selectedElement) {
            return
        }

        smoothScrollIntoView(selectedElement, {
            // Without this it scrolls to top on page load
            boundary: pageContentRef.current,
        })
    }, [selectedValue])

    return (
        <PageContentSection
            pt={`var(${cssVariables.pageContentPadding})`}
            ref={pageContentRef}
        >
            <SimpleScrollbar sx={{ pb: '10px' }}>
                <ToggleButtonGroup
                    sx={{ px: `var(${cssVariables.pageContentPadding})` }}
                    value={selectedValue}
                    exclusive
                    ref={groupRef}
                    onChange={(e, value) => {
                        if (value == null) {
                            return
                        }
                        navigate({ pathname: pathJoin(basePath, value) })
                    }}
                >
                    {tabs.map((tab) => (
                        <Tab
                            {...tab}
                            minWidth={tabMinWidth}
                            key={tab.value}
                        />
                    ))}
                </ToggleButtonGroup>
            </SimpleScrollbar>
        </PageContentSection>
    )
}

export default InPageTabs

interface TabProps extends InPageTabProps {
    minWidth?: Size
}

const Tab: FC<TabProps> = ({ label, minWidth, value, ...rest }) => {
    return (
        <ToggleButton
            {...rest}
            sx={{ gap: '6px', minWidth, [cssVariables.iconSize]: '16px', whiteSpace: 'nowrap' }}
            size="small"
            value={value}
        >
            {label}
        </ToggleButton>
    )
}
