import { type FC } from 'react'

import Icons from 'assets/icons'
import { TextareaInput } from 'components'
import {
    api,
    useConfirm,
    useNotify,
    maxLengthValidationText,
    requiredValidation,
    serialize,
    updatedMessage,
    useRecordContext,
    type Validator,
} from 'core'
import { type IssueModel, issuesResource } from 'resources/issues'
import { Stack } from 'ui'

import ActionWhileInProgressText from './ActionWhileInProgressText'

const textAreaValidation: Validator[] = [requiredValidation, maxLengthValidationText]

interface Props {
    children: (action: () => void) => JSX.Element
}

interface FormValues extends Pick<IssueModel, 'reasonClosed'> {}

const ManualResolve: FC<Props> = ({ children }) => {
    const confirm = useConfirm()
    const notify = useNotify()
    const record = useRecordContext<IssueModel>()

    return children(() => {
        confirm({
            title: 'Resolve Issue?',
            content: (
                <Stack gap="24px">
                    <ActionWhileInProgressText
                        record={record}
                        action="resolved"
                    />
                    <TextareaInput<IssueModel>
                        validate={textAreaValidation}
                        source="reasonClosed"
                        label="Reason for Resolve"
                    />
                </Stack>
            ),
            confirmButtonProps: {
                children: 'Resolve',
                startIcon: <Icons.HowToRegOutlined />,
                color: 'error',
                variant: 'text',
            },
            onConfirm: async (params) => {
                const formValues = params.formValues as FormValues
                await api.post(
                    issuesResource.close(record.id),
                    serialize<FormValues>(formValues, ['reasonClosed']),
                )
                notify(updatedMessage)
            },
        })
    })
}

export default ManualResolve
