import { ViewHeader } from 'components'
import { GridContainerColumns, GridItemLayout, PageContent } from 'ui'

import { WoPoCard } from '../../components'

const Overview = () => {
    return (
        <PageContent>
            <GridContainerColumns>
                <GridItemLayout>
                    <ViewHeader title="Purchase Order" />
                    <WoPoCard />
                </GridItemLayout>
            </GridContainerColumns>
        </PageContent>
    )
}
export default Overview
