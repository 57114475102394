import { useRecordContext } from 'core'
import { Monitor } from 'pages/Dashboard/components/Monitor/MonitorComponents'
import { useMonitor } from 'pages/Dashboard/components/Monitor/Monitors/useMonitor'
import { SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import { createReportResource } from 'resources/reports'
import { type UnitModel } from 'resources/units'
import { LinkButton, StatusText, BoxContainer } from 'ui'

interface DataType {
    overdue: number
    planned: number
    dueSoon: number
}

export const UnitExpirationsWidget = () => {
    const record = useRecordContext<UnitModel>()
    const data = useMonitor<DataType>(
        createReportResource(`expirations-by-status`),
        {
            unit: record?.id,
        },
        Boolean(record?.id),
    )

    if (!data || !record) {
        return <SliderWidgetSkeleton />
    }
    const isExpirations = Boolean(data.overdue + data.planned + data.dueSoon)

    const content = isExpirations ? (
        <>
            <Monitor.ColumnsContainer>
                <BoxContainer gap="4px">
                    <Monitor.ValueText>{data.overdue || 0}</Monitor.ValueText>
                </BoxContainer>
                <StatusText statusColor={(theme) => theme.palette.charts.red}>Overdue</StatusText>
            </Monitor.ColumnsContainer>
            <Monitor.ColumnsContainer>
                <BoxContainer gap="4px">
                    <Monitor.ValueText>{data.dueSoon || 0}</Monitor.ValueText>
                </BoxContainer>
                <StatusText statusColor={(theme) => theme.palette.charts.orange}>
                    Due Soon
                </StatusText>
            </Monitor.ColumnsContainer>
            <Monitor.ColumnsContainer>
                <BoxContainer gap="4px">
                    <Monitor.ValueText>{data.planned || 0}</Monitor.ValueText>
                </BoxContainer>
                <StatusText statusColor={(theme) => theme.palette.charts.greenBody}>
                    Planned
                </StatusText>
            </Monitor.ColumnsContainer>
        </>
    ) : (
        <Monitor.ColumnsContainer>
            <Monitor.ValueText>None</Monitor.ValueText>
            <LinkButton
                to="unit-expirations"
                variant="componentButtonSmall"
            >
                ADD EXPIRATION
            </LinkButton>
        </Monitor.ColumnsContainer>
    )
    return (
        <Monitor>
            <Monitor.Title>Expirations</Monitor.Title>
            <Monitor.Container>{content}</Monitor.Container>
        </Monitor>
    )
}
