import Icons from 'assets/icons'
import { useArrayControllerContext } from 'components/ArrayController'
import { Button } from 'ui'

const FilterAddButton = () => {
    const { append } = useArrayControllerContext()

    return (
        <Button
            size="large"
            variant="text"
            startIcon={<Icons.Add fontSize="large" />}
            onClick={() => append()}
        >
            Add Filter
        </Button>
    )
}

export default FilterAddButton
