import { type FC, type ReactElement, type ReactNode } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { cssVariables, withColor } from 'lib'
import { BoxContainer, Stack, Typography } from 'ui'

interface Props {
    children: ReactNode
    label: ReactElement
}

const Row: FC<Props> = ({ children, label }) => {
    return (
        <BoxContainer
            justifyContent="space-between"
            height="24px"
        >
            <Typography
                variant="chartsBody"
                component="div"
                color={withColor('text.primary')}
                sx={{
                    [cssVariables.gap]: '8px',
                    [cssVariables.iconSize]: '14px',
                }}
            >
                {label}
            </Typography>
            <Typography
                variant="chartsBody"
                component="div"
                color={withColor('text.primary')}
            >
                {children || '-'}
            </Typography>
        </BoxContainer>
    )
}

export default Row

export const Rows: FC<WithChildrenNode> = ({ children }) => {
    return <Stack gap="4px">{children}</Stack>
}
