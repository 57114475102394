import { type NoInfer } from 'appTypes'
import { type FilterBy, FilterValueInput, type SortBy, type Column } from 'components'

import { type InvoiceModel, InvoiceTypeKeys } from '../types'

const source = 'type' as const satisfies keyof InvoiceModel
const label = 'Invoice Type'

const config: { [key in InvoiceTypeKeys]: string } = {
    [InvoiceTypeKeys.STANDARD]: 'Direct Invoice',
    [InvoiceTypeKeys.WORK_ORDER]: 'Work Order',
}

interface Data extends Pick<InvoiceModel, 'type'> {}

const value = (record: Data) => config[record.type]

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={(record) => {
                    return config[record.id]
                }}
            />
        ),
    }) as const satisfies FilterBy

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const invoiceType = {
    value,
    source,
    label,
    filter,
    tableColumn,
    sort,
}

export default invoiceType
