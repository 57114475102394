import { type FC } from 'react'

import { CardDefaultSkeleton } from 'components'
import { useListContext } from 'core'
import { GridContainerGrid, GridItemCard, PageContent } from 'ui'

import { type PaymentModel } from '../types'

import PaymentCard from './PaymentCard'
import PaymentsHeader from './PaymentsHeader'
import PaymentsSectionEmpty from './PaymentsSectionEmpty'
import { type CreateDefaultValues } from './types'

interface Props extends CreateDefaultValues {
    readOnly?: boolean
    refund?: boolean
    invoicesUrl?: string
}

const PaymentsPage: FC<Props> = ({ readOnly, refund, createDefaultValues, invoicesUrl }) => {
    const { data } = useListContext<PaymentModel>()

    let content = null

    if (!data) {
        content = <CardDefaultSkeleton width />
    } else if (data.length) {
        content = (
            <GridContainerGrid>
                {data.map((item, index) => (
                    <GridItemCard key={item.id}>
                        <PaymentCard
                            invoicesUrl={invoicesUrl}
                            payment={item}
                            index={index + 1}
                            readOnly={readOnly}
                            refund={refund}
                        />
                    </GridItemCard>
                ))}
            </GridContainerGrid>
        )
    } else {
        content = (
            <PaymentsSectionEmpty
                readOnly={readOnly}
                createDefaultValues={createDefaultValues}
                refund={refund}
                invoicesUrl={invoicesUrl}
            />
        )
    }

    return (
        <PageContent>
            <PaymentsHeader
                readOnly={readOnly}
                createDefaultValues={createDefaultValues}
                refund={refund}
                invoicesUrl={invoicesUrl}
            />
            {content}
        </PageContent>
    )
}

export default PaymentsPage
