import { alpha, styled, generateNotForwardedProps, themeColor, type ThemeColorType } from 'lib'
import { Typography, type TypographyProps } from 'ui/text'

export interface StatusTextProps extends StatusTextExtraProps, TypographyProps {}

interface StatusTextExtraProps {
    statusColor?: ThemeColorType
    bgOpacity?: number
    statusType?: defaultTypes
    textColor?: ThemeColorType
}

type defaultTypes = 'success' | 'error'

const types: { [key in defaultTypes]: Omit<StatusTextProps, 'statusType'> } = {
    success: {
        statusColor: (theme) => theme.palette.charts.greenBody,
        bgOpacity: 0.15,
    },
    error: {
        statusColor: (theme) => theme.palette.charts.red,
    },
}

const shouldForwardProp = generateNotForwardedProps<StatusTextExtraProps>([
    'bgOpacity',
    'statusColor',
    'statusType',
    'textColor',
])

const StatusText = styled(Typography, {
    shouldForwardProp,
})<StatusTextExtraProps>(({ theme, statusColor, bgOpacity, statusType, textColor }) => {
    const defaultProps = types[statusType] || {}

    const _statusColor: ThemeColorType =
        defaultProps.statusColor || statusColor || ((theme) => theme.palette.primary.main)

    const _bgOpacity = defaultProps.bgOpacity || bgOpacity || 0.1

    const _textColor = defaultProps.textColor || textColor

    return `
        font-weight: 400;
        padding: 2px 4px;
        line-height: 1;
        background-color: ${alpha(themeColor(_statusColor, theme), _bgOpacity)};
        color: ${themeColor(_textColor || _statusColor, theme)};
        border-radius: 2px;
        white-space: nowrap;
    `
})

StatusText.defaultProps = {
    variant: 'tooltip',
}

export default StatusText
