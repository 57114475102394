import { createContext, type FC, useContext } from 'react'

import { type WithChildrenNode, type ObjectAny } from 'appTypes'

/*
 * The value of the context is a function f1 that returns function f2
 * f2 returns the value that we would like to modify
 *
 * We return nested function in order to be able to check if modifier exists in the context.
 * eg:
 *
 * const modifiers = useModifierContext<{text: {testProp: any}}>()
 * const m = modifiers('text')
 * if (m) {...; m({testProp: 1})}
 */

type ContextValue = (name: string) => (value: any) => any

const defaultValue: ContextValue = () => (value) => value
const ModifierContext = createContext<ContextValue>(null)

interface Props extends WithChildrenNode {
    value: ContextValue | null
}

/**
 * If there is parent ModifierProvider, it will merge the parent value with the passed value.
 * It is the responsibility of the parent to merge the passed value with the parent value.
 */
export const ModifierProvider: FC<Props> = ({ children, value }) => {
    const c = useContext(ModifierContext)

    return (
        <ModifierContext.Provider
            value={
                c
                    ? (name) => {
                          return (args) => {
                              const valueModifier = value(name)
                              const valueForContext = valueModifier ? valueModifier(args) : args
                              const contextModifier = c(name)
                              if (contextModifier) {
                                  return contextModifier(valueForContext)
                              }
                              return valueForContext
                          }
                      }
                    : value
            }
        >
            {children}
        </ModifierContext.Provider>
    )
}

/**
 * Use useModifiers when you don't need to check if the modifier exists in the context.
 * It merges f1 and f2 into single function
 */
export const useModifiers = <Config extends ObjectAny>() => {
    const value = useContext(ModifierContext)
    return <Name extends keyof Config>(name: Name, args: Config[Name]) => {
        return (value || defaultValue)(name as string)?.(args)
    }
}

/**
 * Use useModifierContext when you have to check if the modifier exists in the context.
 */
export const useModifierContext = <Config extends ObjectAny>() => {
    return useContext(ModifierContext) as <Name extends keyof Config>(
        name: Name,
    ) => (value: Config[Name]) => Config[Name]
}

/**
 * Converts object of modifiers to a function.
 * - If the value of a propety is objext, it will be merged with the passed args to useModifiers.
 * - If the value of a property is a function, it will be called with the passed args to useModifiers.
 * When it is a function, it is the responsibility of the function to merge the passed args with the existing args.
 */
export const createModifiers =
    <Config extends ObjectAny>(modifiers: {
        [K in keyof Config]?: ((args: Config[K]) => Config[K]) | Config[K]
    }) =>
    <Name extends keyof Config>(name: Name) => {
        const config = modifiers[name]

        return (args: Config[Name]) => {
            if (typeof config === 'function') {
                return config(args)
            }

            if (typeof config === 'object') {
                return { ...args, ...config }
            }

            return args
        }
    }
