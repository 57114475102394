import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { useRecordContext } from 'core'
import { type IssueModel } from 'resources/issues'
import { IconBox } from 'ui'

import { IssueDrawerToggler } from '../../components'

const DetailsHeader = () => {
    const record = useRecordContext<IssueModel>()

    return (
        <ViewHeader
            title="Details"
            loading={!record}
        >
            <ViewHeader.Content placement="rightMobile">
                <IssueDrawerToggler
                    record={record}
                    defaultValues={{ customer: record?.unitData?.customer }}
                >
                    {(action) => (
                        <IconBox
                            title="Edit"
                            onClick={action}
                        >
                            <Icons.Edit />
                        </IconBox>
                    )}
                </IssueDrawerToggler>
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default DetailsHeader
