import { type FC, type ReactElement } from 'react'

import Icons from 'assets/icons'
import { useOpenUtilityDrawer, UtilityDrawerAddNewButton } from 'components'
import { useListContext, basePermissions, renderOnPermission, useResource } from 'core'

import { MemberInviteWrapper } from './MemberInviteDrawer'
import MemberInviteFormContent from './MemberInviteFormContent'

interface Props {
    children: (open: () => void) => ReactElement
}

const InviteMembersToggler: FC<Props> = renderOnPermission(({ children }) => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const resource = useResource()

    return children(() =>
        open({
            drawerArgs: {
                title: 'Invite New Users',
                renderWrapper: (params) => <MemberInviteWrapper {...params} />,
                renderContent: () => <MemberInviteFormContent />,
                renderTopRight: () => <UtilityDrawerAddNewButton />,
                renderBottomRight: (renderButton) =>
                    renderButton({
                        icon: <Icons.Invitations fontSize="large" />,
                        label: 'Invite',
                    }),
            },
            extraArgs: {
                listContext,
                resource,
            },
        }),
    )
}, basePermissions.createBulk)

export default InviteMembersToggler
