import { Show, DynamicRoutes, tabRoutes } from 'components'
import { useShowContext } from 'core'
import { Routes } from 'lib'
import { useIsVenderWo, type WorkOrderModel } from 'resources/workOrders'

import WorkOrderJobs from '../Jobs'
import WorkOrderOverview from '../Overview'
import WorkOrderPo from '../Po'

import { WoShowHeader } from './components'

const WorkOrderShowBase = () => {
    const { record: workOrder } = useShowContext<WorkOrderModel>()
    const isVendorWorkOrder = useIsVenderWo()

    const isStatusCancel = workOrder?.status === 'CANCELED'

    const { routes, tabs } = tabRoutes(
        [
            {
                value: '',
                label: 'Overview',
                element: <WorkOrderOverview />,
                tabHidden: isStatusCancel,
            },
            ...(isStatusCancel
                ? []
                : [
                      {
                          value: 'jobs',
                          label: 'Jobs',
                          element: <WorkOrderJobs />,
                      },
                      isVendorWorkOrder
                          ? {
                                value: 'purchase-order/*',
                                path: 'purchase-order',
                                label: 'purchase order',
                                element: (
                                    <DynamicRoutes
                                        path=":page"
                                        element={<WorkOrderPo />}
                                    />
                                ),
                            }
                          : null,
                  ]),
        ],
        {
            loading: !workOrder?.id,
        },
    )
    return (
        <>
            <WoShowHeader tabs={tabs} />
            <Routes>{routes}</Routes>
        </>
    )
}
const WorkOrderShow = () => {
    return (
        <Show>
            <WorkOrderShowBase />
        </Show>
    )
}

export default WorkOrderShow
