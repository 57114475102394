import { type FC } from 'react'

import { useRecordContext } from 'core'
import { PaymentsList, PaymentsPage } from 'resources/payments'
import { poResource, PoStatusKeys, type PurchaseOrderModel } from 'resources/purchaseOrders'

const PoPayments: FC = () => {
    const record = useRecordContext<PurchaseOrderModel>()
    const readOnly = record?.status === PoStatusKeys.CANCELED

    const id = record?.id

    const pamentMethod = record?.vendorData.paymentMethod

    return (
        <PaymentsList
            resource={poResource.payments(id)}
            enabled={Boolean(id)}
        >
            <PaymentsPage
                refund={record?.type === 'CREDIT'}
                readOnly={readOnly}
                createDefaultValues={{ method: pamentMethod }}
                invoicesUrl={poResource.invoices(id)}
            />
        </PaymentsList>
    )
}

export default PoPayments
