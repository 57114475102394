import { type FC } from 'react'

import Icons from 'assets/icons'
import { Header, InnerHeader, ShowHeaderSkeleton, type TabType } from 'components'
import {
    downloadOneAction,
    deleteOneAction,
    exportAction,
    type Action,
    useRecordContext,
} from 'core'
import { deletePartAction } from 'pages/Parts/config/constants'
import { RenderOnInventory, useInventoryContext } from 'resources/inventory'
import { type PartModel, partFields } from 'resources/parts'

import PartAddToInventoryAction from './AddToInventoryAction'
import ViewPartAction from './ViewPartAction'

const PartShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<PartModel>()
    const inventoryView = useInventoryContext()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="192px"
            tabMinWidth="176px"
            actions={getActions(inventoryView)}
        >
            <Header.Info
                avatar={
                    <Header.Avatar
                        imageSrc={record.image}
                        defaultImage={<partFields.avatar.Icon />}
                    />
                }
            >
                <Header.Content>
                    <Header.Title>{record.number}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">{record.description}</Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default PartShowHeader

const getActions =
    (inventoryView: boolean): Action<PartModel> =>
    (record, { children }) => {
        const actions = [
            <RenderOnInventory key="inventory-actions">
                <>
                    {!inventoryView && (
                        <PartAddToInventoryAction
                            key="inventory-add"
                            children={children}
                        />
                    )}
                    <ViewPartAction
                        key="see-part"
                        children={children}
                    />
                </>
            </RenderOnInventory>,
            downloadOneAction({
                children: (params) =>
                    children({
                        ...params,
                        Icon: Icons.QrCode2Outlined,
                        key: 'export-pdf',
                        title: 'Export QR code',
                    }),
                id: record.id,
                filename: `Part-${record.number}-Label`,
                type: 'pdf',
            }),
            exportAction({
                children,
            }),
            !inventoryView &&
                deleteOneAction({
                    children,
                    disabled: Boolean(record.inventoryCount),
                    titleOnDisabled: record.inventoryCount
                        ? 'This part has an inventory instance and cannot be deleted.'
                        : undefined,
                    ...deletePartAction,
                }),
        ]
        actions.filter(Boolean)

        return actions
    }
