import { useLayoutEffect } from 'react'

import { stringify } from 'query-string'
import { type DataProvider as RADataProvider, type PaginationPayload } from 'react-admin'

import { type Identifier, type NoStringIndex, type ReplaceReturnType } from 'appTypes'
import { type CApi } from 'core/api'
import { type AuthStore } from 'core/auth'
import { isFilterReference, prepareFilterResource } from 'core/resource'
import { capitalize, getId, isObject } from 'utils'

import { type FilterKnownValues } from './types'
import { emptyOptionValue, filterSearchText } from './utils'

const getPaginationQuery = (pagination: PaginationPayload) => {
    return {
        // if page is negative, then offset = page
        offset:
            pagination.page < 0
                ? Math.abs(pagination.page)
                : (pagination.page - 1) * pagination.perPage,
        limit: pagination.perPage,
    }
}

export const getFilterQuery = (filter) => {
    if (!filter) {
        return {}
    }
    const {
        q,
        [filterSearchText]: searchedText,
        __o: operators,
        ...otherSearchParams
    } = filter as FilterKnownValues

    return Object.keys(otherSearchParams).reduce(
        (acc, key) => {
            const value = otherSearchParams[key]

            const operator = operators?.[key]

            const name = operator ? `${operator}${key}` : key

            if (isObject(value)) {
                Object.keys(value).forEach((subKey) => {
                    acc[`${name}${capitalize(subKey)}`] = value[subKey]
                })
            } else {
                acc[name] = value
            }

            return acc
        },
        {
            [filterSearchText]: searchedText || q,
        },
    )
}

export const getOrderingQuery = (sort) => {
    if (!sort) {
        return {}
    }
    const { field, order } = sort
    if (field === 'id') {
        return {}
    }
    return {
        ordering: `${order === 'ASC' ? '' : '-'}${field}`,
    }
}
export type DataProviderConfigType = {
    [KEY in keyof ReturnType<typeof dataProvider>]?: {
        prepareResource?: (resource: string, params: any) => string
        prepareDataAfterResponse?: (
            data: any,
            params: Parameters<ReturnType<typeof dataProvider>[KEY]>[1],
        ) => Awaited<ReturnType<ReturnType<typeof dataProvider>[KEY]>>['data']
        shouldStopRequest?: boolean
        makeResponse?: ReplaceReturnType<
            ReturnType<typeof dataProvider>[KEY],
            Awaited<ReturnType<ReturnType<typeof dataProvider>[KEY]>>['data']
        >
        queryParams?: () => object
    } & (KEY extends 'getOne' | 'update'
        ? {
              makeId?: (id: Identifier) => Identifier
          }
        : {}) &
        (KEY extends 'getMany'
            ? {
                  getId?: (id: Identifier) => Identifier
              }
            : {})
}

export interface DataProvider extends NoStringIndex<RADataProvider> {
    createMany: RADataProvider['create']
}

const dataProviderConfig: { [key: string]: DataProviderConfigType } = {}

export const useDataProviderConfig = (resource: string | null, config: DataProviderConfigType) => {
    useLayoutEffect(() => {
        if (resource) {
            dataProviderConfig[resource] = config
            return () => {
                delete dataProviderConfig[resource]
            }
        }
    }, [resource])
}

export interface DataProviderMeta {
    [key: string]: any
}

const dataProvider = (authStore: AuthStore): DataProvider => {
    const api: CApi = authStore.api
    const getOneJson = (resource: string, id: Identifier, query?: object) => {
        const queryParams = stringify(query)
        return api.sendRequest(
            'get',
            `${resource}${id ? `/${id}` : ''}${queryParams ? `?${queryParams}` : ''}`,
        )
    }

    return {
        getList: async (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.getList || {}
            if (defaultConfig.shouldStopRequest) {
                return Promise.resolve({ data: [] })
            }
            if (defaultConfig.makeResponse) {
                const data = defaultConfig.makeResponse(resourceProp, params)
                return Promise.resolve({
                    data,
                    total: data.length,
                })
            }
            const query = {
                ...getFilterQuery(params.filter),
                ...getPaginationQuery(params.pagination),
                ...getOrderingQuery(params.sort),
                ...defaultConfig.queryParams?.(),
                ...params.meta?.query,
            }
            if (defaultConfig.makeResponse) {
                const data = defaultConfig.makeResponse(resourceProp, params)
                return Promise.resolve({
                    data,
                    total: data.length,
                })
            }
            let fixedResource = resourceProp
            if (defaultConfig.prepareResource) {
                fixedResource = defaultConfig.prepareResource(resourceProp, params)
            }

            const isFilter = isFilterReference(fixedResource)

            if (isFilter) {
                fixedResource = prepareFilterResource(fixedResource)
            }

            const json = await api.sendRequest('get', `${fixedResource}?${stringify(query)}`)

            let fixedData = json.results || json
            if (defaultConfig.prepareDataAfterResponse) {
                fixedData = defaultConfig.prepareDataAfterResponse(fixedData, params)
            }

            if (isFilter && Array.isArray(fixedData) && typeof fixedData[0] !== 'object') {
                fixedData = fixedData.map((id) => ({ id }))
            }

            const getPrev = () => {
                const aggregates = json.aggregates
                if (!aggregates) {
                    return Boolean(json.prev)
                }
                // eslint-disable-next-line no-new-wrappers
                const hasNext = new Boolean(json.next)
                ;(hasNext as any).aggregates = aggregates
                return hasNext as boolean
            }

            return {
                data: fixedData,
                total: json.count || fixedData.length,
                pageInfo: {
                    hasNextPage: Boolean(json.next),
                    hasPreviousPage: getPrev(),
                },
            }
        },
        getOne: async (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.getOne || {}
            if (defaultConfig.shouldStopRequest) {
                return new Promise(() => {
                    /* empty */
                })
            }
            let fixedResource = resourceProp
            if (defaultConfig.prepareResource) {
                fixedResource = defaultConfig.prepareResource(resourceProp, params)
            }
            let data = await getOneJson(
                fixedResource,
                defaultConfig.makeId ? defaultConfig.makeId(params.id) : params.id,
                { ...params.meta?.query, ...defaultConfig.queryParams?.() },
            )
            if (defaultConfig.prepareDataAfterResponse) {
                data = defaultConfig.prepareDataAfterResponse(data, params)
            }

            return { data }
        },
        getMany: (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.getMany || {}
            if (defaultConfig.shouldStopRequest) {
                return Promise.resolve({ data: [] })
            }
            if (defaultConfig.makeResponse) {
                return Promise.resolve({
                    data: defaultConfig.makeResponse(resourceProp, params),
                })
            }
            let fixedResource = resourceProp
            if (defaultConfig.prepareResource) {
                fixedResource = defaultConfig.prepareResource(resourceProp, params)
            }

            let request: Promise<any>

            let ids = params.ids.filter((id) => id !== emptyOptionValue)

            if (defaultConfig.getId) {
                ids = ids.map((id) => defaultConfig.getId(id) || id)
            }

            if (isFilterReference(fixedResource)) {
                const itemResouse = prepareFilterResource(fixedResource, true)
                if (itemResouse) {
                    fixedResource = itemResouse
                } else {
                    return Promise.resolve({
                        data: ids.map((id) => ({ id })),
                    })
                }
            }

            const isEmptyIncluded = ids.length !== params.ids.length

            // If there is only 1 id, fetch from getOne endpoint
            if (ids.length === 1) {
                request = getOneJson(fixedResource, ids[0], params.meta?.query).then((data) => [
                    data,
                ])
            } else if (ids.length) {
                request = api
                    .get(
                        fixedResource +
                            '?' +
                            stringify(
                                {
                                    id: ids,
                                    ...params.meta?.query,
                                },
                                {
                                    arrayFormat: 'comma',
                                },
                            ),
                    )
                    .then((data) => {
                        const results = Array.isArray(data) ? data : data.results

                        if (isEmptyIncluded) {
                            return [...results, { id: emptyOptionValue }]
                        }
                        return results
                    })
            } else {
                request = new Promise((resolve) => {
                    resolve(isEmptyIncluded ? [{ id: emptyOptionValue }] : [])
                })
            }

            return request.then((data) => ({
                data: defaultConfig.prepareDataAfterResponse
                    ? defaultConfig.prepareDataAfterResponse(data, params)
                    : data,
            }))
        },

        getManyReference: async (resourceProp, params) => {
            const query = {
                ...getFilterQuery(params.filter),
                ...getPaginationQuery(params.pagination),
                ...getOrderingQuery(params.sort),
                [params.target]: params.id,
            }
            const json = await api.sendRequest('get', `${resourceProp}?${stringify(query)}`)
            return {
                data: json.results,
                total: json.count,
            }
        },
        update: async (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.update || {}
            const id = defaultConfig.makeId ? defaultConfig.makeId(params.id) : params.id

            const json = await api.sendRequest(
                'patch',
                `${resourceProp}${id ? `/${id}` : ''}${
                    defaultConfig.queryParams || params.meta?.query
                        ? '?' +
                          stringify({ ...params.meta?.query, ...defaultConfig.queryParams?.() })
                        : ''
                }`,
                params.data,
            )
            return { data: json }
        },
        updateMany: (resourceProp, params) => {
            return Promise.all(
                params.ids.map((id) =>
                    api.sendRequest('patch', `${resourceProp}/${id}/`, params.data),
                ),
            ).then((responses) => ({ data: responses.map(getId) }))
        },
        create: async (resourceProp, params) => {
            const defaultConfig = dataProviderConfig[resourceProp]?.create || {}
            const resource = defaultConfig.prepareResource
                ? defaultConfig.prepareResource(resourceProp, params)
                : resourceProp
            const json = await api.sendRequest('post', resource, params.data, {
                params: params.meta?.query,
            })
            return {
                data: json,
            }
        },
        delete: (resourceProp, params) => {
            return api
                .sendRequest('delete', `${resourceProp}/${params.id}`, null, {
                    params: params?.previousData?.params,
                })
                .then(() => ({ data: params.previousData }))
        },
        deleteMany: (resourceProp, params) => {
            if (!params.ids.length) {
                return Promise.resolve().then(() => ({ data: [] }))
            }

            return api
                .sendRequest('delete', `${resourceProp}?id=${params.ids.join(',')}`, null, {
                    headers: { 'X-BULK-OPERATION': true },
                })
                .then(() => ({ data: params.ids }))
        },
        createMany: (resource, params) => {
            return api.post(resource, params.data)
        },
    }
}

export default dataProvider
