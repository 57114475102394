import { type ReactNode } from 'react'

import { type DataRecord, type ExtendRecordType } from 'appTypes'
import { useRecordContext, extendRecord } from 'core/data'
import { globalClassNames, withColor } from 'lib'
import { Box, type BoxProps } from 'ui'

import { InfoCardLabelTypography, InfoCardRow, InfoCardValueTypography } from './styled'

export interface InfoCardDetailsRow<T extends DataRecord> {
    label: string | ReactNode
    source: ExtendRecordType<T, string | number | ReactNode>
    valueFullWidth?: boolean
    labelGrow?: number
}

export interface InfoCardDetailType<T extends DataRecord> extends BoxProps {
    details: InfoCardDetailsRow<T>[] | ReactNode
    propRecord?: T
}
const InfoCardDetails = <T extends DataRecord>({
    details,
    propRecord,
    ...rest
}: InfoCardDetailType<T>) => {
    const contextRecord = useRecordContext<T>()
    const record = propRecord || contextRecord

    if (record === undefined) {
        return <></>
    }
    return (
        <Box {...rest}>
            {Array.isArray(details)
                ? details.map((detail, index) =>
                      detail ? (
                          <InfoCardRow
                              key={index}
                              className="infocard-row"
                              labelGrow={detail.labelGrow}
                          >
                              <Box className={globalClassNames.ellipsis}>
                                  <InfoCardLabelTypography
                                      color={withColor('text.disabled')}
                                      variant="tooltip"
                                  >
                                      {detail.label}
                                  </InfoCardLabelTypography>
                              </Box>
                              <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  overflow="hidden"
                              >
                                  <InfoCardValueTypography
                                      color={withColor('text.primary')}
                                      variant="body2"
                                      component="div"
                                      display={detail.valueFullWidth ? 'flex' : undefined}
                                      sx={[
                                          {
                                              '&:empty::before': {
                                                  content: '"-"',
                                                  display: 'inline-block',
                                              },
                                          },
                                          detail.valueFullWidth
                                              ? {
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }
                                              : undefined,
                                      ]}
                                  >
                                      {extendRecord(record, detail.source) ?? '-'}
                                  </InfoCardValueTypography>
                              </Box>
                          </InfoCardRow>
                      ) : null,
                  )
                : details}
        </Box>
    )
}

export default InfoCardDetails
