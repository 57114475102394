import { type FC } from 'react'

import Icons from 'assets/icons'
import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { useRecordContext } from 'core'
import { getMeterConfig, meterTypesForUnit, type UnitModel } from 'resources/units'
import { IconBox } from 'ui'
import { displayBooleanValue } from 'utils'

import SettingsDrawerToggler from './SettingsDrawerToggler'

const MeterSettings: FC = () => {
    const unit = useRecordContext<UnitModel>()

    if (!unit) {
        return null
    }

    return (
        <InfoCard>
            <InfoCardHeader
                title="Tracked Meters"
                action={
                    <SettingsDrawerToggler>
                        {({ onClick }) => (
                            <IconBox
                                title="Edit"
                                onClick={onClick}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </SettingsDrawerToggler>
                }
            />
            <InfoCardDetails
                details={meterTypesForUnit.map((meterType) => {
                    const meterConfig = getMeterConfig(meterType)

                    return {
                        label: meterConfig.name,
                        source: () => displayBooleanValue(unit.settings[meterType]?.tracked),
                    }
                })}
            />
        </InfoCard>
    )
}

export default MeterSettings
