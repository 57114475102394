import Icons from 'assets/icons'
import { alpha, styled, Link } from 'lib'
import { Card, SvgIcon } from 'ui'

export const StyledWidgetContainer = styled(Card)`
    max-height: 304px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: initial;
    position: relative;
`

export const WidgetBaseLink = styled(({ link, ...p }: { link: string }) => {
    return (
        <Link to={link}>
            <SvgIcon
                component={Icons.ArrowForward}
                {...p}
            />
        </Link>
    )
})`
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.04)};
    width: 16px;
    height: 16px;
    border-radius: 4px;
    position: absolute;
    top: 17px;
    right: 15px;
    cursor: pointer;
    z-index: 4;
`

export const WidgetBaseDragIndicator = styled(({ className, ...p }: { className?: string }) => {
    return (
        <SvgIcon
            component={Icons.DragIndicator}
            className={`WidgetDrag ${className}`}
            {...p}
        />
    )
})`
    color: ${({ theme }) => theme.palette.text.disabled};
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 4;
    transform: translate(-50%, 0%) rotate(90deg);
    cursor: pointer;
`
export const WidgetBaseResizeIndicator = styled(({ className, ...p }: { className?: string }) => {
    return (
        <SvgIcon
            component={Icons.OpenInFullOutlined}
            className={`WidgetResize ${className}`}
            {...p}
        />
    )
})`
    color: ${({ theme }) => theme.palette.text.disabled};
    display: none;
    position: absolute;
    width: 18px;
    height: 18px;
    bottom: 0;
    right: 0;
    transform: rotate(90deg);
    background-color: ${({ theme }) => theme.palette.white};
    z-index: 2;
`
