import { type FC } from 'react'

import { FilterRangeInput, type FilterRangeInputProps } from 'components'

import { type UnitMeterTypes } from '../types'
import { meterTypesConfig } from '../utils'

interface MeterRangeFilterProps extends Omit<FilterRangeInputProps, 'valueAdornment'> {
    type: UnitMeterTypes
}

const MeterRangeFilter: FC<MeterRangeFilterProps> = ({ type, ...props }) => {
    const meterConfig = meterTypesConfig[type]

    return (
        <FilterRangeInput
            valueAdornment={meterConfig.adornment}
            {...props}
        />
    )
}

export default MeterRangeFilter
