import { formatMoney } from 'components'
import { useRecordContext } from 'core'
import { useResizeObserverWidth } from 'hooks'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import {
    Clarification,
    CostPerUnitChart,
} from 'pages/Dashboard/components/Widgets/CostPerUnitWidget/components'
import { roundWidgetJobsData } from 'pages/Dashboard/components/Widgets/utils'
import { type UnitModel } from 'resources/units'

const description = `Maintenance Cost displays the aggregated costs of
parts and labor associated with the unit's work orders.`
const UnitCostWidget = () => {
    const { ref, match } = useResizeObserverWidth(360)

    const record = useRecordContext<UnitModel>()
    if (!record) {
        return <SliderWidgetSkeleton />
    }
    const ratioData = roundWidgetJobsData(record)
    const validData = ratioData.total > 0
    return (
        <SliderWidget
            ref={ref}
            headerProps={{
                title: 'Maintenance Cost',
                label: formatMoney(ratioData.total),
                tooltipText: description,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={match}
                        validData={validData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        {...ratioData}
                        disabled={!validData}
                    />
                ),
            }}
        />
    )
}
export default UnitCostWidget
