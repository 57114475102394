import { type FC } from 'react'

import { inject } from 'mobx-react'

import { Form, TextInput, EmailInput, CombinedInputs, PasswordInput } from 'components'
import { urls } from 'configs'
import {
    maxLengthValidation,
    passwordMatchValidation,
    passwordValidation,
    requiredValidation,
    serialize,
    useSubmit,
    type AuthStore,
    nonFieldErrors,
    type Validator,
} from 'core'
import { Navigate, useParams, useQuery } from 'lib'
import { Typography } from 'ui'

import { AuthBody, AuthSubmitButton, TosButton } from '../components'

import ExpiredInvitation from './ExpiredInvitation'
import { type InvitationParams, type TokenType } from './types'

const password2Validation: Validator[] = [requiredValidation, passwordMatchValidation]

type TokenResponse = {
    company?: string
    createdByEmail: string
    createdByName?: string
    invitationEmail: string
}

const AcceptInvitation: FC = inject('auth')(({ auth }: { auth: AuthStore }) => {
    const { token } = useParams<InvitationParams>()
    const { data } = useQuery<TokenResponse & { code: TokenType }>('accept', async () => {
        try {
            const res = await auth.checkInvite(token)
            return { ...res, code: 'active' }
        } catch (err) {
            if (err[nonFieldErrors]) {
                return { code: err[nonFieldErrors].code }
            }
            return { code: 'invalid' }
        }
    })
    const handleSubmit = useSubmit(async (formData) => {
        const serializedForm = serialize(formData, ['name', 'password'])
        await auth.acceptInvite(token, serializedForm.password, serializedForm.name)
    })

    if (!data) {
        return null
    }

    if (data.code === 'invalid') {
        return <Navigate to={urls.root} />
    }

    return (
        <AuthBody>
            {data.code === 'active' ? (
                <>
                    <Typography
                        variant="h6"
                        mb="8px"
                    >
                        Join your team on Fleetpal
                    </Typography>

                    <Typography
                        mb="24px"
                        component="div"
                    >
                        <Typography
                            fontWeight={700}
                            display="inline"
                        >
                            {data.createdByName}
                            {data.createdByName ? ` (${data.createdByEmail})` : data.createdByEmail}
                        </Typography>{' '}
                        invited you to join{' '}
                        {data.company ? (
                            <>
                                <Typography
                                    fontWeight={700}
                                    display="inline"
                                >
                                    {data.company}
                                </Typography>{' '}
                                company
                            </>
                        ) : (
                            'your team on Fleetpal'
                        )}
                    </Typography>

                    <Form
                        warnWhenUnsavedChanges={false}
                        onSubmit={handleSubmit}
                    >
                        <EmailInput
                            defaultValue={data.invitationEmail}
                            disabled
                            label="Email Address"
                        />

                        <TextInput
                            source="name"
                            label="Full Name"
                            validate={maxLengthValidation}
                        />

                        <PasswordInput
                            validate={passwordValidation}
                            helperText="Use at least 8 characters"
                        />

                        <CombinedInputs
                            track={['password']}
                            sources={['password2']}
                        />

                        <PasswordInput
                            source="password2"
                            validate={password2Validation}
                            label="Confirm Password"
                        />

                        <TosButton />

                        <AuthSubmitButton
                            label="JOIN"
                            disabled={false}
                        />
                    </Form>
                </>
            ) : (
                <ExpiredInvitation tokenCode={data.code} />
            )}
        </AuthBody>
    )
})

export default AcceptInvitation
