import { useEffect, type FC } from 'react'

import { useFieldArray, useFormContext } from 'react-hook-form'

import { type WithChildrenNode } from 'appTypes'
import { ArrayControllerContextProviderBase } from 'components/ArrayController/ArrayControllerBase'

import { useListSnap } from '../ListContext'

import {
    type ArrayExtra,
    type SourceConfig,
    type FilterElement,
    type FilterFormValues,
} from './types'

const FilterArrayController: FC<WithChildrenNode> = ({ children }) => {
    const { control, setValue, getValues } = useFormContext<FilterFormValues>()
    const snap = useListSnap()

    const data = useFieldArray({
        control,
        name: 'filters',
    })

    const isEmpty = !data.fields.length

    useEffect(() => {
        if (!isEmpty) {
            return
        }

        data.append({ name: '' }, { shouldFocus: false })
    }, [isEmpty])

    // data.fields does not give correct values for data.{index}.source
    const fields = getValues('filters')

    const sources = fields.reduce(
        (acc, item) => {
            if (item.name) {
                acc[item.name] = true
            }

            return acc
        },
        {} as { [key: string]: true },
    )

    const getConfig: ArrayExtra['getConfig'] = (source) => {
        return snap.config.filters.reduce(
            (acc, item) => {
                const match = source && item.id === source
                if (match) {
                    acc.config = item
                }

                if (match || sources[item.id] !== true) {
                    acc.options.push(item)
                }

                return acc
            },
            { config: null, options: [] } satisfies SourceConfig,
        )
    }

    const isFilterEmpty = fields.length < 2 && !fields[0]?.name

    return (
        <ArrayControllerContextProviderBase
            value={{
                array: data.fields,
                append: () => {
                    data.append({ name: '' }, { shouldFocus: false })
                    return null
                },
                remove: ({ index }) => {
                    data.remove(index)
                },
                setArray: (value: FilterElement[]) => {
                    setValue('filters', value)
                },
                extra: {
                    isFilterEmpty,
                    getConfig,
                } satisfies ArrayExtra,
                limit: undefined,
            }}
        >
            {children}
        </ArrayControllerContextProviderBase>
    )
}

export default FilterArrayController
