import { type FC } from 'react'

import { useGetList } from 'react-admin'

import { type Identifier } from 'appTypes'
import {
    ActionsMenu,
    api,
    type MenuActionProps,
    updatedMessage,
    useActionsMenuContext,
    useFinalErrorHandler,
    useNotify,
} from 'core'
import { MenuItem, SimpleScrollbar, Skeleton, Stack, Typography } from 'ui'

import { type UnitStatusModel } from '../types'
import { unitStatusResource } from '../utils'

import BaseStatusContent from './BaseStatusContent'

interface StatusMenuProps
    extends Pick<MenuActionProps, 'renderToggler' | 'anchorOrigin' | 'transformOrigin'> {
    endpoint?: string
    id?: Identifier
    selectedStatus?: UnitStatusModel
    onSuccess?: () => void
}

const StatusMenu: FC<StatusMenuProps> = ({
    renderToggler,
    anchorOrigin,
    transformOrigin,
    ...rest
}) => {
    return (
        <ActionsMenu
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            renderToggler={renderToggler}
            actions={() => [
                <SimpleScrollbar
                    style={{ maxHeight: '193px', width: '181px' }}
                    key="status-change"
                >
                    <StatusList {...rest} />
                </SimpleScrollbar>,
            ]}
        />
    )
}

export default StatusMenu

const StatusList = ({
    selectedStatus,
    id,
    onSuccess,
    endpoint,
}: {
    selectedStatus?: UnitStatusModel
    id?: Identifier
    onSuccess?: () => void
    endpoint?: string
}) => {
    const list = useGetList<UnitStatusModel>(unitStatusResource.resource, {
        pagination: {
            page: 1,
            perPage: 1000,
        },
    })

    const { close } = useActionsMenuContext()
    const errorHandler = useFinalErrorHandler()
    const notify = useNotify()

    if (list.isLoading || list.error) {
        return <StatusListSkeleton />
    }

    return (
        <Stack>
            {list.data?.map((status) => {
                if (status.id === selectedStatus?.id) {
                    return null
                }
                return (
                    <Typography
                        component={MenuItem}
                        key={status.id}
                        variant="menuItem"
                        onClick={async () => {
                            close()
                            try {
                                await api.post(endpoint || unitStatusResource.changeStatus(id), {
                                    status: status.id,
                                })
                                onSuccess?.()
                                notify(updatedMessage)
                            } catch (err) {
                                errorHandler(err)
                            }
                        }}
                    >
                        <BaseStatusContent color={status.color}>{status.name}</BaseStatusContent>
                    </Typography>
                )
            })}
        </Stack>
    )
}

const StatusListSkeleton = () => {
    return (
        <Stack>
            {Array(4)
                .fill(null)
                .map((_, i) => {
                    return (
                        <MenuItem key={i}>
                            <Skeleton />
                        </MenuItem>
                    )
                })}
        </Stack>
    )
}
