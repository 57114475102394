import { type FC } from 'react'

import { PageContent } from 'ui'
import { booleanValue } from 'utils'

import { IssuesList, IssuesListController } from '../components'

import { ListHeader } from './components'

const List: FC = () => {
    return (
        <IssuesListController filter={{ unitArchived: booleanValue.negative }}>
            <ListHeader />
            <PageContent>
                <IssuesList />
            </PageContent>
        </IssuesListController>
    )
}

export default List
