import { useRecordContext } from 'core'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import { DowntimeChart, Label } from 'pages/Dashboard/components/Widgets/DowntimeWidget/components'
import { type ReportDowntimeModel, type DowntimeList } from 'resources/units'

import { UnitDowntimeClarification } from './components'

const description = `Maintenance Downtime refers to the time,
 in absolute and relative terms, in which a unit is not available due to repair services.`
let validData = true

const UnitDowntimeWidget = () => {
    const data = useRecordContext<ReportDowntimeModel>()
    if (!data) {
        return <SliderWidgetSkeleton />
    }
    const {
        downtime,
        downtimeEmergency,
        downtimeNonScheduled,
        downtimePercent,
        downtimeScheduled,
    } = data
    const downtimePerPriorityClass: DowntimeList = {
        EMERGENCY: {
            total: String(downtimeEmergency),
            percentOfTotalDowntime: (downtimeEmergency / downtime) * 100,
            totalStr: String(downtime),
        },
        SCHEDULED: {
            total: String(downtimeScheduled),
            percentOfTotalDowntime: (downtimeScheduled / downtime) * 100,
            totalStr: String(downtime),
        },
        NON_SCHEDULED: {
            total: String(downtimeNonScheduled),
            percentOfTotalDowntime: (downtimeNonScheduled / downtime) * 100,
            totalStr: String(downtime),
        },
    }
    validData = downtimePercent > 0

    return (
        <SliderWidget
            headerProps={{
                title: 'Downtime by Repair Class',
                labelElement: (
                    <Label
                        label={downtimePercent}
                        validData={validData}
                        inUnit
                    />
                ),
                tooltipText: description,
                clarification: (
                    <UnitDowntimeClarification
                        totalDowntimeSeconds={downtime}
                        validData={validData}
                        list={downtimePerPriorityClass}
                    />
                ),
                chart: (
                    <DowntimeChart
                        {...downtimePerPriorityClass}
                        disabled={!validData}
                    />
                ),
            }}
        />
    )
}
export default UnitDowntimeWidget
