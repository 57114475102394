import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { createModifiers, ModifierProvider } from 'context'
import { clsx, globalClassNames, withColor } from 'lib'
import { Box, type TextModifiers } from 'ui'

const Primary: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Box
            mt="21px"
            display="flex"
            alignItems="center"
            gap="8px"
        >
            <ModifierProvider value={modifiers}>{children}</ModifierProvider>
        </Box>
    )
}

const modifiers = createModifiers<TextModifiers>({
    text: (props) => ({
        color: withColor('text.primary'),
        variant: 'h5',
        ...props,
        className: clsx(globalClassNames.ellipsis, props.className),
    }),
})

export default Primary
