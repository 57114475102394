import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import {
    TextInput,
    TextareaInput,
    UtilityDrawerDeleteButton,
    UtilityDrawerEdit,
    useOpenUtilityDrawer,
} from 'components'
import {
    useListContext,
    type Serializer,
    maxLengthValidationText,
    requiredValidation,
    useResource,
} from 'core'

import { type FileDocument } from '../types'

interface Props {
    children: (open: () => void) => ReactElement
    id: Identifier
    readOnly?: boolean
}

const DocumentEditor: FC<Props> = ({ children, id, readOnly }) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()
    const listContext = useListContext()

    return children(() => {
        open({
            drawerArgs: {
                title: 'Edit Document',
                renderWrapper: (params) => (
                    <UtilityDrawerEdit
                        serializer={serializer}
                        {...params}
                    />
                ),
                renderContent: () => (
                    <Content isInputDisabled={readOnly ? () => true : undefined} />
                ),
                renderTopRight: () => (
                    <UtilityDrawerDeleteButton
                        disabled={readOnly}
                        confirmConfig={{
                            title: 'Are you sure you want to delete this document?',
                        }}
                    />
                ),
                renderBottomRight: readOnly ? () => null : undefined,
            },
            extraArgs: {
                id,
                resource,
                listContext,
            },
        })
    })
}

export default DocumentEditor

const nameValidation = [requiredValidation]

interface ContentProps {
    isInputDisabled?: (source: keyof FileDocument) => boolean
}

const defaultIsInputDisabled = () => false

const Content: FC<ContentProps> = ({ isInputDisabled = defaultIsInputDisabled }) => {
    return (
        <>
            <TextInput
                source="name"
                disabled={isInputDisabled('name')}
                label="Document Name"
                validate={nameValidation}
            />
            <TextareaInput
                source="description"
                disabled={isInputDisabled('description')}
                label="Description"
                validate={maxLengthValidationText}
            />
        </>
    )
}

const serializer: Serializer<FileDocument> = ['name', 'description']
