import { type FC, type ReactElement } from 'react'

import { useFormContext, useFormState } from 'react-hook-form'

import Icons from 'assets/icons'
import {
    ArrayControllerBox,
    ArrayControllerDeleteIcon,
    ArrayControllerElements,
    ArrayControllerForm,
    FormPreventSubmit,
    useArrayControllerContext,
    useArrayControllerElementContext,
    useOpenUtilityDrawer,
} from 'components'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import {
    type Serializer,
    formErrorToString,
    parseFile,
    serialize,
    useNotify,
    useResource,
    useSubmit,
    useListContext,
    api,
} from 'core'
import { globalClassNames } from 'lib'
import { maxFileSize } from 'resources/gallery'
import {
    Alert,
    Box,
    Button,
    Typography,
    InfoBadge,
    NonDisplayedInput,
    Spacer,
    Stack,
    Tooltip,
    FormHelperText,
} from 'ui'
import { displayFileExtension, displayFileSize, getFileName, htmlSymbols } from 'utils'

import DocumentAvatar from './DocumentAvatar'

interface Props {
    children: (open: () => void) => ReactElement
}

const DocumentUploader: FC<Props> = ({ children }) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()
    const listContext = useListContext()
    const notify = useNotify()

    const submitHandler = useSubmit(async (data) => {
        await api.post(resource.resource + '/create_bulk', serialize(data, serializer))
        notify({ title: 'Successfully uploaded', type: 'success' })
        listContext.refetch()
    })

    return children(() => {
        open({
            drawerArgs: {
                title: 'Upload Documents',
                renderWrapper: (params) => (
                    <UtilityDrawerForm
                        {...params}
                        onSubmit={submitHandler}
                    />
                ),
                renderBottomRight: (render) =>
                    render({ label: 'Upload', icon: <Icons.UploadFileOutlined /> }),
                renderContent: () => <UploadContent />,
            },
        })
    })
}

export default DocumentUploader

const UploadContent: FC = () => {
    return (
        <ArrayControllerForm>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb="16px"
            >
                <Spacer>
                    <Typography
                        variant="subtitle1"
                        color="text.primary"
                    >
                        Documents
                    </Typography>
                    <ElementsCount />
                </Spacer>
                <AddButton />
            </Stack>
            <Stack>
                <ArrayControllerElements<any> itemKey={({ item }) => item.id}>
                    <Element />
                </ArrayControllerElements>
            </Stack>
            <Submittable />
            <NoData />
        </ArrayControllerForm>
    )
}

const ElementsCount = () => {
    const { array } = useArrayControllerContext()

    return <InfoBadge badgeContent={String(array.length)} />
}

const AddButton = () => {
    const { append, array, limit } = useArrayControllerContext<File>()
    const { setError } = useFormContext()

    const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = (event.target as HTMLInputElement).files?.[0]
        if (!file) {
            return
        }

        const index = array.length
        append(file)
        const source = getName(index)

        if (file.size > maxFileSize.size) {
            setError(source, { message: maxFileSize.errorMessage })
        }

        event.target.value = null
    }

    const disabled = array.length >= limit

    const button = (
        <Button
            variant="text"
            startIcon={<Icons.AttachFileOutlined />}
            component="div"
            disabled={disabled}
        >
            Choose Files
        </Button>
    )

    if (disabled) {
        return (
            <Tooltip title={`Maximum ${limit} files`}>
                <span>{button}</span>
            </Tooltip>
        )
    }

    return (
        <label>
            <NonDisplayedInput
                type="file"
                onChange={upload}
            />
            <Tooltip title={`Add up to ${limit} files, max size 30 MB each`}>{button}</Tooltip>
        </label>
    )
}

const Element = () => {
    const { index } = useArrayControllerElementContext()
    const { getValues, getFieldState } = useFormContext()

    const source = getName(index)
    const file = getValues(source)
    const error = getFieldState(source).error

    return (
        <ArrayControllerBox
            gap="12px"
            title={
                <Stack
                    direction="row"
                    overflow="hidden"
                    alignItems="center"
                >
                    <DocumentAvatar file={file} />
                    <Box
                        ml="12px"
                        overflow="hidden"
                    >
                        <Typography
                            variant="h6"
                            color="text.primary"
                            className={globalClassNames.ellipsis}
                        >
                            {getFileName(file)}
                        </Typography>
                        <Typography
                            variant="chartTitle"
                            color="text.primary"
                        >
                            {displayFileExtension(file)} {htmlSymbols.dot} {displayFileSize(file)}
                        </Typography>
                    </Box>
                </Stack>
            }
            deleteAlwaysVisible
            renderDeleteButton={() => (
                <ArrayControllerDeleteIcon
                    controller={{ alwaysVisible: true }}
                    size="small"
                    sx={{ mb: 'auto' }}
                >
                    <Icons.Close fontSize="inherit" />
                </ArrayControllerDeleteIcon>
            )}
        >
            {error ? <FormHelperText error>{formErrorToString(error)}</FormHelperText> : null}
        </ArrayControllerBox>
    )
}

const serializer: Serializer = [{ name: 'data', parse: (value) => value.map(parseFile) }]

const Submittable = () => {
    const { array } = useArrayControllerContext()
    const { errors } = useFormState()

    if (array.length && !Object.keys(errors).length) {
        return null
    }

    return <FormPreventSubmit />
}

const getName = (index: number) => `data.${index}`

const NoData = () => {
    const { array } = useArrayControllerContext()

    if (array.length) {
        return null
    }

    return <Alert severity="info">No Documents Added</Alert>
}
