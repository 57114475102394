import { type FilterBy } from 'components'
import { createResource } from 'core'

import { type PaymentTermModel } from './types'

export const paymentTermResource = createResource({
    name: 'payment-terms',
    resource: 'payment-terms',
})

export const displayPaymentTerm = (record: Pick<PaymentTermModel, 'name'>): string =>
    record ? record.name : ''

export const paymentTermFilter = ({ label }: { label?: string }) =>
    ({
        id: 'paymentTerm',
        label: label || 'Payment Term',
    }) as const satisfies FilterBy
