import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { useModifiers } from 'context'
import { customerFields } from 'resources/customers'
import { unitFields } from 'resources/units'

import { assignmentsResource, assignmentsSerializer } from '../utils'

import AssignmentsForm, { type AssignmentsFormProps } from './AssignmentsForm'

export interface AssignmentsDrawerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
    defaultValues?: any
    readOnly?: boolean
}
export interface AssignmentsDrawerModifications {
    assignmentForm?: AssignmentsFormProps
}
const AssignmentsDrawer = ({ children, id, defaultValues, readOnly }: AssignmentsDrawerProps) => {
    const open = useOpenUtilityDrawer()
    const modification = useModifiers<AssignmentsDrawerModifications>()

    return children(() => {
        open({
            extraArgs: {
                id,
                resource: assignmentsResource,
            },
            drawerArgs: {
                title: readOnly ? 'View Assignment' : id ? 'Edit Assignment' : 'Create Assignment',
                renderWrapper: (props) => (
                    <UtilityDrawerEditor
                        serializer={assignmentsSerializer}
                        defaultValues={defaultValues}
                        {...props}
                    />
                ),
                renderBottomRight: readOnly ? () => null : undefined,
                renderBottomLeft: readOnly
                    ? (renderCancelButton) => renderCancelButton({ children: 'Close' })
                    : undefined,
                renderContent: () => (
                    <AssignmentsForm
                        isInputDisabled={
                            readOnly
                                ? () => readOnly
                                : id
                                  ? (source) =>
                                        source === customerFields.self.source ||
                                        source === unitFields.self.source
                                  : modification('assignmentForm', {}).isInputDisabled
                        }
                    />
                ),
            },
        })
    })
}

export default AssignmentsDrawer
