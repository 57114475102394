import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { type ResourceConfig } from 'core'
import { pathJoin } from 'utils'

import { type PurchaseOrderModel } from './types'

const resource = 'purchase-orders'

export const poResource = {
    resource,
    name: 'purchase-orders',
    close: (id: Identifier) => pathJoin(resource, id, 'close'),
    payments: (id: Identifier) => `${poResource.resource}/${id}/payments`,
    invoices: (id: Identifier) => `${poResource.resource}/${id}/invoices`,
} satisfies ResourceConfig

export const poDraftUrl = (id: Identifier) => `/purchase-orders/${id}/receipts/draft_receipt`

export const poReceiptsName = 'purchase-order-receipts'

const poIcons: { [key in PurchaseOrderModel['type']]: FC } = {
    CREDIT: Icons.CreditPO,
    STANDARD: Icons.ReceiptLongOutlined,
    WORK_ORDER: Icons.WOPurchaseOrder,
}

export const getPOIcon = (type: PurchaseOrderModel['type']) => {
    return poIcons[type || 'STANDARD']
}

export const formatPOTotal = (total: number, featured = true) =>
    formatMoney({ value: total, negativeFormat: 'accounting', featured })
