import { formatMoney } from 'components'
import { useRecordContext } from 'core'
import { useResizeObserverWidth } from 'hooks'
import { SliderWidget, SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import {
    Clarification,
    CostPerUnitChart,
} from 'pages/Dashboard/components/Widgets/CostPerUnitWidget/components'
import { roundWidgetJobsData } from 'pages/Dashboard/components/Widgets/utils'
import { type UnitModel } from 'resources/units'

const description = `The Total Expenses widget calculates the sum of all 
Work Orders associated with the current Vendor and provides an insightful breakdown.`
const VendorTotalExpensesWidget = () => {
    const { ref, match } = useResizeObserverWidth(360)

    const record = useRecordContext<UnitModel>()
    if (!record) {
        return <SliderWidgetSkeleton />
    }
    const ratioData = roundWidgetJobsData(record)
    const validData = ratioData.total > 0
    const hasData = typeof ratioData.total === 'number'

    return (
        <SliderWidget
            ref={ref}
            headerProps={{
                title: 'Total Expenses',
                label: formatMoney(ratioData.total),
                tooltipText: description,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={match}
                        validData={hasData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        {...ratioData}
                        disabled={!validData}
                    />
                ),
            }}
        />
    )
}
export default VendorTotalExpensesWidget
