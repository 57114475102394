import { type FC, useEffect, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { api, useInputStateContext } from 'core'
import { dateTimeParse } from 'lib'
import {
    getMetersResource,
    MeterInput,
    type MeterModel,
    meterTypesConfig,
    meterTypesForUnit,
    type UnitMeterTypes,
    type UnitModel,
    type UnitSettings,
} from 'resources/units'
import { type WoKeys } from 'resources/workOrders'
import { SectionTitle } from 'ui'
import { pathJoin } from 'utils'

const settingsName: WoKeys = 'unitData.settings'

const WorkOrderFormMeter = () => {
    const { getValues, watch } = useFormContext()
    const unitSettings: UnitSettings = getValues(settingsName) || watch(settingsName)
    const [lastMeters, setLastMeters] = useState<UnitModel['lastMeters']>(null)

    if (!unitSettings) {
        return null
    }

    const meters = meterTypesForUnit.filter(
        (meterType) =>
            unitSettings[meterType]?.tracked ||
            getValues(`metersStart.${meterType}` satisfies WoKeys) != null,
    )

    if (!meters.length) {
        return null
    }

    return (
        <>
            <SectionTitle
                largeVariant="subtitle1"
                frontLine
            >
                Meters
            </SectionTitle>

            <WoMetersHelperText setLastMeters={setLastMeters} />

            {meters.map((meterType) => {
                const meterInfo = unitSettings[meterType]
                const isTracked = meterInfo?.tracked
                const config = meterTypesConfig[meterType]

                return (
                    <MeterInput
                        label={'Start ' + config.name}
                        disabled={!isTracked}
                        type={meterType}
                        key={meterType}
                        source={`metersStart.${meterType}` satisfies WoKeys}
                        helperText={lastMeters && !isTracked ? 'Not Tracked' : undefined}
                        lastReading={lastMeters?.[meterType]}
                    />
                )
            })}
        </>
    )
}

export default WorkOrderFormMeter

interface HelperTextProps {
    setLastMeters: (meters: UnitModel['lastMeters']) => void
}

const WoMetersHelperText: FC<HelperTextProps> = ({ setLastMeters }) => {
    const inputState = useInputStateContext()

    if (inputState?.form?.allDisabled) {
        return null
    }

    return <WoMetersGetHelperText setLastMeters={setLastMeters} />
}

const WoMetersGetHelperText: FC<HelperTextProps> = ({ setLastMeters }) => {
    const { getValues, watch } = useFormContext()
    const unit = watch('unit') || getValues('unit')
    const date = watch('started') || getValues('started')

    useEffect(() => {
        if (!unit || !date) {
            return
        }

        api.get(pathJoin(getMetersResource(unit).resource, `current`), {
            now: dateTimeParse(date),
        })
            .then((data: { currentMeters: { [key in UnitMeterTypes]?: MeterModel | null } }) => {
                setLastMeters(data.currentMeters)
            })
            .catch(() => {
                setLastMeters(null)
            })
    }, [unit, date])

    return null
}
