import { type FC, useEffect } from 'react'

import { useEventCallback } from '@mui/material'
import { useListContext } from 'react-admin'

import { type WithChildrenNode } from 'appTypes'
import { type FilterKnownValues, type SortPayload } from 'core'
import { isEqualWith } from 'utils'

import { useListConfig } from './ListContext'

export const ThirdLayer: FC<WithChildrenNode> = ({ children }) => {
    // const [shouldRender, setShouldRender] = useState(false)
    const listContext = useListContext()
    const { state, snap } = useListConfig()

    const resetFilter = useEventCallback(() => {
        const newFilter = { ...listContext.filterValues }
        delete (newFilter as FilterKnownValues).__o
        state.config.filters.forEach((filter) => {
            delete newFilter[filter.id]
        })
        listContext.setFilters(newFilter, listContext.displayedFilters, false)
    })

    const resetSort = useEventCallback(() => {
        listContext.setSort(state.config.sort)
    })

    const filterApplied = Boolean(
        snap.config.filters?.some((f) => listContext.filterValues[f.id as string]),
    )

    useEffect(() => {
        state.functions.filter = {
            setOpen: (toggle) => {
                state.filter.isOpen = toggle
            },
            open: () => {
                state.filter.isOpen = true
            },
            close: () => {
                state.filter.isOpen = false
            },
            reset: resetFilter,
        }

        state.functions.sort = {
            setOpen: (toggle) => {
                state.sort = toggle
            },
            open: () => {
                state.sort = true
            },
            close: () => {
                state.sort = false
            },
            areEqual: (sort1, sort2) => {
                return isEqualWith(sort1, sort2)
            },
            reset: resetSort,
        }

        // setShouldRender(true)
    }, [])

    useEffect(() => {
        state.isDefaultSorted = state.functions.sort.areEqual(
            snap.config.sort,
            listContext.sort as SortPayload,
        )
    }, [snap.config.sort, listContext.sort])

    useEffect(() => {
        state.filter.isApplied = filterApplied
    }, [filterApplied])

    // if (!shouldRender) {
    //     return null
    // }

    return <>{children}</>
}
