import { type ReactElement } from 'react'

import { type NoInfer } from 'appTypes'
import Icons from 'assets/icons'
import { type FilterBy, FilterValueInput, type DataListDetails, type Column } from 'components'
import { IconElement, TextWithIcon } from 'ui'

import { AssignmentsStatusKeys, type AssignmentModel } from '../types'

const source = 'status' satisfies keyof AssignmentModel

const label = 'Status'

const statusConfigs: {
    [key in AssignmentsStatusKeys]: {
        text: string
        icon: ReactElement
    }
} = {
    [AssignmentsStatusKeys.ONGOING]: {
        icon: (
            <IconElement
                component={Icons.StatusOpen}
                color={(theme) => theme.palette.primary.main}
            />
        ),
        text: 'Ongoing',
    },
    [AssignmentsStatusKeys.COMPLETED]: {
        icon: (
            <IconElement
                component={Icons.CheckCircle}
                color={(theme) => theme.palette.success.main}
            />
        ),
        text: 'Completed',
    },
}
const value = (status: AssignmentsStatusKeys) => {
    const config = statusConfigs[status]
    return <TextWithIcon icon={config.icon}>{config.text}</TextWithIcon>
}
const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: ({ value: statusValue }) => {
            return value(statusValue)
        },
    }) as const satisfies Column

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={(record) => {
                    return value(record.id as AssignmentsStatusKeys)
                }}
            />
        ),
    }) as const satisfies FilterBy

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (statusValue) => value(statusValue),
    }) as const satisfies DataListDetails<any, any>

export const status = {
    source,
    label,
    tableColumn,
    filter,
    value,
    dataCardRow,
}
