import { type ListControllerResult } from 'react-admin'

import Icons from 'assets/icons'
import { useCreateResourcePath, type ActionChildren, type ListActionExtendedOption } from 'core'

import StatusMenu from './StatusMenu'

interface UnitStatusBulkActionProps {
    children: ActionChildren<{}, ListActionExtendedOption>
    listContext?: ListControllerResult
}
const UnitStatusBulkAction = ({ children, listContext }: UnitStatusBulkActionProps) => {
    const createPath = useCreateResourcePath()
    const { onSelect, selectedIds, resource } = listContext

    const resourcePath =
        createPath({ resource, type: 'list' }) +
        '/' +
        'status' +
        '_bulk?id=' +
        selectedIds.join(',')

    return (
        <StatusMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            endpoint={resourcePath}
            renderToggler={(open) =>
                children({
                    Icon: Icons.Status1,
                    title: 'Change Unit Status',
                    onClick: open,
                })
            }
            onSuccess={() => {
                onSelect([])
            }}
        />
    )
}

export const unitStatusBulkAction = (params: UnitStatusBulkActionProps) => (
    <UnitStatusBulkAction
        {...params}
        key="unit-status-bulk-action"
    />
)
