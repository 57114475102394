import { type NoInfer, type DataRecord } from 'appTypes'
import { type FilterBy, type SortBy, type Column, type DataListDetails } from 'components'

import { type CK2Model } from '../types'

const source = 'equipmentCategory' as const
const label = 'Equipment Category'

const value = (record: CK2Model) => (record ? record.description : '')

const column = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToRecord,
}: {
    id?: Source
    label?: string
    dataToRecord: (data: DataRecord) => CK2Model
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => value(dataToRecord(row)),
    }) as const satisfies Column

const cardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CK2Model
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies SortBy

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies FilterBy

const self = { column, cardRow, sort, filter, label, value }

export default self
