import { type NoInfer } from 'appTypes'
import {
    type FilterBy,
    FilterRangeInput,
    type SortBy,
    type Column,
    costMaskParams,
    type DataListDetails,
    formatMoney,
} from 'components'

import { type InvoiceModel } from '../types'

const source = 'total' as const satisfies keyof InvoiceModel
const label = 'Total'

interface Data extends Pick<InvoiceModel, 'total'> {}

const value = (record: Data) => formatMoney(record.total)

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
        align: 'right',
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies FilterBy

const total = {
    source,
    label,
    value,
    filter,
    sort,
    tableColumn,
    dataCardRow,
}

export default total
