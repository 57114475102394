import { type FilterBy, FilterValueInput } from 'components'

import { type WorkOrderModel } from '../types'
import { woTypeChoices } from '../utils'

const source = 'type' as keyof WorkOrderModel

const label = 'WO Type'

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                choices={woTypeChoices}
            />
        ),
    }) as const satisfies FilterBy

export const woType = {
    source,
    label,
    filter,
}
