import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { alpha, styled, themeColor, type ThemeColorType } from 'lib'

interface Props extends WithChildrenNode {
    color?: ThemeColorType
    bgcolor?: ThemeColorType
    disabled?: boolean
    bg?: ThemeColorType
}

const Boxed: FC<Props> = styled('div', {
    shouldForwardProp: (prop: keyof Props) =>
        prop !== 'color' && prop !== 'bgcolor' && prop !== 'disabled',
})<Props>(({ theme, bgcolor: bgColorProp, bg: bgProp, color: colorProp, disabled }) => {
    let color: string
    let bg: string

    if (disabled) {
        color = alpha(theme.palette.text.primary, 0.26)
        bg = alpha(theme.palette.text.primary, 0.12)
    } else {
        if (colorProp) {
            color = themeColor(colorProp, theme)
        }
        if (bgProp) {
            bg = themeColor(bgProp, theme)
        } else {
            const bgcolor = bgColorProp ? themeColor(bgColorProp, theme) : color
            if (bgcolor) {
                bg = alpha(bgcolor, 0.15)
            }
        }
    }

    return {
        borderRadius: '4px',
        padding: '3px',
        boxSizing: 'border-box',

        color,
        backgroundColor: bg,

        '& > svg': {
            display: 'block',
        },
    }
})

export default Boxed
