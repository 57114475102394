import { type FC } from 'react'

import Icons from 'assets/icons'
import { useRecordContext, downloadOneAction } from 'core'
import { IconBox } from 'ui'

import { type PurchaseOrderModel } from '../types'
import { poResource } from '../utils'

export const PoExportActionIconButton: FC<{ po?: Partial<PurchaseOrderModel> }> = ({ po }) => {
    const poFromContext = useRecordContext<PurchaseOrderModel>()
    const finalPurchaseOrder = po || poFromContext

    return downloadOneAction({
        children: ({ onClick }) => (
            <IconBox
                title="Export PO"
                onClick={onClick}
            >
                <Icons.Pdf />
            </IconBox>
        ),
        resource: poResource,
        id: finalPurchaseOrder.id,
        filename: finalPurchaseOrder.number,
        type: 'pdf',
    })
}
