import { telematicsExtras } from 'resources/telematics'
import { type MeterModel } from 'resources/units'

export const meterSourceMap: { [key in MeterModel['source']]?: string } = {
    WORK_ORDER: 'Work Order',
    PM_SCHEDULE: 'PM Schedule',
    MANUAL: 'Manual Entry',
    ECOLANE: 'Ecolane',
}

export const translateMeterSource = (source: MeterModel['source']) => {
    if (meterSourceMap[source]) {
        return meterSourceMap[source]
    }
    const integrationName = telematicsExtras[source.toLowerCase()]?.providerName

    if (integrationName) {
        return 'Integration - ' + integrationName
    }

    return ''
}
