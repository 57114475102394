import { type NoInfer } from 'appTypes'
import { type FilterBy, FilterDateRangeValue, type DataListDetails, type Column } from 'components'
import { formatDate } from 'lib'

import { type AssignmentModel } from '../types'

const source = 'start' satisfies keyof AssignmentModel

const label = 'Start Date & Time'

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: ({ value }) => {
            return formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)
        },
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    }) as const satisfies FilterBy

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
    }) as const satisfies DataListDetails<any, any>
export const startDate = {
    source,
    label,
    tableColumn,
    sort,
    filter,
    dataCardRow,
}
