import { type MouseEvent } from 'react'

import { type IntervalsType, type PmModel } from 'resources/pm'
import { getMeterConfig, getMeterIcon } from 'resources/units'
import { SvgIcon, ToggleButton, ToggleButtonGroup, BoxContainer, Typography } from 'ui'

import PmBadge from './PMBadge'
interface MeterTypeSelectionButtonProps {
    selectedPM: IntervalsType
    setSelectedPM: React.Dispatch<React.SetStateAction<IntervalsType>>
    unitPmRecord: PmModel['intervals']
}

const MeterTypeSelectionButton = ({
    selectedPM,
    setSelectedPM,
    unitPmRecord,
}: MeterTypeSelectionButtonProps) => {
    const handleAlignment = (event: MouseEvent, newMeter: IntervalsType['type']) => {
        setSelectedPM(() =>
            newMeter ? unitPmRecord.find((interval) => interval.type === newMeter) : selectedPM,
        )
    }

    const firstMeter = getMeterConfig(unitPmRecord[0].type)
    const secondMeter = unitPmRecord.length > 1 ? getMeterConfig(unitPmRecord[1].type) : null
    const selectedMeterName = getMeterConfig(selectedPM.type).name

    return (
        <BoxContainer justifyContent="space-between">
            <Typography
                variant="tooltip"
                color="text.primary"
                textTransform="uppercase"
            >
                {selectedMeterName}
            </Typography>
            <ToggleButtonGroup
                value={selectedPM.type}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
            >
                <ToggleButton
                    value={firstMeter.id}
                    sx={{ width: '24px', height: '24px' }}
                    aria-label={firstMeter.name}
                >
                    <PmBadge
                        unitPmRecord={unitPmRecord[0]}
                        disabled={!secondMeter}
                    >
                        <SvgIcon sx={{ width: '16px', height: '16px' }}>
                            {getMeterIcon(firstMeter.id)}
                        </SvgIcon>
                    </PmBadge>
                </ToggleButton>
                {secondMeter && (
                    <ToggleButton
                        value={secondMeter.id}
                        sx={{ width: '24px', height: '24px' }}
                        aria-label={secondMeter.name}
                    >
                        <PmBadge unitPmRecord={unitPmRecord[1]}>
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                {getMeterIcon(secondMeter.id)}
                            </SvgIcon>
                        </PmBadge>
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </BoxContainer>
    )
}
export default MeterTypeSelectionButton
