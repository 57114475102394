import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { createModifiers, ModifierProvider } from 'context'
import { clsx, globalClassNames, withColor } from 'lib'
import { Box, type TextModifiers } from 'ui'

const BottomContent: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Box mt="auto">
            <ModifierProvider value={modifiers}>{children}</ModifierProvider>
        </Box>
    )
}

export default BottomContent

const modifiers = createModifiers<TextModifiers>({
    text: (props) => ({
        color: withColor('text.secondary'),
        variant: 'chartsBody',
        ...props,
        className: clsx(globalClassNames.ellipsis, props.className),
    }),
})
