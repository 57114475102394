import { type ReactElement } from 'react'

import { type Identifier, type NoInfer } from 'appTypes'
import { type FilterBy, type Column, FilterValueInput } from 'components'
import { registerResource } from 'resources/base'

import { type UnitModel } from '../types'

import { StatusField, unitStatusBulkAction } from './components'
import { type UnitStatusModel } from './types'
import { unitStatusResource } from './utils'

const source = 'status' as const satisfies keyof UnitModel

const statusLabel = 'Status'
const statusLongName = 'Unit Status'

const value = (status: UnitStatusModel, unitId?: Identifier): ReactElement => (
    <StatusField
        record={status}
        unitId={unitId}
    />
)
const valueByParent = <T extends Pick<UnitModel, 'statusData' | 'id'>>(parent: T) =>
    value(parent?.statusData)

const tableColumn = ({
    label,
    render,
}: {
    label?: string
    render: (data: unknown) => ReactElement
}) =>
    ({
        field: source,
        headerName: label || statusLabel,
        renderCell: ({ row }) => {
            return render(row)
        },
    }) as const satisfies Column

const sort = ({ label: labelProp }: { label?: string } = {}) =>
    ({
        id: source,
        label: labelProp || statusLabel,
    }) as const

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: {
    id?: Source
    label?: string
} = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: labelProp || statusLabel,
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={value}
                itemReference={unitStatusResource.resource}
            />
        ),
    }) as const satisfies FilterBy

const status = {
    source,
    label: statusLabel,
    longLabel: statusLongName,
    value,
    valueByParent,
    sort,
    filter,
    tableColumn,
    unitStatusBulkAction,
    show: true,
}

const noStatuses: typeof status = {
    ...status,
    value: () => null,
    valueByParent: () => null,
    tableColumn: () => null,
    filter: () => null,
    sort: () => null,
    unitStatusBulkAction: () => null,
    show: false,
}

export type UnitStatusConfig = {
    unitStatus: typeof status
}

const getStatus = (hasUnitStatuses: boolean): typeof status => {
    if (hasUnitStatuses) {
        return status
    }

    return noStatuses
}

export const registerUnitStatus = (resources) => {
    registerResource(
        (store) => store.companySettings.hasUnitStatuses,
        (v) => {
            resources.unitStatus = getStatus(v)
        },
    )
}
