import avatar from './avatar'
import { completed } from './completed'
import { created } from './created'
import { customer } from './customer'
import { duration } from './duration'
import { number } from './number'
import { priority } from './priority'
import repairPriorityClass from './repairPriorityClass'
import self from './self'
import status from './status'
import total from './total'
import { woType } from './type'

const woFields = {
    self,
    avatar,
    number,
    created,
    completed,
    duration,
    priority,
    repairPriorityClass,
    customer,
    total,
    status,
    type: woType,
}

export default woFields
