import { type FC } from 'react'

import { useArchivedContext } from 'context'
import { useRecordContext } from 'core'
import { unitAspects, UnitsListController, UnitsListUi } from 'resources/units'
import { displayBooleanValue } from 'utils'

import Header from './Header'

export const CustomerUnitsList: FC = () => {
    const record = useRecordContext()
    const { isArchived } = useArchivedContext()

    return (
        <UnitsListController
            key={String(isArchived)}
            isLoading={!record}
            filter={{
                withAspects: [unitAspects.costPerMeter, unitAspects.totals],
                customer: record?.name,
                archived: displayBooleanValue(isArchived),
            }}
            removeFilters
            preferencesName={getPreferencesName(isArchived)}
            clearResource
        >
            <Header isArchived={isArchived} />
            <UnitsListUi disableExportButton />
        </UnitsListController>
    )
}

const getPreferencesName = (isArchived: boolean): string =>
    `customer-units${isArchived ? '-archived' : ''}`
