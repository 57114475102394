import { type Identifier } from 'appTypes'
import { type ResourceConfig } from 'core'
import { pathJoin } from 'utils'

const resource = 'invoices'

export const enum InvoiceStatusActionKeys {
    CANCEL = 'cancel',
    REOPEN = 'reopen',
    CLOSE = 'close',
    RESET = 'reset',
}
export const invoicesResource = {
    name: 'invoices',
    resource,
    statusAction: (id: Identifier, action: InvoiceStatusActionKeys) =>
        pathJoin(resource, id, action),
    payments: (id: Identifier) => pathJoin(resource, id, 'payments'),
    sellingPrice: (id: Identifier) => pathJoin(resource, id, 'selling_price'),
} satisfies ResourceConfig
