import { type Identifier } from 'appTypes'
import { type ResourceConfig } from 'core'
import { pathJoin } from 'utils'

import { type WoActionKeys } from './actions'
const resource = 'work-orders'

export const woResource = {
    name: 'work-orders',
    resource,
    jobsResource: (id: Identifier) => pathJoin(resource, id, 'jobs'),
    updateStatus: (id: Identifier, action: WoActionKeys) => pathJoin(resource, id, action),
    edit: (id: Identifier) => pathJoin(resource, id),
    invoice: (id: Identifier) => pathJoin(resource, id, 'invoice'),
} satisfies ResourceConfig

export const woPermissions = {
    reopen: 'reopen',
    close: 'close',
    cancel: 'cancel',
}

export interface WoTypeChoice {
    id: Identifier
    name: string
}

export const woTypeChoices: WoTypeChoice[] = [
    { id: 'internal', name: 'Internal' },
    { id: 'vendor', name: 'Vendor' },
]
