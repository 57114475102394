import { ViewHeader } from 'components'
import { useShowContext } from 'core'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { GridContainerColumns, GridItemLayout, Section, PageContent } from 'ui'

import ReceiptsList from '../LineItems/components/ReceiptsList'
import { ReturnItemsTotalSection } from '../ReturnItems/components'
import { PurchaseOrderCardSkeleton } from '../components'

import {
    LineItemsSection,
    LineItemsSectionSkeleton,
    PurchaseOrderDetails,
    TotalPurchaseSection,
    VendorSection,
} from './components'

const PoOverviewSkeleton = () => {
    return (
        <PageContent>
            <Section>
                <ViewHeader
                    title=""
                    loading
                />
                <GridContainerColumns>
                    <GridItemLayout>
                        <PurchaseOrderCardSkeleton height="221px" />
                    </GridItemLayout>
                </GridContainerColumns>
            </Section>
            <Section>
                <ViewHeader
                    title=""
                    loading
                />
                <GridContainerColumns>
                    <GridItemLayout>
                        <PurchaseOrderCardSkeleton height="161px" />
                    </GridItemLayout>
                </GridContainerColumns>
            </Section>
            <Section>
                <ViewHeader
                    title=""
                    loading
                />
                <GridContainerColumns>
                    <GridItemLayout>
                        <PurchaseOrderCardSkeleton height="219px" />
                    </GridItemLayout>
                </GridContainerColumns>
            </Section>
            <Section>
                <ViewHeader
                    title=""
                    loading
                />
                <LineItemsSectionSkeleton />
            </Section>
        </PageContent>
    )
}
const PurchaseOrderOverview = () => {
    const { record, isLoading } = useShowContext<PurchaseOrderModel>()

    if (isLoading) {
        return <PoOverviewSkeleton />
    }

    return (
        <PageContent>
            {record.type === 'CREDIT' && record.status !== 'CANCELED' ? (
                <ReturnItemsTotalSection />
            ) : null}
            <TotalPurchaseSection />
            <PurchaseOrderDetails />
            <VendorSection />
            {record.type === 'STANDARD' ? (
                <ReceiptsList perPage={record.closedOn ? 3 : 2}>
                    <LineItemsSection />
                </ReceiptsList>
            ) : null}
        </PageContent>
    )
}

export default PurchaseOrderOverview
