import { type ReactElement, type FC } from 'react'

import { type Identifier } from 'appTypes'
import {
    costMaskParams,
    DateInput,
    TextInput,
    useOpenUtilityDrawer,
    UtilityDrawerEditor,
} from 'components'
import { useShowContext, dispatchAction, requiredValidation } from 'core'
import { partsResource } from 'resources/parts'
import { purchaseHistoryResourceName } from 'resources/purchaseHistory'
import { VendorInput } from 'resources/vendors'

interface PurchaseHistoryDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
}
export const createResourcePath = (id: Identifier) =>
    `${partsResource.resource}/${id}/purchase-history`
const PurchaseHistoryDrawerToggler: FC<PurchaseHistoryDrawerTogglerProps> = ({ children, id }) => {
    const open = useOpenUtilityDrawer()
    const { record } = useShowContext()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Manual Entry' : 'Add Manual Entry',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        onSuccess={() => {
                            dispatchAction(purchaseHistoryResourceName, {
                                name: id ? 'edit' : 'create',
                            })
                        }}
                    />
                ),
                renderContent: () => <PurchaseHistoryForm />,
                renderBottomRight: (render) => render({ label: id ? undefined : 'Add Entry' }),
            },
            extraArgs: {
                id,
                resource: {
                    name: purchaseHistoryResourceName,
                    resource: createResourcePath(record.id),
                },
            },
        })
    })
}

export default PurchaseHistoryDrawerToggler

const PurchaseHistoryForm = () => {
    return (
        <>
            <VendorInput required />
            <TextInput
                source="price"
                label="Part Cost"
                validate={requiredValidation}
                {...costMaskParams}
            />
            <DateInput
                source="purchaseDate"
                label="Purchase Date"
                validate={requiredValidation}
                defaultValue={new Date()}
            />
        </>
    )
}
