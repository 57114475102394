import { type ReactElement } from 'react'

import { type Size } from 'appTypes'
import { Link, Navigate, Route, matchPath, useParams, classes } from 'lib'
import { Tab, Tabs, tabsClasses, Skeleton, BoxContainer } from 'ui'

export interface TabType {
    label: string | ReactElement
    value: string
    path?: string
}

export interface LinkTabsProps {
    tabs?: TabType[]
    tabWidth?: Size
    tabMinWidth?: Size
    loading?: boolean
}

const tabsSkeletons = (
    <BoxContainer>
        {new Array(4).fill(0).map((v, i) => (
            <BoxContainer
                key={i}
                sx={{
                    minHeight: '48px',
                    minWidth: '120px',
                    justifyContent: 'center',
                }}
            >
                <Skeleton
                    width="80px"
                    height="18px"
                />
            </BoxContainer>
        ))}
    </BoxContainer>
)

const LinkTabs = ({ tabs = [], tabWidth, tabMinWidth, loading }: LinkTabsProps) => {
    const params = useParams()

    if (loading) {
        return tabsSkeletons
    }

    if (!tabs.length) {
        return null
    }

    const tabValue = tabs.find((tab) =>
        tab
            ? matchPath(
                  {
                      path: `/${tab.value}`,
                  },
                  `/${params['*']}`,
              )
            : false,
    )

    return (
        <Tabs
            indicatorColor="primary"
            allowScrollButtonsMobile
            variant="scrollable"
            value={tabValue?.value || ''}
            sx={{
                background: 'white',
                [`& .${tabsClasses.scrollButtons}`]: (theme) => ({
                    color: theme.palette.text.primary,
                    opacity: 0.54,
                    [`&.${classes.disabled}`]: {
                        opacity: 0.26,
                    },
                }),
            }}
        >
            {tabs.map((tab) => {
                return (
                    <Tab<typeof Link>
                        sx={(theme) => ({
                            minWidth: tabMinWidth ?? 'fit-content',
                            flex: 1,
                            maxWidth: tabWidth,
                            [theme.breakpoints.down('sm')]: {
                                maxWidth: 'unset',
                            },
                        })}
                        key={tab.value}
                        value={tab.value}
                        label={tab.label}
                        component={Link}
                        to={{ pathname: tab.path || tab.value }}
                    />
                )
            })}
        </Tabs>
    )
}

export default LinkTabs

export const tabRoutes = (
    items: PageTabConfig[],
    {
        loading = false,
    }: {
        loading?: boolean
    } = {},
) => {
    const pages = items.filter(Boolean)
    const tabs = pages.filter((tab) => !tab.tabHidden).map(({ element, ...page }) => page)
    const routes = pages.map(({ value, element }) => (
        <Route
            key={value}
            path={value}
            element={element}
        />
    ))
    if (!loading) {
        routes.push(
            <Route
                key="fallback"
                path="*"
                element={
                    <Navigate
                        replace
                        to=""
                    />
                }
            />,
        )
    }

    return {
        tabs,
        routes,
    }
}

export interface PageTabConfig extends TabType {
    element: ReactElement
    tabHidden?: boolean
}
