import {
    type ListUiProps,
    ListController,
    ListUi,
    type ListControllerProps,
    ListFilterNoResults,
    type PeriodFilter,
} from 'components'
import { useMediaQuery } from 'lib'

import PeriodSelector, { defaultReportPeriod } from './PeriodSelector'

export const reportPeriodKeys: (keyof PeriodFilter)[] = ['periodStart', 'periodEnd']

export const ListUiWithPeriod = (props: ListUiProps<any>) => {
    const match = useMediaQuery((theme) => theme.breakpoints.down('lg'))

    return (
        <ListUi
            {...props}
            renderNoResults={() => (
                <ListFilterNoResults
                    disableFilter
                    subtitle={
                        <>
                            No results match your criteria.
                            <br />
                            Try modifying the search, filters or the
                            <br />
                            reporting period.
                        </>
                    }
                />
            )}
            toolbarContent={
                match
                    ? { contentUnderToolbar: <PeriodSelector sx={{ pb: '20px' }} /> }
                    : { contentBeforeViews: <PeriodSelector /> }
            }
        />
    )
}

export const ListControllerWithPeriod = (props: ListControllerProps<any>) => {
    return (
        <ListController
            {...props}
            filterValues={defaultReportPeriod()}
        />
    )
}
