import { type FC } from 'react'

import { Anchor } from 'ui'

import { type PaymentModel } from '../types'

import PaymentDrawerToggler from './PaymentDrawerToggler'

interface Props {
    payment: PaymentModel
    index: number
    readOnly: boolean
    refund?: boolean
    invoicesUrl?: string
}

const PaymentCardTitle: FC<Props> = ({ invoicesUrl, refund, payment, index, readOnly }) => {
    return (
        <PaymentDrawerToggler
            id={payment.id}
            readOnly={readOnly}
            refund={refund}
            invoicesUrl={invoicesUrl}
        >
            {(open) => (
                <Anchor
                    onClick={open}
                    variant="subtitle2"
                >
                    {refund ? 'Refund' : 'Payment'} {index}
                </Anchor>
            )}
        </PaymentDrawerToggler>
    )
}

export default PaymentCardTitle
