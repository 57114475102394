import { type FC } from 'react'

import { inject } from 'mobx-react'

import Icons from 'assets/icons'
import { SliderView, ViewHeader } from 'components'
import { useShowContext, type AuthStore } from 'core'
import { TagsCard } from 'resources/tags'
import { UnitDrawerToggler, isUnitArchived, type UnitModel } from 'resources/units'
import { IconBox, Section } from 'ui'

import { UnitSliderSkeleton } from '../../components'

import { BasicDetails, Specifications, Engine, Transmission } from './Slides'
import Body from './Slides/Body'

const skeletonSlides = new Array(4).fill(0).map((v, i) => <UnitSliderSkeleton key={i} />)
const UnitDetails: FC = inject('auth')(({ auth }: { auth: AuthStore }) => {
    const { isLoading, record } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(record)

    const maxVisibleColumns =
        5 + (auth.companySettings.hasCostCenters || auth.companySettings.hasDivisions ? 1 : 0)

    const slides = [
        <BasicDetails maxVisibleColumns={maxVisibleColumns} />,
        <UnitDrawerToggler id={record?.id}>
            {({ onClick }) => (
                <TagsCard
                    create={isArchived ? false : onClick}
                    tags={record?.tagsData}
                />
            )}
        </UnitDrawerToggler>,
        <Specifications maxVisibleColumns={maxVisibleColumns} />,
        ...(record?.body ? [<Body maxVisibleColumns={maxVisibleColumns} />] : []),
        <Engine />,
        <Transmission />,
    ]
    return (
        <Section>
            <ViewHeader
                title="Details"
                loading={isLoading}
            >
                <ViewHeader.Content placement="rightMobile">
                    {isArchived ? null : (
                        <UnitDrawerToggler id={record?.id}>
                            {({ onClick }) => (
                                <IconBox
                                    title="Edit"
                                    onClick={onClick}
                                >
                                    <Icons.Edit />
                                </IconBox>
                            )}
                        </UnitDrawerToggler>
                    )}
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView
                height={maxVisibleColumns === 5 ? '212px' : '246px'}
                items={isLoading ? skeletonSlides : slides}
            />
        </Section>
    )
})

export default UnitDetails
