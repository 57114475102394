import { type ReactElement } from 'react'

import { type NoInfer } from 'appTypes'
import Icons from 'assets/icons'
import { type FilterBy, FilterValueInput, type SortBy, type Column } from 'components'
import { options } from 'resources/common'

import { WoRepairClassKeys, type WorkOrderModel } from '../types'

interface RcConfig {
    text: string
    icon: ReactElement
    id: WoRepairClassKeys
}

const items = [
    {
        id: WoRepairClassKeys.SCHEDULED,
        text: 'Scheduled',
        icon: (
            <Icons.InsertInvitationOutlined
                color="success"
                fontSize="small"
            />
        ),
    },
    {
        id: WoRepairClassKeys.NON_SCHEDULED,
        text: 'Non-Scheduled',
        icon: (
            <Icons.EventBusyOutlined
                color="warning"
                fontSize="small"
            />
        ),
    },
    {
        id: WoRepairClassKeys.EMERGENCY,
        text: 'Emergency',
        icon: (
            <Icons.CalendarTodayOutlined
                color="error"
                fontSize="small"
            />
        ),
    },
] satisfies RcConfig[]

const getConfig = options.createConfig<RcConfig>(items)

const value = options.makeIconValue(getConfig)

const source = 'repairPriorityClass' as keyof WorkOrderModel

const label = 'Repair Class'

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.value),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={(record) => {
                    return value(record.id as WoRepairClassKeys)
                }}
            />
        ),
    }) as const satisfies FilterBy

const repairPriorityClass = {
    source,
    label,
    tableColumn,
    sort,
    filter,
    choices: options.createIdChoices(items),
    inputValue: options.makeIdValue(value),
    value: (record: Pick<WorkOrderModel, 'repairPriorityClass'>) =>
        value(record.repairPriorityClass),
    getConfig,
}

export default repairPriorityClass
