import { observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    ViewHeader,
    ListTotalBadge,
    type CardListConfig,
    ListAvatar,
    type DatagridColumnsProps,
    formatMoney,
    ResourceLinkButton,
    costMaskParams,
    datagridAvatarColumn,
    FilterDateRangeValue,
    FilterRangeInput,
    ListController,
    type ListControllerProps,
    ListUi,
} from 'components'
import { urls } from 'configs'
import { useShowContext, multiselectAction, type SortPayload } from 'core'
import { formatDate } from 'lib'
import { useInventoryContext } from 'resources/inventory'
import { type PartModel } from 'resources/parts'
import { purchaseHistoryResourceName } from 'resources/purchaseHistory'
import { vendorFields, vendorsResource } from 'resources/vendors'
import { IconBox, PageContent, Typography } from 'ui'

import {
    PurchaseHistoryDrawerToggler,
    type PurchaseHistoryModel,
    actions,
    bulkActions,
    createResourcePath,
} from './components'

const defaultSort: SortPayload<PurchaseHistoryModel> = {
    field: 'purchaseDate',
    order: 'DESC',
}
const sorts: ListControllerProps<PurchaseHistoryModel>['sorts'] = [
    { id: 'vendor', label: 'Vendor' },
    { id: 'purchaseDate', label: 'Purcahse Date' },
    { id: 'price', label: 'Part Cost' },
]

const filters: ListControllerProps<PurchaseHistoryModel>['filters'] = [
    { id: 'vendor', label: 'Vendor' },
    {
        id: 'purchaseDate',
        label: 'Purcahse Date',
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    },
    {
        id: 'price',
        label: 'Part Cost',
        renderComponent: (props) => (
            <FilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    },
]

const columnsConfig: DatagridColumnsProps<PurchaseHistoryModel & { avatar: string }> = {
    avatarSource: 'avatar',
    mainField: 'purchaseDate',
    columns: [
        datagridAvatarColumn({
            field: 'avatar',
            headerName: 'Avatar',
            avatar: (record) => (
                <ListAvatar
                    id={record.id}
                    customPath={{
                        resource: vendorsResource.resource,
                        id: record.vendor,
                        type: 'edit',
                    }}
                    imageSrc={record.vendorData.logo}
                    defaultImage={<vendorFields.avatar.Icon />}
                />
            ),
        }),
        {
            field: 'vendor',
            headerName: 'Vendor',
            renderCell: ({ row }) => (
                <ResourceLinkButton
                    to={{ type: 'edit', id: row.vendor, resource: vendorsResource.resource }}
                >
                    {row.vendorData.name}
                </ResourceLinkButton>
            ),
        },
        {
            headerName: 'Purchase Date',
            field: 'purchaseDate',
            valueFormatter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDate),
        },
        {
            headerName: 'Part Cost',
            field: 'price',
            renderCell: ({ value }) => formatMoney(value),
            align: 'right',
        },
    ],
    actions: ({ id }, { children }) => actions(id, children),
}
const navigateTo = (id: Identifier) => `${urls.vendors}/${id}`
const cardsConfig: CardListConfig<PurchaseHistoryModel> = {
    titleSource: (record) => record.vendorData.name,
    imageSource: (record) => record.vendorData.logo,
    titleLink: (record) => navigateTo(record.vendor),
    defaultImage: <vendorFields.avatar.Icon />,
    details: [
        {
            label: 'Purchase Date',
            source: 'purchaseDate',
            render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDate),
        },
        {
            label: 'Part Cost',
            source: 'price',
            render: (value) => formatMoney(value),
        },
    ],
    actions: ({ id }, { children }) => [
        ...actions(id, children),
        multiselectAction({
            children,
            id,
        }),
    ],
}

const PurchaseHistory = observer(() => {
    const { record } = useShowContext<PartModel>()
    const inventoryView = useInventoryContext()

    if (!record) {
        return null
    }

    const path = createResourcePath(record.id)

    return (
        <PageContent>
            <ListController<PurchaseHistoryModel>
                resource={path}
                sort={defaultSort}
                preferencesName={purchaseHistoryResourceName}
                sorts={sorts}
                filters={filters}
            >
                <ViewHeader title="Purchase History">
                    <ViewHeader.Content>
                        <ListTotalBadge />
                    </ViewHeader.Content>
                    {inventoryView ? null : (
                        <ViewHeader.Content placement="rightMobile">
                            <PurchaseHistoryDrawerToggler>
                                {(open) => (
                                    <IconBox
                                        onClick={open}
                                        title="Add Manual Entry"
                                    >
                                        <Icons.Add />
                                    </IconBox>
                                )}
                            </PurchaseHistoryDrawerToggler>
                        </ViewHeader.Content>
                    )}
                </ViewHeader>
                <ListUi
                    exportFileName={`${record.number}-purchase-history`}
                    bulkActions={bulkActions}
                    columnsCfg={columnsConfig}
                    cardsCfg={cardsConfig}
                    listFTUProps={{
                        title: 'No Data',

                        action: inventoryView ? null : (
                            <PurchaseHistoryDrawerToggler>
                                {(onClick) => (
                                    <Typography
                                        color="primary.main"
                                        onClick={onClick}
                                        sx={{ cursor: 'pointer' }}
                                        variant="body1"
                                    >
                                        Add Manual Entry
                                    </Typography>
                                )}
                            </PurchaseHistoryDrawerToggler>
                        ),
                        imageSrc: images.purchaseHistoryFtu,
                        linkText: null,
                    }}
                />
            </ListController>
        </PageContent>
    )
})

export default PurchaseHistory
