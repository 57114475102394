import Icons from 'assets/icons'
import { SkeletonSliderCardContainer, SliderView, ViewHeader } from 'components'
import { ActionsMenu, useRecordContext } from 'core'
import { InventoryItemDrawerToggler } from 'pages/Inventory/List/components'
import { AdjustItemDrawerToggler } from 'pages/Inventory/components'
import { type PartInInventoryModel } from 'resources/inventory'
import { Section, Skeleton } from 'ui'

import { CostCard } from './CostCard'
import { LocationCard } from './LocationCard'
import { QuantityCard } from './QuantityCard'

const slides = [<QuantityCard />, <CostCard />, <LocationCard />]

const InventoryDetails = () => {
    const record = useRecordContext<PartInInventoryModel>()

    return (
        <Section>
            <ViewHeader
                title="Inventory Details"
                loading={!record}
            >
                <ViewHeader.Content placement="rightMobile">
                    <ActionsMenu
                        actions={(_, { children }) => [
                            <InventoryItemDrawerToggler
                                key="edit"
                                id={record.inventoryItem.id}
                                children={({ onClick }) =>
                                    children({
                                        Icon: Icons.Edit,
                                        title: 'Edit/View',
                                        onClick,
                                    })
                                }
                            />,
                            <AdjustItemDrawerToggler
                                record={record?.inventoryItem}
                                key="manually-adjust"
                                children={({ onClick }) =>
                                    children({
                                        Icon: Icons.Calculate,
                                        title: 'Manually Adjust',
                                        onClick,
                                    })
                                }
                            />,
                        ]}
                    />
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView items={record ? slides : slides.map((_, i) => <SlideSkeleton key={i} />)} />
        </Section>
    )
}

export default InventoryDetails

const SlideSkeleton = () => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height: '220px',
                gap: '10px',
            }}
        >
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}
