import { type NoInfer } from 'appTypes'
import {
    FilterRangeInput,
    type FilterBy,
    type SortBy,
    type Column,
    type DataListDetails,
    formatMoney,
    costMaskParams,
} from 'components'

import { type PartModel } from '../types'

import { partLongSource } from './utils'

const source = 'cost' satisfies keyof PartModel
const longSource = partLongSource(source)

const label = 'Last Known Cost'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => PartModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => formatMoney(dataToValue(row)),
        align: 'right',
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => PartModel[typeof source]
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => formatMoney(dataToValue(data)),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies FilterBy

export const cost = {
    source,
    longSource,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}
