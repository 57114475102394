import { type FC } from 'react'

import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { useRecordContext } from 'core'
import { type PartInInventoryModel } from 'resources/inventory'

export const LocationCard: FC = () => {
    const record = useRecordContext<PartInInventoryModel>()
    const inventoryItem = record.inventoryItem

    return (
        <InfoCard>
            <InfoCardHeader title="Location" />
            <InfoCardDetails
                details={[
                    {
                        label: 'Repair Shop',
                        source: () => inventoryItem.shopData.name,
                    },
                    {
                        label: 'Bin Location Id',
                        source: () => inventoryItem.binLocationId,
                    },
                    {
                        label: 'Bin Description',
                        source: () => inventoryItem.binDescription,
                    },
                ]}
            />
        </InfoCard>
    )
}
