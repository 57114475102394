import Icons from 'assets/icons'
import { HideOnBreakpoint, Typography, BoxContainer, SvgIcon, IconButton, Button } from 'ui'

import { useListConfig } from '../ListContext'

const SortButton = () => {
    const { snap, state } = useListConfig()
    const isDefaultSorted = snap.isDefaultSorted

    const Icon = isDefaultSorted ? Icons.Sort : Icons.SortActive

    return (
        <BoxContainer>
            <Typography
                component="div"
                color="text"
            >
                <HideOnBreakpoint
                    replaceWith={
                        <IconButton
                            aria-label="Open Sort"
                            size="small"
                            color="inherit"
                            onClick={state.functions.sort.open}
                        >
                            <SvgIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                }}
                                inheritViewBox
                                component={Icon}
                            />
                        </IconButton>
                    }
                >
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={state.functions.sort.open}
                        startIcon={
                            <SvgIcon
                                inheritViewBox
                                component={Icon}
                            />
                        }
                    >
                        Sort By
                    </Button>
                    {!isDefaultSorted && (
                        <IconButton
                            aria-label="Clear Sort"
                            size="small"
                            onClick={state.functions.sort.reset}
                        >
                            <Icons.Close />
                        </IconButton>
                    )}
                </HideOnBreakpoint>
            </Typography>
        </BoxContainer>
    )
}

export default SortButton
