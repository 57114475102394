import { type ReactElement } from 'react'

import { SliderView, ViewHeader } from 'components'
import { SkeletonSliderCardContainer } from 'components/Skeletons'
import { useRecordContext, RaResourceContextProvider, useClearResource, useResource } from 'core'
import { type WorkOrderModel } from 'resources/workOrders'
import { Section, Skeleton } from 'ui'

const skeletonSlides = new Array(4).fill(0).map((v, i) => {
    return (
        <SkeletonSliderCardContainer
            key={i}
            sx={{ gap: '10px' }}
        >
            <Skeleton
                height="8px"
                width="120px"
                sx={{
                    my: '20px',
                }}
            />
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
})
export const WorkOrderJobsFallback = () => {
    return (
        <Section>
            <ViewHeader
                title={null}
                loading
            />
            <SliderView
                height="219px"
                items={skeletonSlides}
            />
        </Section>
    )
}

const WorkOrderJobsResource = ({
    children,
    workOrder,
}: {
    children: ReactElement
    workOrder?: WorkOrderModel
}) => {
    const record = useRecordContext({ record: workOrder })
    const resource = useResource()

    const jobsResource = `${resource.resource}/${record?.id}/jobs`

    useClearResource(jobsResource)

    if (!record || !record.id) {
        return <WorkOrderJobsFallback />
    }

    return (
        <RaResourceContextProvider value={{ resource: jobsResource, name: 'work-order-jobs' }}>
            {children}
        </RaResourceContextProvider>
    )
}

export default WorkOrderJobsResource
