import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { useAskConfirm } from 'components/form/FormWarnWhenUnsavedChanges'
import { useFinalErrorHandler, useSetBlocker, api, useListContext, useNotify } from 'core'
import { hasIntegrationActiveTasks, startProviderTask } from 'resources/telematics'
import { LoadingSpinner } from 'ui'
import { isObjectEmpty } from 'utils'

import { useSyncedStateContext } from '../../SyncedStateContext'

import FooterSaveButton, { type FooterSaveButtonProps } from './FooterSaveButton'

interface ManageFooterSaveButtonProps extends Omit<FooterSaveButtonProps, 'onClick'> {
    provider: string
}
const ManageFooterSaveButton = observer(({ provider, ...props }: ManageFooterSaveButtonProps) => {
    const hasTasks = hasIntegrationActiveTasks(provider)
    const { synced, reset } = useSyncedStateContext()
    const isEmpty = isObjectEmpty(synced)
    const notify = useNotify()
    const [loading, setLoading] = useState(false)
    const errorHandler = useFinalErrorHandler()
    const { isFetching, isLoading } = useListContext()
    const [refetchCause, setRefetchCause] = useState<'sync'>(null)

    const listLoading = isFetching || isLoading

    useEffect(() => {
        if (!listLoading && refetchCause === 'sync') {
            setRefetchCause(null)
            reset()
        }
    }, [listLoading])

    const onClick = () => {
        setLoading(true)

        api.patch('telematics/' + provider, {
            vehiclesSyncStatus: synced,
        })
            .then(async () => {
                startProviderTask(provider)
                notify('Changes updated successfully')
                setRefetchCause('sync')
            })
            .catch((err) => {
                errorHandler(err, {
                    fallbackError: 'Failed to update changes',
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const askConfirm = useAskConfirm()

    useSetBlocker(
        {
            preventNavigate: (action, { resume }) => {
                askConfirm(() => {
                    resume()
                })
            },
            allowSamePageNavigate: true,
        },
        {
            isOpen: !isEmpty,
        },
    )

    if (hasTasks || loading) {
        return (
            <FooterSaveButton
                key="sync-button"
                {...props}
                disabled
                children="Sync in Progress"
                startIcon={<LoadingSpinner color="inherit" />}
            />
        )
    }

    return (
        <FooterSaveButton
            key="active-button"
            {...props}
            disabled={isEmpty}
            onClick={onClick}
        />
    )
})

export default ManageFooterSaveButton
