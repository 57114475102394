import { InfoCard, InfoCardHeader } from 'components'
import { useShowContext } from 'core'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Typography } from 'ui'

const ReasonForCancelationCard = () => {
    const { record } = useShowContext<PurchaseOrderModel>()
    if (!record || record.status !== 'CANCELED') {
        return null
    }
    return (
        <InfoCard sx={{ mb: '24px' }}>
            <InfoCardHeader title="Reason For Cancelation" />
            <Typography
                variant="body2"
                color="text.primary"
            >
                {record.cancellationReason}
            </Typography>
        </InfoCard>
    )
}

export default ReasonForCancelationCard
