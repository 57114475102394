import { type FC, useEffect, useRef, useState, type ReactNode, startTransition } from 'react'

import { type DataRecord } from 'appTypes'
import { Tooltip } from 'ui/text'
import { debounce, findLastIndex } from 'utils'

import Box from './Box'

export interface OverflowElementsProps<RecordType extends DataRecord> {
    data: RecordType[]
    renderContainer: (children: ReactNode) => JSX.Element
    renderItem: (item: RecordType) => JSX.Element
    renderOverflow: (left: number) => JSX.Element
    justify?: 'flex-end'
    tooltip?: (item: RecordType) => string
}

const OverflowElements = <RecordType extends DataRecord>({
    data,
    renderContainer,
    renderItem,
    renderOverflow,
    justify,
    tooltip,
}: OverflowElementsProps<RecordType>) => {
    const [visibleData, setVisibleData] = useState<any[]>([])
    const containerRef = useRef<HTMLDivElement>(null)
    const [domConfig, setDomConfig] = useState<DomConfig | null>(null)
    const prevData = useRef<RecordType[]>(null)

    useEffect(() => {
        if (!domConfig) {
            return
        }
        prevData.current = data
        const cb = () => {
            if (!containerRef.current) {
                return
            }

            const containerWidth = containerRef.current.clientWidth

            const last = findLastIndex(domConfig.items, (itemRight, index) => {
                if (index === data.length - 1) {
                    return itemRight < containerWidth
                }

                return itemRight < containerWidth - domConfig.overflowWidth - 4
            })
            setVisibleData(data.slice(0, (last === -1 ? 0 : last) + 1))
        }

        cb()

        const observer = new ResizeObserver(debounce(cb, 100))

        observer.observe(containerRef.current)

        return () => observer.disconnect()
    }, [domConfig])

    const left = data.length - visibleData.length

    const overflow = left && visibleData.length ? renderOverflow(left) : null

    return (
        <Box
            ref={containerRef}
            position="relative"
            display="flex"
            className="main-container-class"
            width="100%"
            justifyContent={justify}
        >
            {renderContainer(visibleData.map(renderItem))}

            {overflow && tooltip ? (
                <Tooltip
                    title={data.slice(visibleData.length).map((item) => (
                        <div key={item.id}>{tooltip(item)}</div>
                    ))}
                >
                    {overflow}
                </Tooltip>
            ) : (
                overflow
            )}

            {prevData.current !== data && (
                <DomSizes
                    data={data}
                    getConfig={setDomConfig}
                    renderContainer={renderContainer}
                    renderItem={renderItem}
                    renderOverflow={renderOverflow}
                />
            )}
        </Box>
    )
}

export default OverflowElements

interface DomSizesProps extends OverflowElementsProps<DataRecord> {
    getConfig: (params: DomConfig) => void
}

// If renderItem changes its view based on context provider, this won't work
const DomSizes: FC<DomSizesProps> = ({
    data,
    getConfig,
    renderContainer,
    renderItem,
    renderOverflow,
}) => {
    const overflowRef = useRef<HTMLDivElement>(null)
    const itemsContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const nodes = itemsContainerRef.current.querySelector('& > *').children
        const overflowElementWidth = overflowRef.current.clientWidth
        const innerContainerLeft = itemsContainerRef.current.getBoundingClientRect().left

        const getLeft = (node: Element) => node.getBoundingClientRect().left - innerContainerLeft
        startTransition(() => {
            getConfig({
                items: Array.from(nodes).map((node) => getLeft(node) + node.clientWidth),
                overflowWidth: overflowElementWidth,
            })
        })
    }, [])

    return (
        <div style={containerStyle as any}>
            <div
                style={rowStyle}
                ref={itemsContainerRef}
            >
                {renderContainer(data.map(renderItem))}
            </div>
            <div
                style={rowStyle}
                ref={overflowRef}
            >
                {renderOverflow(data.length)}
            </div>
        </div>
    )
}

interface DomConfig {
    items: number[]
    overflowWidth: number
}

const containerStyle = { position: 'absolute', left: 0, visibility: 'hidden' }
const rowStyle = { display: 'inline-block' }
