import { type FC } from 'react'

import { stringify } from 'query-string'

import Icons from 'assets/icons'
import { useColumnsContext } from 'components/Datagrid'
import { actionsField, checkboxField, columnGetMeta } from 'components/Datagrid/utils'
import { getFilterQuery, getOrderingQuery } from 'core/data'
import { useFileDownload } from 'core/files'
import { useListContext } from 'core/list'
import { Tooltip, BoxContainer, SvgIcon, IconButton } from 'ui'

export interface ExportButtonProps {
    fileName?: string
}

const ExportButton: FC<ExportButtonProps> = ({ fileName }) => {
    const listContext = useListContext()
    const fileDownload = useFileDownload()
    const { columns, pinnedColumns } = useColumnsContext()

    const downloadCSV = () => {
        const left = (pinnedColumns.left || []).map((field) =>
            columns.find((col) => col.field === field),
        )
        const right = (pinnedColumns.right || []).map((field) =>
            columns.find((col) => col.field === field),
        )
        const exportColumns = [
            ...left,
            ...columns.filter((col) => !columnGetMeta(col).pinned),
            ...right,
        ].filter((col) => {
            if (!col) {
                return false
            }

            const meta = columnGetMeta(col)
            if (meta.hidden) {
                return false
            }

            if (col.field === actionsField || col.field === checkboxField) {
                return false
            }

            return true
        })

        const resource = listContext.resource
        const query = {
            ...listContext.filter,
            ...getFilterQuery(listContext.filterValues),
            csvFields: exportColumns.flatMap((colDef) => {
                if (colDef.field === actionsField || colDef.field === checkboxField) {
                    return []
                }

                const toExport = columnGetMeta(colDef).toExport
                if (toExport?.separate) {
                    return toExport.separate.map((field) => [field.field, field.headerName])
                }

                return [[colDef.field, colDef.headerName]]
            }),
            ...getOrderingQuery(listContext.sort),
        }

        fileDownload({
            filename: fileName || listContext.resource,
            type: 'csv',
            headers: { accept: 'text/csv' },
            url: `${resource}?${stringify(query)}`,
        })
    }
    return (
        <Tooltip title="Export">
            <BoxContainer>
                <IconButton
                    aria-label="Export List"
                    size="small"
                    sx={{ color: (theme) => theme.palette.text.primary }}
                    onClick={downloadCSV}
                >
                    <SvgIcon
                        component={Icons.Export}
                        sx={{
                            width: { xs: '20px', sm: '24px' },
                            height: { xs: '20px', sm: '24px' },
                        }}
                    />
                </IconButton>
            </BoxContainer>
        </Tooltip>
    )
}

export default ExportButton
