import { type Identifier } from 'appTypes'
import { type ResourceConfig } from 'core'
import { pathJoin } from 'utils'

const resource = 'units'

const unitResource = {
    name: 'units',
    resource,
    jobsResource: (id: Identifier) => pathJoin(resource, id, 'jobs'),
    settings: (id: Identifier) => pathJoin(resource, id, 'settings'),
} satisfies ResourceConfig

export default unitResource
