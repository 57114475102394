import { type Identifier, type BaseModel } from 'appTypes'
import { type CustomerModel } from 'resources/customers'
import { type WithUnit } from 'resources/units'

export const enum AssignmentsStatusKeys {
    ONGOING = 'ONGOING',
    COMPLETED = 'COMPLETED',
}

export interface AssignmentModel extends BaseModel, WithUnit {
    created: string
    end: string
    start: string
    customer: Identifier
    customerData: CustomerModel
    status: AssignmentsStatusKeys
    accumulatedCost: number
}

export interface AssignmentAggregates {
    ongoingCount: number
    completedCount: number
}
