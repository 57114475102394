import { type Identifier } from 'appTypes'
import { type ResourceConfig } from 'core'
import { pathJoin } from 'utils'

import unitResource from '../unitResource'
const resource = 'unit-statuses'

export const unitStatusResource = {
    resource,
    name: 'unit-statuses',
    reposition: pathJoin(resource, 'reposition'),
    changeStatus: (id: Identifier) => pathJoin(unitResource.resource, id, 'status'),
} satisfies ResourceConfig
