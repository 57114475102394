import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { BooleanInput } from 'react-admin'

import { CheckboxInput, useUtilityDrawerContext } from 'components'
import { type AuthStore, type Validator } from 'core'
import { styled } from 'lib'
import { Capability } from 'resources/telematics'
import { Alert, BoxContainer, SectionTitleSmall, Spacer, Typography, Img, StyledElement } from 'ui'

import IntegrationsUnitListDialog from '../IntegrationsUnitList/IntegrationsUnitListDialog'

import { type IntegrationDrawerTogglerExtraProps } from './types'

const unitsOwnershipAlertValidation: Validator[] = [
    (value) =>
        value ? undefined : 'To proceed with the import, please acknowledge by checking the box.',
]

const IntegrationActivateForm: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { extra }: { extra: IntegrationDrawerTogglerExtraProps } = useUtilityDrawerContext()
        const integration = extra.integration

        return (
            <>
                <BoxContainer justifyContent="space-between">
                    <Spacer>
                        <Img
                            src={integration.logo}
                            sx={{
                                width: '24px',
                                height: '24px',
                                maxWidth: '100%',
                            }}
                        />
                        <Typography variant="subtitle1">{integration.providerName}</Typography>
                    </Spacer>

                    {!integration.isActive && (
                        <IntegrationsUnitListDialog integration={integration} />
                    )}
                </BoxContainer>
                <SectionTitleSmall
                    mt="24px"
                    variant="subtitle1"
                >
                    Select info you want to sync
                </SectionTitleSmall>

                {integration.capabilities.meters === Capability.SUPPORTED && (
                    <BooleanInput
                        source="syncMeters"
                        helperText={false}
                        label="Meters"
                    />
                )}

                {integration.capabilities.dvirs === Capability.SUPPORTED && (
                    <BooleanInput
                        source="syncDvirs"
                        helperText={false}
                        label="DVIR"
                    />
                )}

                <BooleanInput
                    source="autosaveNewVehicles"
                    helperText={false}
                    label="Automatically connect new incoming Units"
                />

                {/* No id means the user is not yet created */}
                {integration.createsTelematicsUser &&
                    !integration.fleetpalUserData.id &&
                    integration.isActive === false && (
                        <Alert
                            severity="info"
                            sx={{ mt: '18px' }}
                        >
                            <Typography
                                color="text.primary"
                                variant="body2"
                            >
                                A new user with the email{' '}
                                <BoldSpan>{integration.fleetpalUserData.email}</BoldSpan> will be
                                created in{' '}
                                <StyledElement as="span">{integration.providerName}</StyledElement>.
                            </Typography>
                        </Alert>
                    )}
                {auth.companySettings.hasCustomers && integration.isActive === false && (
                    <CheckboxInput
                        sx={{ mt: '20px' }}
                        disableLabelClick
                        source="unitsOwnershipAlert"
                        validate={unitsOwnershipAlertValidation}
                        label={`Units imported from ${integration.providerName} will be automatically assigned to ${auth.currentCompany.name}.`}
                    />
                )}
            </>
        )
    }),
)

export default IntegrationActivateForm

const BoldSpan = styled('span')`
    font-weight: 500;
    white-space: nowrap;
`
