import { type Identifier } from 'appTypes'
import { api, type ResourceType, type DeleteOneParams } from 'core'
import { type LineItemTypeKeys } from 'resources/lineItems'
import { pathJoin } from 'utils'

import { invoicesResource } from '../utils'

export const deleteInvoiceItemActionParams: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete this item?',
    },
}

export const getSuggestedPrice = (
    id: Identifier,
    data: { type: LineItemTypeKeys; part?: Identifier },
): Promise<{ sellingPrice: number }> => {
    const resource = invoicesResource.sellingPrice(id)
    const response = api.post(resource, data)

    return response
}

export const getInvoiceItemResource: (id: Identifier, action?: string) => ResourceType = (
    id,
    action,
) => ({
    name: 'invoice-items',
    resource: pathJoin(invoicesResource.resource, id, 'items', action),
})
