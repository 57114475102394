import { forwardRef, type ReactElement } from 'react'

import { useModifiers } from 'context'
import { alpha, styled, generateNotForwardedProps, themeColor, type ThemeColorType } from 'lib'

import Avatar, { type AvatarProps } from './Avatar'

export interface DataAvatarProps extends Omit<AvatarProps, 'color'> {
    color?: ThemeColorType
    defaultImage?: ReactElement | string
    imageSrc?: string
    avatarOpacity?: number
    className?: string
    size?: number
}

const DataAvatar = forwardRef<any, DataAvatarProps>((props, ref) => {
    const {
        imageSrc,
        defaultImage = null,
        color,
        ...rest
    } = useModifiers<DataAvatarModifiers>()('dataAvatar', props)

    return (
        <Avatar
            ref={ref}
            src={imageSrc}
            alt="Avatar"
            {...rest}
        >
            {imageSrc ? null : defaultImage}
        </Avatar>
    )
})

const shouldForwardProp = generateNotForwardedProps<DataAvatarProps>(['avatarOpacity'])

export default styled(DataAvatar, {
    shouldForwardProp,
})`
    ${({ imageSrc, color, avatarOpacity = 0.08, theme, size }) =>
        imageSrc
            ? ''
            : `
        border-radius: 50%;
        background: ${alpha(themeColor(color || theme.palette.primary.main, theme), avatarOpacity)};
        display: flex;
        justify-content: center;
        overflow: visible;
        align-items: center;
        & svg {
            color: ${themeColor(color || theme.palette.primary.main, theme)};
        }
            
        ${size ? `width: ${size}px; height: ${size}px; font-size: ${size * 0.6}px;` : ''}
`}
`

export interface DataAvatarModifiers {
    dataAvatar: Partial<DataAvatarProps>
}
