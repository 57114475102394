import { type ReactElement } from 'react'

import { useRecordContext, useClearResource, RaResourceContextProvider, useResource } from 'core'
import { useDataProviderConfig } from 'core/data'
import { pathJoin } from 'utils'

export const jobsItemsShortResourceName = 'work-order-job-items'

const WorkOrderJobItemsResource = ({ children }: { children: ReactElement }) => {
    const { id } = useRecordContext() || {}
    const resource = useResource()

    const jobItemsResource = `${resource.resource}/${id}/items`

    useDataProviderConfig(jobItemsResource, {
        create: {
            prepareResource: (resource, { data }) => {
                return pathJoin(resource, `add_${(data.type as string).toLocaleLowerCase()}`)
            },
        },
    })

    useClearResource(jobItemsResource)

    if (!id) {
        return null
    }

    return (
        <RaResourceContextProvider
            value={{
                resource: jobItemsResource,
                name: 'work-order-job-items',
            }}
        >
            {children}
        </RaResourceContextProvider>
    )
}

export default WorkOrderJobItemsResource
