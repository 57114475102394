import { Show, tabRoutes } from 'components'
import { createModifiers, ModifierProvider } from 'context'
import { Routes } from 'lib'
import { type ContactDrawerModifications, ContactsListPage } from 'resources/contacts'
import { type VendorModel } from 'resources/vendors'

import VendorOverview from '../Overview'
import { vendorContactsResourceName } from '../constants/config'

import { VendorShowHeader } from './components'

const VendorShow = () => {
    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <VendorOverview />,
        },
        {
            value: 'contact-persons',
            label: 'Contact Persons',
            element: (
                <ContactsListPage
                    exportName={(record: VendorModel) => record.name}
                    resourceName={vendorContactsResourceName}
                />
            ),
        },
    ])
    return (
        <Show>
            <ModifierProvider value={modifications}>
                <VendorShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
            </ModifierProvider>
        </Show>
    )
}

export default VendorShow

const modifications = createModifiers<ContactDrawerModifications>({
    contactForm: {
        alertText: 'Purchase Orders can be sent only to contacts with an email address.',
    },
})
