import { type InPageTabProps, InPageTabs } from 'components'
import { urls } from 'configs'
import { useRecordContext } from 'core'
import { useParams } from 'lib'
import { PaymentsList, PaymentsPage } from 'resources/payments'
import { poResource } from 'resources/purchaseOrders'
import { type WorkOrderModel } from 'resources/workOrders'
import { pathJoin } from 'utils'

import { Invoices, Overview } from './tabs'

const WoPo = () => {
    const record = useRecordContext()

    return (
        <>
            <InPageTabs
                tabs={tabs}
                tabMinWidth="110px"
                basePath={pathJoin(urls.workOrders, record?.id, 'purchase-order')}
            />
            <Pages />
        </>
    )
}

const Pages = () => {
    const record = useRecordContext<WorkOrderModel>()

    const poId = record?.purchaseOrderData.id

    const invoiceResource = poResource.invoices(poId)
    const paymentResource = poResource.payments(poId)

    const { page } = useParams()

    if (!page) {
        return <Overview />
    }

    if (page === 'invoices') {
        return <Invoices />
    }

    if (page === 'payments') {
        return (
            <PaymentsList resource={paymentResource}>
                <PaymentsPage
                    readOnly={!record?.unitData.isAccessible}
                    invoicesUrl={invoiceResource}
                    createDefaultValues={{
                        method: record.purchaseOrderData.vendorData.paymentMethod,
                    }}
                />
            </PaymentsList>
        )
    }

    return null
}

export default WoPo

const tabs: InPageTabProps[] = [
    {
        label: 'Overview',
        value: '',
    },
    {
        label: 'Invoices',
        value: 'invoices',
    },
    {
        label: 'Payments',
        value: 'payments',
    },
]
