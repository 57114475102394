import { type NoInfer } from 'appTypes'
import { type FilterBy, type SortBy, type Column, type DataListDetails } from 'components'

import { type UnitModel } from '../types'

const source = 'name' satisfies keyof UnitModel

const label = 'Name'

const longLabel = 'Unit Name'

const tableColumn = <Source extends string = typeof source>({
    id,
    headerName,
    dataToValue,
}: {
    id?: Source
    headerName?: string
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: headerName || label,
        renderCell: ({ row }) => dataToValue(row),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    headerName,
    dataToValue,
}: {
    id?: Source
    headerName?: string
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label: headerName || label,
        render: (_, data) => dataToValue(data),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>({ label: labelProp }: { label?: string }) =>
    ({
        id: source as NoInfer<Source>,
        label: labelProp || label,
    }) as const satisfies SortBy

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: {
    id?: Source
    label?: string
}) =>
    ({
        id: id || source,
        label: labelProp || label,
    }) as const satisfies FilterBy

export const name = {
    longLabel,
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}
