import { type StringKeys, type DataRecord, type NoInfer } from 'appTypes'
import {
    type FilterBy,
    FilterValueInput,
    type Column,
    type DataListDetails,
    type SortBy,
} from 'components'

import { type CK33Model } from '../types'
import { ck33Resource, type CK33Levels } from '../utils'

const formatCK33CodeByLevel = (code: string, level: number) => {
    if (level === 0) {
        // the middle symbol in the first part is the group code. E.g. 010-000-000
        return code[1]
    }
    return code
        .split('-')
        .filter((_, i) => i < level)
        .join('-')
}

const displayCK33 = (record: CK33Model) => {
    if (!record) {
        return ''
    }
    return formatCK33CodeByLevel(record.code, record.level) + ' ' + record.text
}

interface CK33FilterParams<RecordType extends DataRecord> {
    label?: string
    makeItemLabel?: (record: CK33Model) => string
    id?: StringKeys<RecordType>
}

const filter = <RecordType extends DataRecord>({
    id,
    label,
    makeItemLabel = displayCK33,
}: CK33FilterParams<RecordType>) =>
    ({
        id,
        label: label || 'Component',
        renderComponent: (props) => (
            <FilterValueInput
                {...props}
                makeItemLabel={makeItemLabel}
                itemReference={ck33Resource.resource}
            />
        ),
    }) as const satisfies FilterBy

const source = 'component' as const

const dataCardRow = <Source extends string = 'component'>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CK33Model
    maxLevel?: CK33Levels
}) =>
    ({
        source: (id || 'component') as NoInfer<Source>,
        label: 'Component',
        render: (_, data) => displayCK33(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const gridColumn = <Source extends string = 'component'>({
    id,
    dataToRecord,
}: {
    id?: Source
    maxLevel?: CK33Levels
    dataToRecord: (data: DataRecord) => CK33Model
}) =>
    ({
        field: (id || 'component') as NoInfer<Source>,
        headerName: 'Component',
        renderCell: ({ row }) => displayCK33(dataToRecord(row)),
    }) as const satisfies Column

const sort = <Source extends string = 'component'>({
    id,
}: { id?: Source; maxLevel?: CK33Levels } = {}) =>
    ({
        id: (id || 'component') as NoInfer<Source>,
        label: 'Component',
    }) as const satisfies SortBy

const self = {
    source,
    sort,
    gridColumn,
    dataCardRow,
    filter,
    value: displayCK33,
    formatCode: formatCK33CodeByLevel,
}

export default self
