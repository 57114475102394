import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import images from 'assets/images'
import {
    type DatagridColumnsProps,
    ListAvatar,
    type CardListConfig,
    datagridAvatarColumn,
    type ListBulkActions,
    ListController,
    ListUi,
    type ListControllerProps,
} from 'components'
import {
    type Action,
    type AuthStore,
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    multiselectAction,
} from 'core'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import {
    InvoiceDrawerToggler,
    type InvoiceModel,
    InvoiceStatusKeys,
    deleteInvoiceTitle,
    downloadInvoicePdfAction,
    invoiceFields,
} from 'resources/invoices'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import { woFields } from 'resources/workOrders'
import { LinkButton, PageContent } from 'ui'

import { SendInvoiceAction } from '../Show/components/actions'

import { InvoicesListHeader } from './components'

const InvoicesList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { columnsConfig, cardsConfig, filters, sorts } = getConfig(auth)

        return (
            <ListController
                filters={filters}
                sorts={sorts}
            >
                <InvoicesListHeader />
                <PageContent>
                    <ListUi
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        bulkActions={bulkActions}
                        listFTUProps={{
                            imageSrc: images.noPayments,
                            title: 'No Invoices',
                            linkText: (
                                <InvoiceDrawerToggler>
                                    {(open) => (
                                        <LinkButton onClick={open}>
                                            Create Direct Invoice
                                        </LinkButton>
                                    )}
                                </InvoiceDrawerToggler>
                            ),
                            linkAction: (e) => {
                                e.preventDefault()
                            },
                            secondaryTitle: 'Would you like to create one?',
                        }}
                    />
                </PageContent>
            </ListController>
        )
    }),
)

export default InvoicesList

const actions: Action<InvoiceModel> = (record, { children }) => {
    return [
        editRedirectInListAction({ children, id: record.id }),
        downloadInvoicePdfAction(record, children),
        <SendInvoiceAction
            key="sendInvoice"
            record={record}
            children={children}
            title="Send"
            disabled={record.status === InvoiceStatusKeys.CANCELED}
        />,
        deleteOneAction({
            children,
            id: record.id,
            disabled: record.status !== InvoiceStatusKeys.OPEN,
            confirmConfig: {
                title: deleteInvoiceTitle(true),
            },
        }),
    ]
}

const getConfig = (auth: AuthStore) => {
    const cardsConfig: CardListConfig<InvoiceModel> = {
        titleSource: invoiceFields.number.source,
        subTitleSource: (record) => invoiceFields.status.value(record),
        defaultImage: (record) => <invoiceFields.avatar.Icon record={record} />,
        details: [
            shopFields.self.dataCardRow({
                dataToRecord: (record: InvoiceModel) => record.shopData,
                withShopContext: auth,
            }),
            customerFields.self.dataCardRow({
                dataToRecord: (record: InvoiceModel) => record.customerData,
            }),
            unitFields.self.dataCardRow({
                dataToRecord: (record) => record.unitData,
                withLink: true,
            }),
            woFields.self.dataCardRow({
                withLink: true,
                dataToRecord: (record: InvoiceModel) => record.workOrderData,
            }),
            invoiceFields.poNumber.dataCardRow(),
            invoiceFields.invoiceDate.dataCardRow(),
            invoiceFields.closedDate.dataCardRow(),
            invoiceFields.total.dataCardRow(),
            invoiceFields.balanceDue.dataCardRow(),
        ],
        actions: (record, params) => {
            const [firstAction, ...rest] = actions(record, params)
            return [
                firstAction,
                multiselectAction({ children: params.children, id: record.id }),
                ...rest,
            ]
        },
    }

    const columnsConfig: DatagridColumnsProps<InvoiceModel & { workOrderCompleted: string }> = {
        mainField: invoiceFields.number.source,
        avatarSource: invoiceFields.avatar.source,
        columns: [
            datagridAvatarColumn({
                field: invoiceFields.avatar.source,
                headerName: invoiceFields.avatar.label,
                avatar: (record) => (
                    <ListAvatar
                        id={record.id}
                        defaultImage={<invoiceFields.avatar.Icon record={record} />}
                    />
                ),
            }),
            invoiceFields.number.tableColumn(),
            shopFields.self.tableColumn({
                dataToRecord: (record: InvoiceModel) => record.shopData,
                withShopContext: auth,
            }),
            invoiceFields.type.tableColumn(),
            customerFields.self.tableColumn({
                dataToRecord: (record: InvoiceModel) => record.customerData,
            }),
            unitFields.self.tableColumn({
                id: 'unitData',
                withLink: true,
                dataToRecord: (record) => record.unitData,
            }),
            woFields.self.tableColumn({
                withLink: true,
                dataToRecord: (record: InvoiceModel) => record.workOrderData,
            }),
            invoiceFields.woCompletedDate.tableColumn(),
            invoiceFields.poNumber.tableColumn(),
            invoiceFields.status.tableColumn(),
            createdField.tableColumn({
                dataToValue: (record) => record.created,
            }),
            invoiceFields.invoiceDate.tableColumn(),
            invoiceFields.dueDate.tableColumn(),
            invoiceFields.closedDate.tableColumn(),
            invoiceFields.total.tableColumn(),
            invoiceFields.paid.tableColumn(),
            invoiceFields.balanceDue.tableColumn(),
        ],
        actions: ({ row }, args) => actions(row, args),
        actionsWidth: 156,
    }

    const filters: ListControllerProps<InvoiceModel & { workOrderCompleted: string }>['filters'] = [
        shopFields.self.filter({ withShopContext: auth }),
        invoiceFields.type.filter(),
        customerFields.self.filter(),
        unitFields.self.filter(),
        woFields.self.filter(),
        invoiceFields.woCompletedDate.filter(),
        invoiceFields.status.filter(),
        createdField.filter(),
        invoiceFields.invoiceDate.filter(),
        invoiceFields.closedDate.filter(),
        invoiceFields.total.filter(),
    ]

    const sorts: ListControllerProps<InvoiceModel & { workOrderCompleted: string }>['sorts'] = [
        invoiceFields.number.sort(),
        shopFields.self.sort({ withShopContext: auth }),
        invoiceFields.type.sort(),
        customerFields.self.sort(),
        unitFields.self.sort(),
        woFields.self.sort(),
        invoiceFields.woCompletedDate.sort(),
        invoiceFields.poNumber.sort(),
        invoiceFields.status.sort(),
        createdField.sort(),
        invoiceFields.invoiceDate.sort(),
        invoiceFields.closedDate.sort(),
        invoiceFields.total.sort(),
        invoiceFields.paid.sort(),
        invoiceFields.balanceDue.sort(),
    ]

    return {
        sorts,
        filters,
        columnsConfig,
        cardsConfig,
    }
}

const bulkActions: ListBulkActions = ({ children }) => {
    return [
        deleteManyFromListAction({
            children,
            confirmConfig: {
                title: deleteInvoiceTitle(true),
            },
        }),
    ]
}
