import { type NoInfer } from 'appTypes'
import { type FilterBy, type SortBy, type Column, type DataListDetails } from 'components'

const source = 'zipCode' as const
const label = 'ZIP/Postal Code'

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies DataListDetails<any, any>

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies FilterBy

const zipCode = {
    source,
    label,
    sort,
    tableColumn,
    dataCardRow,
    filter,
}

export default zipCode
