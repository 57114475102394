import { useRef, useState } from 'react'

import { type ChoicesContextValue, useChoicesContext } from 'react-admin'

import { useDataProvider } from 'core/data'
import { useQueryClient } from 'lib'
import { getId } from 'utils'

import { useReferenceInputMetaContext } from './ReferenceInputMetaContext'
import { calcReferenceInputTotal } from './referenceInputTotal'

const sanitizeData = (data: any[]) => {
    const sanitizedData = Array.from(new Set(data.map(getId))) // 3
        .map((id) => data.reverse().find((a) => a.id === id))
    return sanitizedData
}

type LoadingSource = 'fetchMore' | 'reset' | false

interface UseReferenceInputInfiniteScrollProps {
    choicesContext?: Parameters<typeof useChoicesContext>[0]
    multiple?: boolean
    meta?: any
}

const useReferenceInputInfiniteScroll = ({
    choicesContext,
    multiple,
    meta,
}: UseReferenceInputInfiniteScrollProps = {}) => {
    const { getList } = useDataProvider()
    const queryClient = useQueryClient()

    const infoRef = useRef({
        loading: false as LoadingSource,
        page: 1,
    })

    const choicesController = useRef<ChoicesContextValue>({} as ChoicesContextValue) // ref fixes reset inside effects
    choicesController.current = useChoicesContext(choicesContext)
    const referenceInputExtraData = useReferenceInputMetaContext()
    const [, rerender] = useState(false)

    const setCache = (data: any[], options: { reset?: boolean } = {}) => {
        const { resource, sort, perPage, filter } = choicesController.current

        const cacheParams: any = { filter, pagination: { page: 1, perPage }, sort }
        const _meta = meta || referenceInputExtraData?.meta
        if (_meta) {
            cacheParams.meta = _meta
        }

        queryClient.setQueryData([resource, 'getList', cacheParams], (oldData: any) => {
            const sanitizedData =
                options.reset || !Array.isArray(oldData?.data)
                    ? data
                    : sanitizeData([...oldData.data, ...data])
            return {
                ...oldData,
                data: sanitizedData,
            }
        })
    }

    const loadData = (page: number, loadingSource: LoadingSource) => {
        const { resource, sort, perPage, filter } = choicesController.current

        infoRef.current.loading = loadingSource
        infoRef.current.page = page
        rerender((prev) => !prev)

        getList(resource, {
            pagination: { page, perPage },
            sort,
            filter,
            meta,
        }).then(({ data }) => {
            const resetCache = page === 1 || false
            setCache(data, { reset: resetCache })

            infoRef.current.loading = false
            rerender((prev) => !prev)
        })
    }

    const loadMoreResults = () => {
        // RA returns the total directly from the response on multichoice and mutates the one from single choice
        // this is why we have to recalculate the total on single choice
        const total = multiple
            ? choicesController.current.total
            : calcReferenceInputTotal(choicesController.current)
        const { availableChoices } = choicesController.current

        if (infoRef.current.loading || availableChoices.length >= total) {
            return
        }

        loadData(-availableChoices.length, 'fetchMore')
    }

    const reset = () => {
        // autocomplete executes onClose twice. We need to reset only once
        // this can't be done with state, because it happes before the next render
        // TODO: if there are modifications in the list, while on page 1, the list should reset
        if (infoRef.current.loading === 'reset' || infoRef.current.page === 1) {
            return
        }
        loadData(1, 'reset')
    }

    return {
        loadMoreResults,
        reset,
        choicesController: choicesController.current,
        loading: Boolean(infoRef.current.loading),
    }
}

export default useReferenceInputInfiniteScroll
