import { type FC } from 'react'

import { useRecordContext } from 'core'
import { type InvoiceModel } from 'resources/invoices'
import { GridContainerColumns, GridItemLayout, SmallSection } from 'ui'

import TotalCard from './TotalCard'

const TotalSection: FC = () => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return null
    }

    return (
        <SmallSection>
            <GridContainerColumns>
                <GridItemLayout>
                    <TotalCard />
                </GridItemLayout>
            </GridContainerColumns>
        </SmallSection>
    )
}

export default TotalSection
