import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { BoxContainer } from 'ui'

import { type UnitStatusModel } from '../types'

import BaseStatusContent from './BaseStatusContent'
import StatusMenu from './StatusMenu'

interface StatusFieldProps {
    unitId?: Identifier
    record: UnitStatusModel
}

const StatusField: FC<StatusFieldProps> = ({ unitId, record }) => {
    if (!record) {
        return null
    }

    if (unitId) {
        return (
            <StatusMenu
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                selectedStatus={record}
                id={unitId}
                renderToggler={(open, isOpen) => (
                    <BoxContainer
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => {
                            open(event)
                        }}
                        role="button"
                        gap="4px"
                    >
                        <BaseStatusContent color={record.color}>{record.name}</BaseStatusContent>
                        {isOpen ? (
                            <Icons.KeyboardArrowUpOutlined />
                        ) : (
                            <Icons.KeyboardArrowDownOutlined />
                        )}
                    </BoxContainer>
                )}
            />
        )
    }

    return <BaseStatusContent color={record.color}>{record.name}</BaseStatusContent>
}

export default StatusField
