import { type NoInfer } from 'appTypes'
import {
    type FilterBy,
    FilterRangeInput,
    type SortBy,
    costMaskParams,
    type Column,
} from 'components'
import { formatDuration } from 'lib'

import { type WorkOrderModel } from '../types'

const source = 'duration' as keyof WorkOrderModel

const label = 'WO Duration'

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => formatDuration(row.durationSeconds, true),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <FilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies FilterBy

export const duration = {
    source,
    label,
    tableColumn,
    sort,
    filter,
}
