import { type NoInfer } from 'appTypes'
import { type FilterBy, FilterDateRangeValue, type SortBy, type Column } from 'components'
import { formatDate } from 'lib'

import { type UnitModel } from '../types'

const source = 'created' satisfies keyof UnitModel

const label = 'Created on'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) =>
            formatDate(dataToValue(row), (dateFormats) => dateFormats.shortenedDateTime),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: id || source,
        label,
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    }) as const satisfies FilterBy

export const created = {
    source,
    label,
    tableColumn,
    sort,
    filter,
}
