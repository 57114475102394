import { type FC, type ReactElement } from 'react'

import Icons from 'assets/icons'
import { useRecordContext } from 'core'
import { SendToContactDrawer } from 'resources/contacts'
import { vendorsResource } from 'resources/vendors'
import { IconBox } from 'ui'

import { type PurchaseOrderModel } from '../types'
import { poResource } from '../utils'

export const PoSendActionIconButton: FC<{ po?: Partial<PurchaseOrderModel> }> = ({ po }) => {
    return (
        <PoSendAction po={po}>
            {(open) => (
                <IconBox
                    title="Send PO"
                    onClick={open}
                >
                    <Icons.SendOutlined />
                </IconBox>
            )}
        </PoSendAction>
    )
}

interface SendPoTogglerProps {
    children: (open: () => void) => ReactElement
    po?: Partial<PurchaseOrderModel>
}

export const PoSendAction = ({ children, po }: SendPoTogglerProps) => {
    const poFromContext = useRecordContext<PurchaseOrderModel>()
    const finalPurchaseOrder = po || poFromContext

    return (
        <SendToContactDrawer
            title="Send PO"
            resource={poResource.resource}
            record={finalPurchaseOrder as PurchaseOrderModel}
            sendToFormConfig={{
                resource: vendorsResource.resource,
                resourceId: finalPurchaseOrder.vendorData?.id,
                alertText: 'Purchase Orders can be sent only to contacts with an email address.',
            }}
        >
            {children}
        </SendToContactDrawer>
    )
}
