import { observer } from 'mobx-react'
import { type Identifier, useCreatePath } from 'react-admin'

import {
    CardCollapsibleContent,
    type CardListConfig,
    type DatagridColumnsProps,
    ListAvatar,
    type ListBulkActions,
    ListController,
    type ListControllerProps,
    ListUi,
} from 'components'
import { datagridAvatarColumn, DatagridLink } from 'components/Datagrid'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect } from 'core'
import {
    EditRedirectInListAction,
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    multiselectAction,
} from 'core/actions'
import { createdField } from 'resources/base'
import { NotesCollapsibleContent, NotesCollapsibleIconHolder } from 'resources/notes'
import {
    type PartModel,
    partFields,
    partsResource,
    type VendorPartModel,
    VendorPartDrawerToggler,
    vendorPartsResource,
} from 'resources/parts'
import { TagsCollapsibleContent, TagsCollapsibleIconHolder, tagFields } from 'resources/tags'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { PageContent, Typography } from 'ui'

import { deleteVendorPartAction } from '../config/constants'

import VendorPartsListHeader from './components/VendorPartsListHeader'

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Vendor Parts?',
        },
    }),
]

const VendorPartsList = observer(() => {
    const createPath = useCreatePath()
    const navigateTo = (id: Identifier) =>
        createPath({
            resource: urls.vendorParts,
            id,
            type: 'edit',
        })

    const cardConfig: CardListConfig<PartModel> = {
        titleSource: 'number',
        subheaderTypographyProps: {
            variant: 'chartTitle',
        },
        subTitleSource: 'description',
        imageSource: 'image',
        details: [
            ck33Fields.self.dataCardRow({
                dataToRecord: (data: VendorPartModel) => data.componentData,
            }),
            ck34Fields.self.cardRow({
                dataToRecord: (data: VendorPartModel) => data.manufacturerData,
            }),
            uomFields.self.dataCardRow({
                dataToRecord: (data: VendorPartModel) => data.unitOfMeasureData,
            }),
            partFields.cost.dataCardRow({
                dataToValue: (data: VendorPartModel) => data.cost,
            }),
        ],
        titleLink: (record) => navigateTo(record.id),
        defaultImage: <partFields.avatar.Vendor />,
        collapsibleContent: (record) => (
            <CardCollapsibleContent
                content={[
                    {
                        iconHolder: (
                            <TagsCollapsibleIconHolder
                                tags={record.tagsData}
                                key="tagsIcon"
                            />
                        ),
                        component: (
                            <TagsCollapsibleContent
                                tags={record.tagsData}
                                key="tagsContent"
                            />
                        ),
                    },
                    {
                        iconHolder: (
                            <NotesCollapsibleIconHolder
                                notes={record.notes}
                                key="notesIcon"
                            />
                        ),
                        component: (
                            <NotesCollapsibleContent
                                notes={record.notes}
                                key="notesContent"
                            />
                        ),
                    },
                ]}
            />
        ),
        actions: (record, { children }) => [
            editRedirectInListAction({
                children,
                id: record.id,
                resource: vendorPartsResource.resource,
            }),

            multiselectAction({
                children,
                id: record.id,
            }),
            deleteOneAction({
                children: (params) =>
                    children({
                        ...params,
                    }),
                id: record.id,
                ...deleteVendorPartAction,
            }),
        ],
    }

    const columnsConfig: DatagridColumnsProps<PartModel> = {
        resetColumns: {
            positionApplicable: false,
            serializedPart: false,
            tagsData: false,
        },
        mainField: 'number',
        avatarSource: 'image',
        columns: [
            datagridAvatarColumn({
                field: 'image',
                headerName: 'Picture',
                avatar: (record) => (
                    <ListAvatar
                        id={record.id}
                        imageSrc={record.image}
                        color={({ palette }) => palette.primary.main}
                        defaultImage={<partFields.avatar.Vendor />}
                        resource={urls.vendorParts}
                    />
                ),
            }),
            {
                field: 'number',
                headerName: 'Part Number',
                renderCell: (params) => (
                    <DatagridLink
                        {...params}
                        resource={urls.vendorParts}
                    />
                ),
            },
            partFields.description.tableColumn({
                dataToValue: (data: VendorPartModel) => data.description,
            }),
            ck33Fields.self.gridColumn({
                dataToRecord: (data: VendorPartModel) => data.componentData,
            }),
            ck34Fields.self.column({
                dataToRecord: (data: VendorPartModel) => data.manufacturerData,
            }),
            partFields.manufacturerPartNumber.tableColumn({
                dataToValue: (data: VendorPartModel) => data.manufacturerPartNumber,
            }),
            partFields.positionApplicable.tableColumn({
                dataToValue: (data: VendorPartModel) => data.positionApplicable,
            }),
            partFields.serializedPart.tableColumn({
                dataToValue: (data: VendorPartModel) => data.serializedPart,
            }),
            tagFields.self.column({
                dataToRecord: (data: VendorPartModel) => data.tagsData,
            }),
            createdField.tableColumn({
                dataToValue: (data: VendorPartModel) => data.created,
            }),
            uomFields.self.tableColumn({
                dataToRecord: (data: VendorPartModel) => data.unitOfMeasureData,
            }),
            partFields.cost.tableColumn({
                dataToValue: (data: VendorPartModel) => data.cost,
            }),
        ],
        actions: ({ row: vendorPart }, { children }) => [
            <EditRedirectInListAction
                id={vendorPart.id}
                children={children}
                key="show"
                resource={vendorPartsResource.resource}
            />,
            deleteOneAction({
                children: (params) =>
                    children({
                        ...params,
                    }),
                id: vendorPart.id,
                ...deleteVendorPartAction,
            }),
        ],
    }

    const sorts: ListControllerProps<VendorPartModel>['sorts'] = [
        createdField.sort(),
        partFields.number.sort(),
        partFields.description.sort(),
        ck33Fields.self.sort(),
        ck34Fields.self.sort(),
        uomFields.self.sort(),
        partFields.manufacturerPartNumber.sort(),
        partFields.positionApplicable.sort(),
        partFields.serializedPart.sort(),
        partFields.cost.sort(),
    ]

    const filters: ListControllerProps<VendorPartModel>['filters'] = [
        createdField.filter(),
        partFields.number.filter(),
        ck34Fields.self.filter(),
        partFields.cost.filter(),
        uomFields.self.filter(),
        tagFields.self.filter(),
        partFields.positionApplicable.filter(),
        partFields.serializedPart.filter(),
        ck33Fields.self.filter<VendorPartModel>({ id: 'component' }),
    ]

    return (
        <ResourceContextProviderWithClearEffect value={partsResource}>
            <ListController
                filter={{
                    type: 'VENDOR',
                }}
                sorts={sorts}
                filters={filters}
                preferencesName={vendorPartsResource.name}
            >
                <VendorPartsListHeader />
                <PageContent>
                    <ListUi
                        exportFileName="vendor-parts"
                        bulkActions={bulkActions}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardConfig}
                        listFTUProps={{
                            secondaryTitle: 'Would you like to create one?',
                            linkText: (
                                <VendorPartDrawerToggler>
                                    {({ onClick }) => (
                                        <Typography
                                            variant="body1"
                                            onClick={onClick}
                                            color={(theme) => theme.palette.primary.main}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            Create Vendor Part{' '}
                                        </Typography>
                                    )}
                                </VendorPartDrawerToggler>
                            ),
                            linkAction: (e) => {
                                e.preventDefault()
                            },
                        }}
                    />
                </PageContent>
            </ListController>
        </ResourceContextProviderWithClearEffect>
    )
})

export default VendorPartsList
