import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { useListContext, type Serializer, useResource } from 'core'

import { deletePaymentAction } from '../actions'
import { type PaymentModel } from '../types'

import PaymentForm from './PaymentForm'
import { type PaymentFormDefaultValues } from './types'

export interface PaymentDrawerTogglerProps {
    id?: Identifier
    children: (open: () => void) => ReactElement
    readOnly?: boolean
    defaultValues?: PaymentFormDefaultValues
    refund?: boolean
    invoicesUrl?: string
}

const PaymentDrawerToggler: FC<PaymentDrawerTogglerProps> = ({
    children,
    id,
    readOnly,
    defaultValues,
    refund,
    invoicesUrl,
}) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()

    const listContext = useListContext()

    return children(() => {
        const title = refund ? 'Refund' : 'Payment'

        const paymentSerializer: Serializer<PaymentModel> = [
            'id',
            { name: 'amount', parse: 'number' },
            { name: 'date', parse: 'date' },
            {
                name: 'method',
                parse: 'emptyToNull',
            },
            'number',
            'notes',
        ]

        if (invoicesUrl) {
            paymentSerializer.push({ name: 'invoices', parse: 'emptyToNull' })
        }

        open({
            extraArgs: {
                id,
                resource,
                listContext,
            },
            drawerArgs: {
                title: `${id ? (readOnly ? 'View' : 'Edit') : 'Add'} ${title}`,
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={paymentSerializer}
                        defaultValues={defaultValues}
                    />
                ),
                renderContent: () => (
                    <PaymentForm
                        refund={refund}
                        disabledFields={readOnly ? () => true : undefined}
                        invoicesUrl={invoicesUrl}
                    />
                ),
                renderBottomRight: readOnly
                    ? () => null
                    : (render) => render({ label: id ? undefined : `Add ${title}` }),
                renderTopRight:
                    id && !readOnly ? (render) => render(deletePaymentAction(refund)) : null,
                renderBottomLeft: readOnly ? (render) => render({ children: 'Close' }) : undefined,
            },
        })
    })
}

export default PaymentDrawerToggler
