import Icons from 'assets/icons'
import { type ListBulkActions } from 'components'
import {
    type Action,
    deleteOneAction,
    deleteManyFromListAction,
    multiselectAction,
} from 'core/actions'
import { type MeterModel, deleteMeterActionParams } from 'resources/units'

import { EditMeterDrawerToggler } from './components/UnitMeterDrawerToggler'
import { translateMeterSource } from './constants'

export type MeterActions = Action<MeterModel>

export const unitMeterActions: MeterActions = (meter, { children }) => {
    return [
        <EditMeterDrawerToggler
            key="edit"
            meter={meter}
            children={(params) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    ...params,
                })
            }
        />,
        deleteOneAction({
            disabled: meter.source === 'WORK_ORDER',
            children: (params) =>
                children({
                    ...params,
                    titleOnDisabled:
                        'This meter reading originates from a Work Order and can be updated through it',
                }),
            id: meter.id,
            ...deleteMeterActionParams,
        }),
    ]
}

export const disableMeterSelection = ({ source, isFromTelematics }: MeterModel) =>
    source !== 'MANUAL' && !isFromTelematics
        ? `This meter reading originates from a ${translateMeterSource(source)} and can be updated through it.`
        : null

export const unitMeterExtendedActions: MeterActions = (meter, args) => [
    ...unitMeterActions(meter, args),
    multiselectAction({
        disabled: Boolean(disableMeterSelection(meter)),
        children: (params) => args.children(params),
        id: meter.id,
    }),
]

export const unitMeterBulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Meters?',
        },
    }),
]
