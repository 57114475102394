import { type FC } from 'react'

import Icons from 'assets/icons'
import { ArchivedSwitcher, ListTotalBadge, ViewHeader } from 'components'
import { useRecordContext } from 'core'
import { type CustomerModel } from 'resources/customers'
import { UnitDrawerToggler } from 'resources/units'
import { IconBox } from 'ui'

const Header: FC<{ isArchived: boolean }> = ({ isArchived }) => {
    const record = useRecordContext<CustomerModel>()

    return (
        <ViewHeader
            title="Units"
            loading={!record}
        >
            <ViewHeader.Content>
                <ListTotalBadge />
            </ViewHeader.Content>
            {isArchived ? null : (
                <ViewHeader.Content placement="rightMobile">
                    <UnitDrawerToggler defaultValues={{ customer: record?.id }}>
                        {({ onClick }) => (
                            <IconBox onClick={onClick}>
                                <Icons.Add />
                            </IconBox>
                        )}
                    </UnitDrawerToggler>
                </ViewHeader.Content>
            )}
            <ViewHeader.Content>
                <ArchivedSwitcher />
            </ViewHeader.Content>
            <ViewHeader.Content />
        </ViewHeader>
    )
}

export default Header
