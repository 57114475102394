import { type Identifier, type DataRecord } from 'appTypes'
import { useConfirm, type ConfirmConfig } from 'core/confirmation'
import { useRecordContext } from 'core/data'
import { type ServerErrors } from 'core/errors'
import { type UseCreateResourcePathParams } from 'core/resource'

import { dispatchAction } from '../actionEmitter'

import useDelete, { type UseDeleteParams } from './useDelete'
import { useDelete as useRaDelete } from './useRaDelete'

export interface DeleteOneParams extends Omit<UseDeleteParams, 'onConfirm'> {
    id?: Identifier

    onSuccess?: () => void

    redirectTo?: 'list' | '' | UseCreateResourcePathParams

    onError?: (params: {
        error: ServerErrors
        defaultOnError: () => void
        confirm: (params: ConfirmConfig) => void
    }) => void
}

const useDeleteOne = () => {
    const deleteResource = useDelete()
    const record = useRecordContext() || ({} as DataRecord)
    const [deleteOne] = useRaDelete()
    const confirm = useConfirm()

    return ({
        id,
        resource,
        onSuccess,
        // TODO: Can something else make the redirect? If no - remove the default value
        redirectTo = 'list',
        onError,
        ...rest
    }: DeleteOneParams = {}) => {
        deleteResource({
            ...rest,
            onConfirm: async (params) => {
                const finalResource = resource || params.resource
                const finalId = id || record.id
                await deleteOne(
                    finalResource.resource,
                    {
                        id: finalId,
                    },
                    {
                        onSuccess: () => {
                            onSuccess?.()
                            params.onSuccess()
                            dispatchAction(finalResource.name, {
                                name: 'delete',
                                payload: finalId,
                            })
                        },
                        onError: (error: ServerErrors) => {
                            if (onError) {
                                onError({
                                    error,
                                    defaultOnError: () => params.onError(error),
                                    confirm,
                                })
                            } else {
                                params.onError(error)
                            }
                        },
                    },
                )
            },
        })
    }
}

export default useDeleteOne
