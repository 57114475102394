import { type FC } from 'react'

import { RecordContextProvider, useListContext } from 'react-admin'

import { type WithChildrenNode } from 'appTypes'
import { Form } from 'components/form'
import { type FilterKnownValues } from 'core'
import { isObject, isObjectEmpty } from 'utils'

import { useListConfig } from '../ListContext'
import { filterOperators } from '../utils'

import FilterArrayController from './FilterArrayController'
import { type FilterFormValues, type FilterElement, type FilterValue } from './types'

interface Props extends WithChildrenNode {
    className?: string
}

const FilterWrapper: FC<Props> = ({ children, className }) => {
    const { filterValues, setFilters, displayedFilters } = useListContext()
    const { snap, state } = useListConfig()

    const values: { [key: string]: FilterValue } = {}

    const operators = (filterValues as FilterKnownValues).__o || {}

    const defaultValuesData = snap.config.filters.flatMap((filterConfig) => {
        const id = filterConfig.id as string
        const value = filterValues[id]
        const operator = operators[id]

        if (!value) {
            return []
        }

        const response: FilterElement = {
            name: id,
        }

        values[id] = {
            value,
        }

        if (operator) {
            values[id].operator = operator
        }

        return [response]
    })

    return (
        <RecordContextProvider value={null}>
            <Form
                warnWhenUnsavedChanges={false}
                className={className}
                defaultValues={
                    {
                        filters: defaultValuesData,
                        values,
                    } satisfies FilterFormValues
                }
                onSubmit={({ values, filters }: FilterFormValues) => {
                    const currentFilters = { ...filterValues }
                    delete (currentFilters as FilterKnownValues).__o

                    state.config.filters.forEach(({ id }) => {
                        delete currentFilters[id]
                    })

                    const filtersToApply: any = {}

                    filters.forEach((filter) => {
                        const { name } = filter

                        if (!name) {
                            return
                        }

                        const data = values[name]

                        if (!data) {
                            return
                        }

                        const { value, operator } = data

                        if (!(Array.isArray(value) ? value.length : value)) {
                            return
                        }

                        if (isObject(value)) {
                            Object.keys(value).forEach((key) => {
                                if (value[key] == null) {
                                    delete value[key]
                                }
                            })

                            if (isObjectEmpty(value)) {
                                return
                            }
                        }

                        if (operator && operator !== filterOperators.any) {
                            ;(filtersToApply as FilterKnownValues).__o ||= {}
                            ;(filtersToApply as FilterKnownValues).__o[name] = operator
                        }

                        filtersToApply[name] = value
                    })
                    const newFilter = { ...currentFilters, ...filtersToApply }
                    setFilters(newFilter, displayedFilters, false)

                    state.functions.filter.close()
                }}
            >
                <FilterArrayController>{children}</FilterArrayController>
            </Form>
        </RecordContextProvider>
    )
}

export default FilterWrapper
