import { InfoCard, InfoCardDetails, type InfoCardDetailsRow } from 'components'
import { useRecordContext } from 'core'
import { dateReadableFormat } from 'lib'
import { meterTypesConfig, meterTypesForUnit, meterValue } from 'resources/units'
import { type WorkOrderModel } from 'resources/workOrders'

const ClosedInfo = () => {
    const workOrder = useRecordContext<WorkOrderModel>()

    return (
        <InfoCard>
            <InfoCardDetails<WorkOrderModel>
                details={[
                    ...meterTypesForUnit.map((meterType): InfoCardDetailsRow<WorkOrderModel> => {
                        const value = workOrder.metersEnd[meterType]

                        if (value == null) {
                            return null
                        }

                        const meterConfig = meterTypesConfig[meterType]

                        return {
                            label: `Completion ${meterConfig.name}`,
                            source: () => meterValue(value, meterType),
                        }
                    }),
                    {
                        label: 'Completion Date & Time',
                        source: ({ completed }) => dateReadableFormat(completed),
                    },
                    {
                        label: 'Closed On date',
                        source: ({ statusUpdated }) => dateReadableFormat(statusUpdated),
                    },
                    {
                        label: 'Closed by',
                        source: ({ statusUpdatedBy }) =>
                            statusUpdatedBy?.name || statusUpdatedBy?.email,
                    },
                ]}
            />
        </InfoCard>
    )
}

export default ClosedInfo
