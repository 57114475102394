import { useMemo, useRef, type FC, type ReactNode } from 'react'

import { type GridEnrichedColDef, type GridRenderCellParams } from '@mui/x-data-grid'

import { type DataRecord } from 'appTypes'
import { useResource } from 'core'
import { useListContext } from 'core/list'
import { useFlags } from 'lib'
import { findLast } from 'utils'

import { DatagridActionsContainer, DatagridActionView } from '../DatagridAction'
import { type PinnedColumns, type DatagridColumnsProps } from '../types'
import {
    actionsField,
    columnGetMeta,
    columnMinWidth,
    columnSetMeta,
    datagridClasses,
    defaultMassColProps,
    pinnedColumnMaxWidth,
} from '../utils'

import { type ColumnsContextValue } from './ColumnsContext'
import useOrdering from './useOrdering'
import usePinning from './usePinning'
import useSizes from './useSizes'
import useVisiblity from './useVisibility'

// sometimes when we want only the skeleton, we might not pass columns config
const defaultConfig: DatagridColumnsProps = {
    columns: [],
}

const useColumns = (
    {
        columns: columnsProp,
        massColProps,
        pinnedColumns: pinnedColumnsProp,
        constantColumns,
        actions,
        resetColumns,
        avatarSource,
        mainField,
        disableColumnPinning = false,
        disableColumnReorder = false,
        disableColumnResize = false,
        actionsWidth,
    }: DatagridColumnsProps = defaultConfig,
    disableManageColumns: boolean = false,
): ColumnsContextValue => {
    const flags = useFlags()
    const listContext = useListContext()
    const resource = useResource()

    const columns = (columnsProp || []).filter(Boolean)

    const { order, updateOrder } = useOrdering({
        columns: columns as { field: string }[],
        disableColumnReorder,
    })

    const { sizes, setSize } = useSizes(disableColumnResize)

    const { pinnedColumns, setPinnedColumns, isColumnPinnable, isColumnPinned } = usePinning({
        initialPinnedColumns: pinnedColumnsProp as PinnedColumns,
        avatarSource: avatarSource as string,
        mainField: mainField as string,
        disableColumnPinning,
    })

    const { setVisibilityModel, visibilityModel, setColumnVisibility } = useVisiblity({
        resetColumns,
        mainField,
        columns,
        constantColumns,
        disabled: disableManageColumns,
    })

    const ref = useRef({
        flags,
        listContext,
    })
    // optimization. No need to recalculate columns if flags or listContext changes
    ref.current.flags = flags
    ref.current.listContext = listContext

    const configuredColumns = useMemo(() => {
        if (!columns) {
            return []
        }

        const providedColumns: GridEnrichedColDef<DataRecord>[] = columns.map((col) => {
            const column: GridEnrichedColDef<DataRecord> = {
                ...defaultMassColProps,
                ...massColProps,
                ...col,
                renderCell: (value) => {
                    const element = col?.renderCell?.(value, {
                        flags: ref.current.flags,
                        listContext: ref.current.listContext,
                    })
                    if (!element) {
                        return null
                    }
                    return <GridCell>{element}</GridCell>
                },
                field: col.field as string,
            }

            if (typeof column.pinnable === 'undefined') {
                column.pinnable = isColumnPinnable(column.field as string)
            }
            if (disableColumnResize) {
                column.flex = column.flex || 1
                column.width = undefined
            } else if (column.resizable) {
                const width = sizes[col.field as string] || col.width
                if (width) {
                    column.width = width
                }
            }

            // apply default min width only if column width or max width is less than default min width
            const minWidth = Math.max(column.maxWidth || 0, column.width || 0)
            if (minWidth >= columnMinWidth) {
                column.minWidth = columnMinWidth
            }

            if (
                disableManageColumns ||
                constantColumns?.[col.field as string] ||
                col.alwaysHidden ||
                col.field === mainField
            ) {
                column.hideable = false
            }

            column.disableColumnMenu = !column.pinnable && !column.hideable

            if (column.align) {
                column.headerAlign = column.align
            }

            if (column.width) {
                column.flex = undefined
            }

            const isPinned = isColumnPinned(column.field)

            if (isPinned) {
                column.maxWidth = pinnedColumnMaxWidth
            }

            columnSetMeta(column, {
                pinned: isPinned,
                toExport: col.toExport,
                hidden: visibilityModel[column.field] === false,
            })

            return column
        })

        let configuredColumns = providedColumns

        if (order) {
            const missingColumns = providedColumns.filter((col) => !order.includes(col.field))
            const orderedColumns = order.map(
                (field) => field && providedColumns.find((col) => col.field === field),
            )
            configuredColumns = [...orderedColumns, ...missingColumns].filter(Boolean)
        }
        if (!disableColumnResize) {
            const lastVisibleCol = findLast(
                configuredColumns,
                (col) => visibilityModel[col.field] !== false && !columnGetMeta(col).pinned,
            )

            if (lastVisibleCol) {
                if (lastVisibleCol.width) {
                    lastVisibleCol.minWidth = lastVisibleCol.width
                }
                delete lastVisibleCol.width
                lastVisibleCol.flex = 1
                lastVisibleCol.resizable = false
            }
        }

        if (actions) {
            configuredColumns.push({
                field: actionsField,
                headerName: 'Actions',
                width: actionsWidth || 130,
                sortable: false,
                filterable: false,
                resizable: false,
                disableColumnMenu: true,
                hideable: false,
                renderCell: (cell) => (
                    <DatagridActionsContainer>
                        {actions(cell as GridRenderCellParams<never, DataRecord>, {
                            children: (params) => <DatagridActionView {...params} />,
                            resource,
                        })}
                    </DatagridActionsContainer>
                ),
            })
        }

        return configuredColumns
    }, [
        columns,
        massColProps,
        isColumnPinnable,
        isColumnPinned,
        constantColumns,
        actions,
        sizes,
        order,
        visibilityModel,
        resource,
        mainField,
        disableColumnResize,
    ])

    return {
        columns: configuredColumns,

        visibilityModel,
        setVisibilityModel,
        setColumnVisibility,

        pinnedColumns,
        setPinnedColumns,

        setSize,

        updateOrder,
    }
}

const GridCell: FC<{ children: ReactNode }> = ({ children }) => (
    <div className={datagridClasses.cellContent}>{children}</div>
)

export default useColumns
