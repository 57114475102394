import { type PropsWithChildren, type FC } from 'react'

import Icons from 'assets/icons'
import { Anchor, IconElement, TextWithIcon, Typography } from 'ui'

const BaseStatusContent: FC<PropsWithChildren & { onClick?: () => void; color: string }> = ({
    onClick,
    children,
    color,
}) => {
    return (
        <TextWithIcon
            icon={
                <IconElement
                    sx={{ p: '5px' }}
                    component={Icons.Circle}
                    size="10px"
                    color={color}
                />
            }
        >
            <Typography
                onClick={onClick}
                component={onClick ? Anchor : 'span'}
                variant="inherit"
            >
                {children}
            </Typography>
        </TextWithIcon>
    )
}

export default BaseStatusContent
