import { createContext, useContext } from 'react'

import { useSnapshot } from 'valtio'

import { type State } from './types'

const Context = createContext<State>(null)

export const ListProvider = Context.Provider

export const useListPure = () => {
    return useContext(Context)
}

export const useListSnap = () => {
    return useSnapshot(useContext(Context)) as State
}

export const useListConfig = () => {
    const state = useListPure()
    const snap = useListSnap()

    return { state, snap }
}
