import { useEffect, useState, type FC } from 'react'

import { useCreatePath } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { api, authStore } from 'core'
import { useQuery, dateTimeParse } from 'lib'
import { type PMIntervalTypes } from 'resources/pm'
import {
    getMetersResource,
    MeterInput,
    type UnitMeterTypes,
    type MeterModel,
    meterTypesConfig,
} from 'resources/units'
import { Stack } from 'ui'
import { pathJoin } from 'utils'

import { type WorkOrderModel } from '../../types'
import { woResource } from '../../utils'

import LabelSwitch from './LabelSwitch'

interface WoCloseGetData {
    defects: { id: Identifier; name: string }[]
    pmSchedules: { id: Identifier; name: string; types: PMIntervalTypes[] }[]
    issues: { id: Identifier; name: string }[]
    trackedMeters: UnitMeterTypes[]
}

interface WoCloseDataType extends WoCloseGetData {
    requiredMeterTypes?: {
        [key in MeterModel['type']]?: string[]
    }
}

export type MeterType = Partial<MeterModel> & { pmIds: Identifier[] }

const WoCloseFields: FC<{ workOrder: WorkOrderModel }> = ({ workOrder }) => {
    const completed = useWatch({ name: 'completed' })

    const createPath = useCreatePath()

    const closeQuery = useQuery<WoCloseDataType>(['wo-close-data'], async () => {
        const woPath = createPath({
            resource: woResource.resource,
            type: 'edit',
            id: workOrder.id,
        })

        const { defects, pmSchedules, issues, trackedMeters }: WoCloseGetData = await api.get(
            woPath + '/close',
        )

        const requiredMeterTypes = pmSchedules.reduce(
            (obj, pm) => {
                pm.types.forEach((type) => {
                    if (type === 'TIME') {
                        return
                    }
                    if (!obj[type]) {
                        obj[type] = []
                    }
                    obj[type].push('pm.' + pm.id)
                })
                return obj
            },
            {} as WoCloseDataType['requiredMeterTypes'],
        )

        return {
            defects,
            pmSchedules,
            requiredMeterTypes,
            issues,
            trackedMeters,
        }
    })

    const currentMetersQuery = useQuery(
        ['current-meters', String(completed || '')],
        async () => {
            const data: { currentMeters: { [key in MeterModel['type']]?: MeterModel | null } } =
                await api.get(pathJoin(getMetersResource(workOrder.unit).resource, `current`), {
                    now: dateTimeParse(completed),
                })

            return data.currentMeters
        },
        {
            cacheTime: 0,
        },
    )

    if (!closeQuery.data) {
        return null
    }

    const { defects, pmSchedules, requiredMeterTypes, issues, trackedMeters } = closeQuery.data
    const requiredSetting = authStore.currentCompany.preferences.enforceMandatoryMetersOnWoClose

    return (
        <>
            {trackedMeters?.map((meterType) => (
                <CloseMeterInput
                    key={meterType}
                    meterType={meterType}
                    pmsName={requiredMeterTypes[meterType]}
                    required={requiredSetting}
                    lastMeter={currentMetersQuery.data?.[meterType]}
                />
            ))}
            <Stack spacing="13px">
                {issues.map((issue) => (
                    <LabelSwitch
                        label="Issue"
                        source={`issue.${issue.id}`}
                        key={issue.id}
                    >
                        {`Resolve ${issue.name}`}
                    </LabelSwitch>
                ))}
                {defects.map((defect) => (
                    <LabelSwitch
                        label="DVIR"
                        source={`dvir.${defect.id}`}
                        key={defect.id}
                    >
                        {`Resolve ${defect.name}`}
                    </LabelSwitch>
                ))}
                {pmSchedules.map((pm) => (
                    <LabelSwitch
                        label="PM"
                        source={`pm.${pm.id}`}
                        key={pm.id}
                    >
                        {`Reset ${pm.name}`}
                    </LabelSwitch>
                ))}
                {/* <Trigger /> */}
            </Stack>
        </>
    )
}

export default WoCloseFields

interface CloseMeterInputProps {
    meterType: UnitMeterTypes
    required: boolean
    pmsName: string[] | undefined
    lastMeter?: MeterModel | null
}

const CloseMeterInput: FC<CloseMeterInputProps> = ({
    meterType,
    required: requiredProp,
    pmsName,
    lastMeter,
}) => {
    const { getValues, watch, trigger } = useFormContext()
    const [pmRequired, setPmRequired] = useState(false)

    // boolean input default values don't trigger watch
    // on first render it gets undefined
    // Use state instead
    useEffect(() => {
        if (requiredProp) {
            return
        }

        const pms = pmsName || []
        const setRequired = () => {
            const meterPms = getValues(pms)
            setPmRequired(meterPms?.some(Boolean))
            // TODO: HACK trigger. For some reason the submit button doesn't get disabled if the input became required
            setTimeout(trigger, 200)
        }

        setTimeout(setRequired, 200)

        const subscription = watch((_, { name }) => {
            if (!name.startsWith('pm.')) {
                return
            }
            setRequired()
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    const meterConfig = meterTypesConfig[meterType]

    return (
        <MeterInput
            label={`Completion ${meterConfig.name}`}
            key={meterType}
            type={meterType}
            required={requiredProp || pmRequired}
            source={`meters.${meterType}`}
            lastReading={lastMeter}
        />
    )
}
