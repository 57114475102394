import { createContext, useContext } from 'react'

export interface ActionsMenuContextValue {
    isOpen: boolean
    close: (selected?: boolean) => void
    open: (event: React.MouseEvent<HTMLElement>) => void
}

const ActionsMenuContext = createContext<ActionsMenuContextValue>({} as ActionsMenuContextValue)

export const useActionsMenuContext = () => useContext(ActionsMenuContext)
export const ActionsMenuContextProvider = ActionsMenuContext.Provider
