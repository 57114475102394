import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    datagridAvatarColumn,
    type DatagridColumnsProps,
    ListAvatar,
    ListController,
    type ListControllerProps,
    ListUi,
} from 'components'
import { urls } from 'configs'
import {
    type ActionChildren,
    deleteOneAction,
    multiselectAction,
    ResourceContextProviderWithClearEffect,
    type SortPayload,
    useShowContext,
} from 'core'
import {
    type AssignmentModel,
    assignmentsResource,
    assignmentFields,
    deleteAssignmentParams,
    AssignmentsDrawer,
    assignmentsBulkAction,
} from 'resources/assignments'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import { isUnitArchived, type UnitModel } from 'resources/units'
import { Anchor } from 'ui'

import Header from './Header'

const defaultSort: SortPayload<AssignmentModel> = {
    field: 'start',
    order: 'DESC',
}

const UnitAssignmentsList = () => {
    const { record, isLoading } = useShowContext<UnitModel>()

    return (
        <ResourceContextProviderWithClearEffect value={assignmentsResource}>
            <ListController
                isLoading={isLoading}
                filters={filters}
                sorts={sorts}
                sort={defaultSort}
                filter={{
                    unitId: record?.id,
                }}
                preferencesName="unit-assignments"
            >
                <Header />
                <ListUi
                    bulkActions={assignmentsBulkAction}
                    cardsCfg={cardsCfg}
                    columnsCfg={columnsCfg}
                    listFTUProps={{
                        title: 'No Assignments',
                        linkText: record?.archived ? null : (
                            <AssignmentsDrawer defaultValues={{ unit: record?.id }}>
                                {(open) => <Anchor onClick={open}>Create Assignment</Anchor>}
                            </AssignmentsDrawer>
                        ),
                        imageSrc: images.expirations,
                        linkAction: () => {
                            //
                        },
                        action: null,
                    }}
                    exportFileName={`${record?.number}-assignments`}
                />
            </ListController>
        </ResourceContextProviderWithClearEffect>
    )
}

export default UnitAssignmentsList

const navigateTo = (id: Identifier) => {
    return `${urls.customers}/${id}`
}

const actions = (record: AssignmentModel, children: ActionChildren, cardActions?: boolean) => {
    const actions = [
        <AssignmentsDrawer
            key="edit"
            id={record.id}
            readOnly={isUnitArchived(record.unitData)}
        >
            {(open) =>
                children({
                    title: 'View/Edit',
                    Icon: Icons.Edit,
                    onClick: open,
                })
            }
        </AssignmentsDrawer>,
        deleteOneAction({
            children,
            id: record.id,
            ...deleteAssignmentParams,
        }),
    ]
    if (cardActions) {
        return [
            actions[0],
            multiselectAction({
                children,
                id: record.id,
            }),
            ...actions.slice(1),
        ]
    }

    return actions
}

const cardsCfg: CardListConfig<AssignmentModel> = {
    titleSource: (record) => customerFields.self.value(record.customerData),
    titleLink: (record) => navigateTo(record.customerData.id),
    subTitleSource: (record) => assignmentFields.status.value(record.status),
    imageSource: (record) => record.customerData.logo,
    defaultImage: <customerFields.avatar.Icon />,
    details: [
        assignmentFields.startDate.dataCardRow(),
        assignmentFields.endDate.dataCardRow(),
        assignmentFields.accumulatedCost.dataCardRow(),
    ],
    actions: (record, { children }) => actions(record, children, true),
}

const columnsCfg: DatagridColumnsProps<AssignmentModel & { photo: string }> = {
    resetColumns: {
        [createdField.source]: false,
    },
    mainField: 'customer',
    avatarSource: 'photo',
    columns: [
        datagridAvatarColumn({
            field: 'photo',
            headerName: 'Avatar',
            avatar: (record) => (
                <ListAvatar
                    id={record.id}
                    imageSrc={record.customerData.logo}
                    defaultImage={<customerFields.avatar.Icon />}
                    customPath={navigateTo(record.customerData.id)}
                />
            ),
        }),
        customerFields.self.tableColumn({
            dataToRecord: (record) => record.customerData,
            label: 'Customer Name',
            withLink: true,
        }),
        assignmentFields.startDate.tableColumn(),
        assignmentFields.endDate.tableColumn(),
        assignmentFields.status.tableColumn(),
        createdField.tableColumn({ dataToValue: (record: AssignmentModel) => record.created }),
        assignmentFields.accumulatedCost.tableColumn(),
    ],
    actions: ({ row }, { children }) => actions(row, children),
}

const filters: ListControllerProps<AssignmentModel>['filters'] = [
    customerFields.self.filter(),
    assignmentFields.startDate.filter(),
    assignmentFields.endDate.filter(),
    assignmentFields.status.filter(),
    assignmentFields.accumulatedCost.filter(),
    createdField.filter(),
]

const sorts: ListControllerProps<AssignmentModel>['sorts'] = [
    customerFields.self.sort(),
    assignmentFields.startDate.sort(),
    assignmentFields.endDate.sort(),
    assignmentFields.accumulatedCost.sort(),
    createdField.sort(),
]
