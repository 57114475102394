import { type FC } from 'react'

import { globalClassNames } from 'lib'
import { type PaymentInvoice } from 'resources/payments'
import { Box, Chip, type ChipProps, OverflowElements, type OverflowElementsProps } from 'ui'

interface Props {
    data: PaymentInvoice[]
    justify?: 'flex-end'
    chipSize?: ChipProps['size']
}

const PurchaseInvoicesField: FC<Props> = ({ data, justify, chipSize = 'small' }) => {
    if (!data?.length) {
        return null
    }

    return (
        <OverflowElements<PaymentInvoice>
            justify={justify}
            data={data}
            renderContainer={renderContainer}
            renderOverflow={(itemsCount) => (
                <Chip
                    size={chipSize}
                    sx={{ ml: '4px' }}
                    label={`+${itemsCount}`}
                />
            )}
            renderItem={(record) => (
                <Chip
                    key={record.id}
                    label={record.number}
                    size={chipSize}
                />
            )}
            tooltip={(item) => item.number}
        />
    )
}

export default PurchaseInvoicesField

const renderContainer: OverflowElementsProps<PaymentInvoice>['renderContainer'] = (children) => (
    <Box
        display="flex"
        alignItems="center"
        gap="4px"
        className={globalClassNames.ellipsis}
        children={children}
    />
)
