import { type FC } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import { SelectInput } from 'components/inputs'
import { useDidUpdate } from 'hooks'
import { formHelperTextClasses } from 'ui'

import PeriodRangeFilter, { type PeriodRangeFilterProps } from './PeriodRangeFilter'

interface PeriodRangeWithTypeFilterProps extends PeriodRangeFilterProps {
    choices: ChoiceValue[]
}

const PeriodRangeWithTypeFilter: FC<PeriodRangeWithTypeFilterProps> = ({
    choices: choicesProp,
    inputMinProps,
    inputMaxProps,
    ...props
}) => {
    const { getValues } = useFormContext()

    const source = props.valueSource
    const dataSource = props.dataSource

    const sources = {
        minSource: source + '.min',
        maxSource: source + '.max',

        inputMinSource: dataSource + '.min',
        inputMaxSource: dataSource + '.max',
        typeSource: dataSource + '.type',
    }
    const typeValue = getValues(sources.typeSource)

    const getParts = (source: string) => {
        const formValue = getValues(source) || ''
        const value = formValue.slice?.(0, -1) || ''
        const type = formValue.slice?.(-1)

        return [value, type]
    }

    const minParts = getParts(sources.minSource)
    const maxParts = getParts(sources.maxSource)

    return (
        <>
            <PeriodRangeFilter
                {...props}
                inputMinProps={{
                    source: sources.inputMinSource,
                    defaultValue: minParts[0],
                    ...inputMinProps,
                }}
                inputEndAdornment={typeValue}
                inputMaxProps={{
                    source: sources.inputMaxSource,
                    defaultValue: maxParts[0],
                    ...inputMaxProps,
                }}
                bottomContent={
                    <SelectInput
                        source={sources.typeSource}
                        choices={
                            choicesProp
                                ? choicesProp.map((choice) => typeChoicesObj[choice])
                                : choices
                        }
                        defaultValue={minParts[1] || maxParts[1] || defaultValue}
                        disableEmptyValue
                        clearable={false}
                        label="Type"
                        helperText={false}
                        sx={{
                            [`& .${formHelperTextClasses.root}`]: {
                                display: 'none !important',
                            },
                        }}
                    />
                }
            />
            <OnInputChange {...sources} />
        </>
    )
}

export default PeriodRangeWithTypeFilter

interface OnInputChangeProps {
    minSource: string
    inputMinSource: string
    maxSource: string
    inputMaxSource: string
    typeSource: string
}

const OnInputChange: FC<OnInputChangeProps> = ({
    minSource,
    inputMinSource,
    maxSource,
    inputMaxSource,
    typeSource,
}) => {
    const values = useWatch({ name: [typeSource, inputMinSource, inputMaxSource] })
    const { setValue } = useFormContext()

    useDidUpdate(() => {
        const [type, min, max] = values
        setValue(minSource, min ? min + type : null)
        setValue(maxSource, max ? max + type : null)
    }, values)

    return null
}

const defaultValue: ChoiceValue = 'h'

type ChoiceValue = 'h' | 'd' | 'm' | 'y'
type Choice = { id: ChoiceValue; name: string }

const choices: Choice[] = [
    {
        id: defaultValue,
        name: 'Hour(s)',
    },
    {
        id: 'd',
        name: 'Day(s)',
    },
    {
        id: 'm',
        name: 'Month(s)',
    },
    {
        id: 'y',
        name: 'Year(s)',
    },
]

const typeChoicesObj = choices.reduce(
    (acc, choice) => {
        acc[choice.id] = choice
        return acc
    },
    {} as { [C in ChoiceValue]: Choice },
)
