import { type ReactNode, type FC, type ReactElement } from 'react'

import { customInputIntegerNonNegativeSpacedMask, TextInput } from 'components/inputs'
import { Stack, formHelperTextClasses, Grid, GridContainerColumns } from 'ui'

import { type FilterRenderProps } from '../../types'

export interface PeriodRangeFilterProps extends FilterRenderProps {
    bottomContent?: ReactElement
    inputMinProps?: Partial<PeriodInputProps>
    inputMaxProps?: Partial<PeriodInputProps>
    inputEndAdornment?: ReactNode
}

const PeriodRangeFilter: FC<PeriodRangeFilterProps> = ({
    valueSource,
    bottomContent,
    inputMaxProps,
    inputMinProps,
    inputEndAdornment,
}) => {
    return (
        <Stack
            gap="16px"
            spacing={0}
        >
            <GridContainerColumns>
                <PeriodInput
                    source={valueSource + '.min'}
                    label="From"
                    {...inputMinProps}
                    inputEndAdornment={inputEndAdornment}
                />
                <PeriodInput
                    source={valueSource + '.max'}
                    label="To"
                    {...inputMaxProps}
                    inputEndAdornment={inputEndAdornment}
                />
            </GridContainerColumns>
            {bottomContent}
        </Stack>
    )
}

export default PeriodRangeFilter

interface PeriodInputProps {
    source: string
    label: string
    defaultValue?: string
    maxValueLength?: number
    inputEndAdornment?: ReactNode
}

const PeriodInput: FC<PeriodInputProps> = ({
    source,
    label,
    defaultValue,
    maxValueLength: maxValueLenght = 15,
    inputEndAdornment,
}) => {
    return (
        <Grid
            item
            xs={6}
        >
            <TextInput
                source={source}
                {...customInputIntegerNonNegativeSpacedMask(
                    maxValueLenght,
                    inputEndAdornment
                        ? { InputProps: { endAdornment: inputEndAdornment } }
                        : undefined,
                )}
                label={label}
                helperText={false}
                defaultValue={defaultValue}
                sx={{
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none !important',
                    },
                }}
            />
        </Grid>
    )
}
