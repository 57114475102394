import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { globalClassNames } from 'lib'
import { Typography } from 'ui'

const Title: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Typography
            variant="chartTitle"
            component="div"
            className={globalClassNames.ellipsis}
        >
            {children}
        </Typography>
    )
}

export default Title
