import { type DataRecord } from 'appTypes'
import { useResource, ActionsMenu, type MenuActionProps } from 'core'
import { useRecordContext } from 'core/data'
import { useFlags, useMediaQuery } from 'lib'
import { Spacer } from 'ui'

import HeaderDesktopAction from './HeaderDesktopActions'

export interface HeaderActionsProps<RecordType extends DataRecord = any> {
    actions: MenuActionProps<RecordType>['actions']
}

const HeaderActions = <RecordType extends DataRecord = any>({
    actions,
}: HeaderActionsProps<RecordType>) => {
    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    const flags = useFlags()
    const record = useRecordContext<RecordType>()
    const resource = useResource()

    return (
        <div>
            {isSmallScreen ? (
                <ActionsMenu<RecordType>
                    actions={actions}
                    record={record}
                />
            ) : (
                <Spacer>
                    {actions(record, {
                        children: (params) => <HeaderDesktopAction {...params} />,
                        resource,
                        flags,
                    })}
                </Spacer>
            )}
        </div>
    )
}

export default HeaderActions
