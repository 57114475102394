import { type FC } from 'react'

import { inputIntegerNonNegativeSpacedMask, TextInput } from 'components'
import { requiredValidation } from 'core'
import { formatDate } from 'lib'

import { type MeterModel, type UnitMeterTypes } from '../types'
import { meterTypesConfig, meterValue } from '../utils'

interface Props {
    type: UnitMeterTypes
    label?: string
    source?: string
    disabled?: boolean
    helperText?: string
    required?: boolean
    lastReading?: Pick<MeterModel, 'timestamp' | 'value'>
}

const MeterInput: FC<Props> = ({
    type: meterType,
    label,
    source,
    disabled,
    helperText,
    required,
    lastReading,
}) => {
    const meterConfig = meterTypesConfig[meterType]

    return (
        <TextInput
            disabled={disabled}
            source={source || meterType}
            label={label || meterConfig?.name}
            {...inputIntegerNonNegativeSpacedMask(
                meterConfig ? { InputProps: { endAdornment: meterConfig.adornment } } : undefined,
            )}
            helperText={getHelperText(helperText, lastReading, meterType)}
            validate={required ? requiredValidation : undefined}
        />
    )
}

export default MeterInput

const getHelperText = (
    helperText: string | undefined,
    lastReading: Props['lastReading'],
    meterType: UnitMeterTypes,
) => {
    if (helperText) {
        return helperText
    }

    if (!lastReading) {
        return undefined
    }

    return (
        <>
            Last reading {meterValue(lastReading.value, meterType)} •{' '}
            {formatDate(lastReading.timestamp, (f) => f.shortenedDateTime)}
        </>
    )
}
