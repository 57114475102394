import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { urls } from 'configs'
import { type AuthStore } from 'core'
import { Route, Routes, Navigate } from 'lib'
import { reportsSlugs } from 'resources/reports'

import { Assignments } from './Assignments'
import CostPerCategoryCK31 from './CostPerCategory'
import CostPerEquipmentCategoryCK2 from './CostPerEquipmentCategory'
import CostPerMeter from './CostPerMeter'
import CostPerResonForRepairCK14 from './CostPerReasonForRepairCK14'
import CostPerVMRSGroup from './CostPerVMRSGroup'
import Downtime from './Downtime'
import Dvirs from './Dvirs/Dvirs'
import MTBF from './MTBF'
import MaintenanceCost from './MaintenanceCost'
import PMIntervals from './PMIntervals'
import URME from './URME'
import UnitExpirations from './UnitExpirations'
import VendorExpenses from './VendorExpenses'

const Reports: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        return (
            <Routes>
                <Route
                    path={reportsSlugs.maintenanceCost}
                    element={<MaintenanceCost />}
                />
                (
                <Route
                    path={reportsSlugs.costPerMeter}
                    element={<CostPerMeter />}
                />
                ) (
                <Route
                    path={reportsSlugs.downtime}
                    element={<Downtime />}
                />
                )
                <Route
                    path={reportsSlugs.costPerCategory}
                    element={<CostPerCategoryCK31 />}
                />
                {auth.companySettings.ck33GroupsEnabled &&
                auth.companySettings.ck33GroupsSelectable ? (
                    <Route
                        path={reportsSlugs.costPerVMRSGroup}
                        element={<CostPerVMRSGroup />}
                    />
                ) : null}
                <Route
                    path={reportsSlugs.vendorExpenses}
                    element={<VendorExpenses />}
                />
                <Route
                    path={reportsSlugs.costPerReasonForRepair}
                    element={<CostPerResonForRepairCK14 />}
                />
                <Route
                    path={reportsSlugs.costPerEquipmentCategory}
                    element={<CostPerEquipmentCategoryCK2 />}
                />
                <Route
                    path={reportsSlugs.intervals}
                    element={<PMIntervals />}
                />
                <Route
                    path={reportsSlugs.defects}
                    element={<Dvirs />}
                />
                <Route
                    path={reportsSlugs.unitExpirations}
                    element={<UnitExpirations />}
                />
                <>
                    <Route
                        path={reportsSlugs.urme}
                        element={<URME />}
                    />
                    <Route
                        path={reportsSlugs.mtbf}
                        element={<MTBF />}
                    />
                </>
                {auth.companySettings.hasAssignments ? (
                    <Route
                        path={reportsSlugs.assignments}
                        element={<Assignments />}
                    />
                ) : null}
                <Route
                    path="*"
                    element={<Navigate to={urls.root} />}
                />
            </Routes>
        )
    }),
)

export default Reports
