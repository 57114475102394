import { type FC, useMemo } from 'react'

import {
    type TextInputProps,
    useUtilityDrawerContext,
    TextInput,
    inputIntegerPositiveMask,
} from 'components'
import { type Validator, requiredValidation } from 'core'

import { type UnitPMDrawerEditorExtraState } from './types'
import useInputSource from './useInputSource'

interface UnitPMMeterEveryInputProps extends Partial<TextInputProps> {}

const UnitPMMeterEveryInput: FC<UnitPMMeterEveryInputProps> = (props) => {
    const source = useInputSource()
    const validate = props.validate
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState

    const validation = useMemo(() => {
        const validation: Validator[] = [requiredValidation]
        if (validate) {
            validation.push(...(Array.isArray(validate) ? validate : [validate]))
        }
        return validation
    }, [validate])

    return (
        <TextInput
            disabled={isArchived}
            label="Every"
            {...props}
            validate={validation}
            {...inputIntegerPositiveMask(props)}
            source={source('value')}
        />
    )
}

export default UnitPMMeterEveryInput
