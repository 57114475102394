import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { DateTimeInput } from 'components'
import { type AuthStore, requiredValidation, validateDateBefore, type Validator } from 'core'
import { isWorkOrderBillable } from 'resources/workOrders/isWorkOrderBillable'
import { Box } from 'ui'

import { type WorkOrderModel } from '../../types'

import WoCloseFields from './CloseFields'
import InvoicingContent from './InvoicingContent'

interface Props {
    workOrder: WorkOrderModel
}

const CloseContent: FC<Props> = inject('auth')(
    observer(({ workOrder, auth }: Props & { auth: AuthStore }) => {
        return (
            <Box mt="12px">
                <DateTimeInput
                    source="started"
                    label="Start Date &amp; Time"
                    disabled
                    defaultValue={workOrder.started}
                    validate={requiredValidation}
                />
                <DateTimeInput
                    source="completed"
                    label="Completion Date &amp; Time"
                    disableFuture
                    validate={complitionDateValidator}
                />
                <WoCloseFields workOrder={workOrder} />
                {auth.companySettings.hasInvoicing &&
                    !workOrder.salesOrderData?.invoiceData &&
                    isWorkOrderBillable(workOrder) && <InvoicingContent workOrder={workOrder} />}
            </Box>
        )
    }),
)

export default CloseContent

const complitionDateValidator: Validator[] = [
    validateDateBefore('started', 'Completion date cannot be before starting date'),
    requiredValidation,
]
