import { type FC, type ReactElement } from 'react'

import { useListContext, useRecordContext } from 'react-admin'
import { useFormContext, useFormState } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    ArrayControllerBox,
    ArrayControllerContextProvider,
    ArrayControllerDeleteIcon,
    ArrayControllerElements,
    CheckboxInput,
    DateInput,
    FormPreventSubmit,
    SelectInput,
    TextInput,
    TextareaInput,
    UtilityDrawerDeleteButton,
    UtilityDrawerEditor,
    useArrayControllerContext,
    useArrayControllerElementContext,
    useOpenUtilityDrawer,
    UtilityDrawerSaveButton,
    inputIntegerNonNegativeSpacedMaskParams,
} from 'components'
import {
    type Serializer,
    maxLengthValidationText,
    requiredValidation,
    useResource,
    validateMaxLength,
    formErrorToString,
} from 'core'
import { globalClassNames, dateGetRelativeDelta, timeLeftFormat } from 'lib'
import { Threshold, thresholdTypeChoices } from 'resources/common'
import { DocumentAvatar } from 'resources/documents'
import { maxFileSize } from 'resources/gallery'
import { StatusInput } from 'resources/units'
import { useResources } from 'resources/utils'
import {
    Typography,
    GridContainerColumns,
    GridItem,
    InfoBadge,
    NonDisplayedInput,
    SectionTitleSmall,
    Spacer,
    Tooltip,
    Alert,
    Box,
    Button,
    FormHelperText,
    Stack,
} from 'ui'
import { displayFileExtension, getFileName } from 'utils'

import expirationFields from '../fields'
import { type ExpirationModel } from '../types'

interface Props {
    children: (open: () => void) => ReactElement
    id?: Identifier
    readOnly?: boolean
    defaultValues?: Partial<ExpirationModel>
}

export const useExpirationEditor = () => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const resource = useResource()

    return ({ id, readOnly, defaultValues }: Omit<Props, 'children'> = {}) => {
        open({
            drawerArgs: {
                title: id ? 'Edit Expiration' : 'Set Expiration',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        defaultValues={defaultValues}
                        serializer={serializer}
                        successMessage={id ? undefined : 'Successfully set'}
                    />
                ),
                renderContent: () => <ExpirationContent readOnly={readOnly} />,
                renderTopRight: () => (
                    <DeleteButton
                        readOnly={readOnly}
                        id={id}
                    />
                ),
                renderBottomRight: readOnly ? () => null : () => <SaveButton />,
            },
            extraArgs: {
                listContext,
                id,
                resource,
            },
        })
    }
}

const SaveButton: FC = () => {
    const record = useRecordContext<ExpirationModel>()

    if (!record) {
        return (
            <UtilityDrawerSaveButton
                label="Set"
                startIcon={<Icons.Event />}
                icon={null}
            />
        )
    }

    if (record.status === expirationFields.status.Keys.COMPLETED) {
        return null
    }

    return <UtilityDrawerSaveButton />
}

const DeleteButton: FC<{ readOnly: boolean; id: Identifier }> = ({ readOnly, id }) => {
    if (readOnly || !id) {
        return null
    }

    return (
        <UtilityDrawerDeleteButton
            confirmConfig={{ title: 'Are you sure you want to delete this expiration?' }}
        />
    )
}

const ExpirationEditor: FC<Props> = ({ children, id, readOnly, defaultValues }) => {
    const open = useExpirationEditor()

    return children(() => {
        open({
            id,
            readOnly,
            defaultValues,
        })
    })
}

export default ExpirationEditor

const ExpirationContent: FC<{ readOnly?: boolean }> = ({ readOnly }) => {
    const record = useRecordContext<ExpirationModel>()

    return <Content getInputProps={getInputProps(record, readOnly)} />
}

const getInputProps = (
    record: ExpirationModel,
    readOnly: boolean,
): ContentProps['getInputProps'] => {
    if (readOnly) {
        return () => ({ disabled: true })
    }

    if (!record?.id) {
        return undefined
    }

    if (record.status === expirationFields.status.Keys.COMPLETED) {
        return () => ({ disabled: true })
    }
}

const nameValidation = [requiredValidation, validateMaxLength(150)]
const valueValidation = validateMaxLength(15)

interface ContentProps {
    getInputProps?: (source: string) => { disabled?: boolean }
}
const defaultProps = {}
const getDefaultInputProps = () => defaultProps

const Content: FC<ContentProps> = ({ getInputProps = getDefaultInputProps }) => {
    return (
        <>
            <SectionTitleSmall>Details</SectionTitleSmall>

            <TextInput
                {...getInputProps(expirationFields.name.source)}
                source={expirationFields.name.source}
                label={expirationFields.name.label}
                validate={nameValidation}
            />

            <ExpirationDateInput {...getInputProps(expirationFields.date.source)} />

            <TextareaInput
                {...getInputProps(expirationFields.description.source)}
                source={expirationFields.description.source}
                label={expirationFields.description.label}
                validate={maxLengthValidationText}
            />

            <ExpirationCheckbox
                {...getInputProps(expirationFields.shouldChangeUnitStatus.source)}
            />
            <ExpirationStatusInput {...getInputProps(expirationFields.targetStatus.source)} />

            <SectionTitleSmall>Expiring Soon Trigger</SectionTitleSmall>

            <GridContainerColumns>
                <GridItem xs={6}>
                    <TextInput
                        {...getInputProps(expirationFields.threshold.valueSource)}
                        source={expirationFields.threshold.valueSource}
                        label="Value"
                        {...inputIntegerNonNegativeSpacedMaskParams}
                        validate={valueValidation}
                    />
                </GridItem>

                <GridItem xs={6}>
                    <SelectInput
                        {...getInputProps(expirationFields.threshold.typeSource)}
                        source={expirationFields.threshold.typeSource}
                        label="Type"
                        choices={thresholdTypeChoices}
                        defaultValue={Threshold.DAY}
                        disableEmptyValue
                        clearable={false}
                    />
                </GridItem>
            </GridContainerColumns>

            <Documents {...getInputProps('files')} />
        </>
    )
}

const ExpirationCheckbox = ({ disabled }: { disabled?: boolean }) => {
    const { unitStatus } = useResources()

    if (!unitStatus.show) {
        return null
    }

    return (
        <CheckboxInput
            disabled={disabled}
            source={expirationFields.shouldChangeUnitStatus.source}
            label="Change unit status if expiration becomes overdue."
        />
    )
}
const ExpirationStatusInput = ({ disabled }: { disabled?: boolean }) => {
    const { getValues, watch } = useFormContext()
    const { unitStatus } = useResources()

    const value =
        watch(expirationFields.shouldChangeUnitStatus.source) ||
        getValues(expirationFields.shouldChangeUnitStatus.source)

    if (!value || !unitStatus.show) {
        return null
    }

    return (
        <StatusInput
            disabled={disabled}
            source={expirationFields.targetStatus.source}
            label={expirationFields.targetStatus.label}
            helperText="Selected unit status will apply when expiration is overdue"
        />
    )
}

const ExpirationDateInput: FC<{ disabled?: boolean }> = (props) => {
    const source = expirationFields.date.source
    const { getValues, watch } = useFormContext()

    const value = watch(source) || getValues(source)

    return (
        <DateInput
            {...props}
            source={source}
            label={expirationFields.date.label}
            validate={requiredValidation}
            defaultValue={new Date()}
            helperText={value ? timeLeftFormat(dateGetRelativeDelta(value)) : undefined}
        />
    )
}

const Documents: FC<{ disabled?: boolean }> = ({ disabled }) => {
    const { setValue, clearErrors } = useFormContext()
    const record = useRecordContext<ExpirationModel>()

    return (
        <ArrayControllerContextProvider
            initialArray={() => {
                if (record) {
                    return expirationFields.files.indexes(record)
                }
                return []
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb="16px"
            >
                <Spacer>
                    <Typography
                        variant="subtitle1"
                        color="text.primary"
                    >
                        Documents
                    </Typography>
                    <ElementsCount />
                </Spacer>
                <AddButton disabled={disabled} />
            </Stack>
            <Stack>
                <ArrayControllerElements
                    onDelete={({ item }) => {
                        const source = getName(item)
                        setValue(source, null, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                        })
                        clearErrors(source)
                    }}
                >
                    <Element disabled={disabled} />
                </ArrayControllerElements>
            </Stack>
            <NoData />
            <Submittable />
        </ArrayControllerContextProvider>
    )
}

const ElementsCount = () => {
    const { array } = useArrayControllerContext()

    return <InfoBadge badgeContent={String(array.length)} />
}

const AddButton: FC<{ disabled?: boolean }> = ({ disabled: disabledProp }) => {
    const { append, array, limit } = useArrayControllerContext()
    const { setError, setValue } = useFormContext()

    const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = (event.target as HTMLInputElement).files?.[0]
        if (!file) {
            return
        }

        const index = append()
        const source = getName(index)
        setValue(source, file, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        })

        if (file.size > maxFileSize.size) {
            setError(source, { message: maxFileSize.errorMessage })
        }

        event.target.value = null
    }

    const disabled = disabledProp ?? array.length >= limit

    const button = (
        <Button
            variant="text"
            startIcon={<Icons.AttachFileOutlined />}
            component="div"
            disabled={disabled}
        >
            Choose Files
        </Button>
    )

    if (disabled) {
        return (
            <Tooltip title={`Maximum ${limit} files`}>
                <span>{button}</span>
            </Tooltip>
        )
    }

    return (
        <label>
            <NonDisplayedInput
                type="file"
                onChange={upload}
            />
            <Tooltip title={`Add up to ${limit} files, max size 30 MB each`}>{button}</Tooltip>
        </label>
    )
}

const Element: FC<{ disabled?: boolean }> = ({ disabled }) => {
    const { item } = useArrayControllerElementContext()
    const { getValues, getFieldState } = useFormContext()

    const source = getName(item)
    const file = getValues(source)
    if (!file) {
        return null
    }
    const error = getFieldState(source).error

    return (
        <ArrayControllerBox
            gap="12px"
            title={
                <Stack
                    direction="row"
                    overflow="hidden"
                    alignItems="center"
                >
                    <DocumentAvatar file={file} />
                    <Box
                        ml="12px"
                        overflow="hidden"
                    >
                        <Typography
                            variant="h6"
                            color="text.primary"
                            className={globalClassNames.ellipsis}
                        >
                            {getFileName(file)}
                        </Typography>
                        <Typography
                            variant="chartTitle"
                            color="text.primary"
                        >
                            {displayFileExtension(file)}
                        </Typography>
                    </Box>
                </Stack>
            }
            deleteAlwaysVisible
            renderDeleteButton={() => (
                <ArrayControllerDeleteIcon
                    disabled={disabled}
                    controller={{ alwaysVisible: true }}
                    size="small"
                    sx={{ mb: 'auto' }}
                >
                    <Icons.Close fontSize="inherit" />
                </ArrayControllerDeleteIcon>
            )}
        >
            {error ? <FormHelperText error>{formErrorToString(error)}</FormHelperText> : null}
        </ArrayControllerBox>
    )
}

const getName = (index: number) => `file${index}`

const NoData = () => {
    const { array } = useArrayControllerContext()

    if (array.length) {
        return null
    }

    return <Alert severity="info">No Documents Added</Alert>
}

const Submittable = () => {
    const { errors } = useFormState()

    if (!Object.keys(errors).length) {
        return null
    }

    return <FormPreventSubmit />
}

const files: Serializer = Array(10)
    .fill(0)
    .map((_, index) => ({
        name: getName(index),
        parse: 'file',
    }))

const serializer: Serializer<ExpirationModel> = [
    'name',
    { name: 'expirationDate', parse: 'date' },
    'description',
    { name: 'altersUnitStatus', parse: 'boolean' },
    { name: 'thresholdValue', parse: 'number' },
    'thresholdType',
    'targetStatus',
    ...(files as Serializer<ExpirationModel>),
]
