import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    LinkArrowButton,
    formatMoney,
    FilterValueInput,
    type ListControllerProps,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { type AuthStore } from 'core/auth'
import { getListData } from 'core/data'
import { vendorFilters } from 'pages/Vendors/List/VendorsList'
import { addressFields } from 'resources/address'
import { countryFields } from 'resources/country'
import { LineItemTypeKeys } from 'resources/lineItems'
import { stateFields } from 'resources/state'
import {
    type VendorAspects,
    type VendorAggregates,
    type VendorModel as BaseVendorModel,
    vendorAspects,
    vendorsResource,
    formatVendorTypes,
    formatVendorType,
    type VendorType,
} from 'resources/vendors'
import { woFields } from 'resources/workOrders'
import { PageContent } from 'ui'

import { ListControllerWithPeriod, ListUiWithPeriod, ReportsHeader } from '../components'
import { reportCostCenterFilter, reportDivisionFilter } from '../utils'

const aspects = [vendorAspects.totals] satisfies VendorAspects[]

interface VendorModel extends BaseVendorModel<typeof aspects> {}

const defaultSort: SortPayload<VendorModel> = {
    field: 'total',
    order: 'DESC',
}

const sorts: ListControllerProps<VendorModel>['sorts'] = [
    { id: 'name', label: 'Vendor Name' },
    woFields.total.sort(LineItemTypeKeys.PART),
    woFields.total.sort(LineItemTypeKeys.LABOR),
    woFields.total.sort(LineItemTypeKeys.SERVICE),
    woFields.total.sort(LineItemTypeKeys.FEE),
    woFields.total.sort(LineItemTypeKeys.TAX),
    woFields.total.sort({ label: 'Total Expenses' }),
]

const navigateTo = (id: Identifier) => `${urls.vendors}/${id}`

const cardsCfg: CardListConfig<VendorModel> = {
    titleSource: 'name',
    titleLink: (record) => navigateTo(record.id),
    defaultImage: null,
    action: (record) => <LinkArrowButton path={navigateTo(record.id)} />,
    details: [
        {
            source: 'type',
            label: 'Vendor Type',
            render: formatVendorTypes,
        },
        woFields.total.dataCardRow(LineItemTypeKeys.PART),
        woFields.total.dataCardRow(LineItemTypeKeys.LABOR),
        woFields.total.dataCardRow(LineItemTypeKeys.SERVICE),
        woFields.total.dataCardRow(LineItemTypeKeys.FEE),
        woFields.total.dataCardRow(LineItemTypeKeys.TAX),
        woFields.total.dataCardRow({ label: 'Total Expenses' }),
    ],
}

const columnsCfg: DatagridColumnsProps<VendorModel> = {
    resetColumns: {
        [addressFields.city.source]: false,
        [addressFields.zipCode.source]: false,
        [stateFields.self.source]: false,
        [countryFields.self.source]: false,
    },
    mainField: 'name',
    pinnedColumns: {
        right: [woFields.total.getConfig().source],
    },
    constantColumns: {
        [woFields.total.getConfig().source]: true,
    },
    checkboxSelection: false,
    columns: [
        {
            field: 'name',
            headerName: 'Vendor Name',
            renderCell: (params) => {
                return <DatagridLink {...params} />
            },
        },
        {
            field: 'type',
            headerName: 'Vendor Type',
            valueFormatter: ({ value }) => formatVendorTypes(value),
        },
        woFields.total.tableColumn(LineItemTypeKeys.PART),
        woFields.total.tableColumn(LineItemTypeKeys.LABOR),
        woFields.total.tableColumn(LineItemTypeKeys.SERVICE),
        woFields.total.tableColumn(LineItemTypeKeys.FEE),
        woFields.total.tableColumn(LineItemTypeKeys.TAX),
        woFields.total.tableColumn({ label: 'Total Expenses' }),
        countryFields.self.tableColumn({
            dataToRecord: (record: VendorModel) => record.countryData,
        }),
        stateFields.self.tableColumn({ dataToRecord: (record: VendorModel) => record.stateData }),
        addressFields.city.tableColumn({}),
        addressFields.zipCode.tableColumn({}),
    ],
    actions: null,
}

const VendorExpenses: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const filters: ListControllerProps<VendorModel>['filters'] = [
            { id: 'name', label: 'Vendor Name' },
            {
                id: 'type',
                label: 'Vendor Type',
                renderComponent: (props) => (
                    <FilterValueInput
                        {...props}
                        makeItemLabel={(option) => formatVendorType(option.id as VendorType)}
                    />
                ),
            },
            vendorFilters.state,
            addressFields.city.filter({}),
            addressFields.zipCode.filter({}),
            vendorFilters.paymentMethod,
            vendorFilters.paymentTerm,
            vendorFilters.tags,
            woFields.total.filter(LineItemTypeKeys.PART),
            woFields.total.filter(LineItemTypeKeys.LABOR),
            woFields.total.filter(LineItemTypeKeys.SERVICE),
            woFields.total.filter(LineItemTypeKeys.FEE),
            woFields.total.filter(LineItemTypeKeys.TAX),
            woFields.total.filter({ label: 'Total Expenses' }),
            reportCostCenterFilter(auth),
            reportDivisionFilter(auth),
        ]

        return (
            <ResourceContextProviderWithClearEffect value={vendorsResource}>
                <ListControllerWithPeriod
                    preferencesName="reports-vendor-expenses"
                    sort={defaultSort}
                    filter={{ withAspects: aspects }}
                    sorts={sorts}
                    filters={filters}
                >
                    <ReportsHeader<VendorModel>
                        renderTotal={(list) => {
                            const listData = getListData<VendorAggregates>(list)
                            return (
                                <>
                                    {list.total} | {formatMoney(listData.total || 0)} total
                                </>
                            )
                        }}
                    >
                        Total Vendor Expenses
                    </ReportsHeader>
                    <PageContent>
                        <ListUiWithPeriod
                            exportFileName="total-vendor-expenses"
                            cardsCfg={cardsCfg}
                            columnsCfg={columnsCfg}
                        />
                    </PageContent>
                </ListControllerWithPeriod>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default VendorExpenses
