import 'simplebar-react/dist/simplebar.min.css'
import { type FC, forwardRef, useEffect, useRef } from 'react'

import { Box } from '@mui/material'
import SimpleBarReact from 'simplebar-react'

import { type WithChildrenNode } from 'appTypes'
import { clsx, cssVariables, globalClassNames, styled } from 'lib'

const scrollbarClasses = {
    content: 'simplebar-content',
    scrollbar: 'simplebar-scrollbar',
}

interface Props extends WithChildrenNode {
    replaceP?: 'xb' | 'x'
    shadow?: ShadowVariant
    alwaysVisible?: boolean
    className?: string
    scrollbarMinSize?: number
}

type ShadowVariant = 't' | 'b' | 'y'

const Scrollbar: FC<Props> = styled(
    forwardRef<any, Props>(
        (
            { replaceP, shadow, alwaysVisible, className, children, scrollbarMinSize }: Props,
            ref,
        ) => {
            const scrollableNodeRef = useRef<HTMLElement>()

            useEffect(() => {
                if (!shadow) {
                    return
                }

                const element = scrollableNodeRef.current

                const observer = new ResizeObserver(() => {
                    handleScroll({ target: element })
                })

                observer.observe(element.querySelector(`.${scrollbarClasses.content}`))

                element.addEventListener('scroll', handleScroll as any)

                handleScroll({ target: element })

                return () => {
                    observer.disconnect()
                    if (element) {
                        element.removeEventListener('scroll', handleScroll as any)
                    }
                }
            }, [Boolean(shadow)])

            return (
                <Box
                    ref={ref}
                    flexGrow={1}
                    height="100%"
                    overflow="hidden"
                    boxSizing="border-box"
                    className={clsx(
                        (replaceP === 'x' || replaceP === 'xb') && globalClassNames.ignorePx,
                        replaceP === 'xb' && globalClassNames.ignorePb,
                    )}
                >
                    <SimpleBarReact
                        scrollableNodeProps={{
                            ref: scrollableNodeRef,
                            'data-shadow-orientation': shadow,
                        }}
                        autoHide={!alwaysVisible}
                        className={clsx(
                            (replaceP === 'x' || replaceP === 'xb') && globalClassNames.extendPx,
                            replaceP === 'xb' && 'scrollbar-pb',
                            scrollClass,
                            className,
                        )}
                        // Passing undefined leads to unexpected behavior. Don't pass it if it's not set.
                        {...(scrollbarMinSize && { scrollbarMinSize })}
                    >
                        {children}
                    </SimpleBarReact>
                </Box>
            )
        },
    ),
)<Props>`
    height: 100%;
    box-sizing: border-box;

    & ${'.' + scrollbarClasses.scrollbar}::before {
        background-color: #ababab;
    }

    &.scrollbar-pb .${scrollbarClasses.content} {
        padding-bottom: var(${cssVariables.pb}) !important;
    }
`

const scrollClass = 'scrollbar-container'

export default Scrollbar

const handleScroll = (event: { target: HTMLElement }) => {
    const element = event.target
    const { scrollTop, scrollHeight, clientHeight } = element
    const shadowOrientation = element.dataset.shadowOrientation as ShadowVariant
    const shadowTop = (shadowOrientation === 'y' || shadowOrientation === 't') && scrollTop > 0
    const shadowBottom =
        (shadowOrientation === 'y' || shadowOrientation === 'b') &&
        scrollTop + clientHeight < scrollHeight
    let position = ''

    if (shadowTop) {
        if (shadowBottom) {
            position = 'vertical'
        } else {
            position = 'top'
        }
    } else if (shadowBottom) {
        position = 'bottom'
    }

    element.dataset.shadow = position
}
