import { type FC } from 'react'

import { type MeterModel } from 'resources/units'
import { woFields } from 'resources/workOrders'

import { translateMeterSource } from '../constants'

interface Props {
    meter: MeterModel
}

const MeterSource: FC<Props> = ({ meter }) => {
    if (meter.source === 'WORK_ORDER') {
        return woFields.self.linkValue(meter.workOrder)
    }

    return <>{translateMeterSource(meter.source)}</>
}

export default MeterSource
