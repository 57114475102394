import { type FC } from 'react'

import { useResourceContext } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { useClearResource } from 'core'
import { ResourcePreferencesProvider } from 'core/context'

import { FirstLayer } from './FirstLayer'
import { SecondLayer, type SecondLayerProps } from './SecondLayer'
import { ThirdLayer } from './ThirdLayer'
import { type ListControllerProps } from './types'

const ListController = <RecordType extends DataRecord>({
    children,
    preferencesName,
    resource,
    filterValues,
    disableSyncWithLocation,
    filter,
    ListLayer,
    isLoading,
    clearResource,
    ...props
}: ListControllerProps<RecordType> & {
    ListLayer?: FC<SecondLayerProps>
    clearResource?: boolean
}) => {
    const ListBase = ListLayer || SecondLayer

    return (
        <ResourcePreferencesProvider resource={preferencesName || resource}>
            <FirstLayer {...props}>
                <ListBase
                    filterValues={filterValues}
                    filter={filter}
                    resource={resource}
                    disableSyncWithLocation={disableSyncWithLocation}
                    isLoading={isLoading}
                >
                    <ThirdLayer>{children}</ThirdLayer>
                    {clearResource && <ClearResource resource={resource} />}
                </ListBase>
            </FirstLayer>
        </ResourcePreferencesProvider>
    )
}

export default ListController

const ClearResource: FC<{ resource: string }> = ({ resource }) => {
    const finalResource = useResourceContext({ resource })
    useClearResource(finalResource)

    return null
}
