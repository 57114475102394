import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    InfoCardDetails,
    InfoCardHeader,
    CollapsibleInfoCard,
    CardCollapsibleContent,
    CollapsibleIconHolder,
} from 'components'
import { type Action, deleteOneAction } from 'core/actions'
import { formatDate, globalClassNames, stringDateFixTimezone } from 'lib'
import { displayPaymentTerm } from 'resources/paymentTerms'
import { Typography, Box, Anchor, type AnchorProps } from 'ui'

import { type PurchaseInvoiceModel } from '../types'
import { formatInvoiceAmount } from '../utils'

import { AttachmentsCollapsibleContent } from './AttachmentsCollapsibleContent'
import { PurchaseInvoiceDrawerToggler, deleteInvoiceAction } from './PurchaseInvoiceDrawerToggler'

export const PurchaseInvoiceCard = ({
    invoice,
    disableActions,
    disablePreview,
    removeActions,
}: {
    invoice: PurchaseInvoiceModel
    disableActions?: boolean
    disablePreview?: boolean
    removeActions?: boolean
}) => {
    const attachments = Array(10)
        .fill(0)
        .flatMap((v, index) => invoice[`attachment${index}`] || [])

    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                actions={(p, p2) =>
                    removeActions
                        ? []
                        : invoiceActions({ id: invoice.id, disabled: disableActions }, p2)
                }
                title={
                    <InvoiceCardTitle
                        disabled={disablePreview}
                        invoice={invoice}
                        disableActions={disableActions}
                    />
                }
            />
            <InfoCardDetails<PurchaseInvoiceModel>
                details={[
                    {
                        label: 'amount',
                        source: ({ amount }) => (
                            <Typography
                                variant="inherit"
                                color={amount < 0 ? 'error.main' : 'text.primary'}
                            >
                                {formatInvoiceAmount(amount)}
                            </Typography>
                        ),
                    },
                    {
                        label: 'date',
                        source: ({ date }) =>
                            date &&
                            formatDate(
                                stringDateFixTimezone(date),
                                (dateFormats) => dateFormats.shortenedDate,
                            ),
                    },
                    {
                        label: 'payment term',
                        source: ({ paymentTermData }) => displayPaymentTerm(paymentTermData),
                    },
                ]}
                propRecord={invoice}
            />
            <Box pt="10px">
                <CardCollapsibleContent
                    content={[
                        {
                            iconHolder: (
                                <CollapsibleIconHolder
                                    name="attachments"
                                    title={`Attachments (${attachments.length})`}
                                    disabled={attachments.length === 0}
                                    titleOnDisabled="No Attachments Added"
                                    key="attachmentsIcon"
                                >
                                    <Icons.AttachFileOutlined fontSize="small" />
                                </CollapsibleIconHolder>
                            ),
                            component: (
                                <AttachmentsCollapsibleContent
                                    attachments={attachments}
                                    key="attachmentsContent"
                                />
                            ),
                        },
                    ]}
                />
            </Box>
        </CollapsibleInfoCard>
    )
}

const InvoiceCardTitle = ({
    invoice,
    disabled,
    disableActions,
}: {
    invoice: PurchaseInvoiceModel
    disabled?: boolean
    disableActions?: boolean
}) => {
    if (disabled) {
        return (
            <TitleBase
                color="text.primary"
                invoice={invoice}
            />
        )
    }
    return (
        <PurchaseInvoiceDrawerToggler
            id={invoice.id}
            readOnly={disableActions}
        >
            {(open) => (
                <TitleBase
                    invoice={invoice}
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={open}
                    color="primary.main"
                />
            )}
        </PurchaseInvoiceDrawerToggler>
    )
}

const TitleBase: FC<{ invoice: PurchaseInvoiceModel } & Omit<AnchorProps, 'children'>> = ({
    invoice,
    ...props
}) => {
    return (
        <Anchor
            className={globalClassNames.ellipsis}
            display="block"
            {...props}
        >
            Invoice No {invoice.number}
        </Anchor>
    )
}

interface InvoiceActionsProps {
    id: Identifier
    disabled: boolean
}
const invoiceActions: Action = ({ id, disabled }: InvoiceActionsProps, { children }) => [
    <PurchaseInvoiceDrawerToggler
        id={id}
        key="edit"
        children={(open) => children({ title: 'Edit/View', Icon: Icons.Edit, onClick: open })}
        readOnly={disabled}
    />,
    deleteOneAction({
        redirectTo: '',
        children,
        id,
        disabled,
        ...deleteInvoiceAction(),
    }),
]
