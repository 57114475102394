import { type Identifier, type BaseModel, type ActionBy, type NestedKeys } from 'appTypes'
import { type CostCenterModel } from 'resources/costCenters'
import { type DivisionModel } from 'resources/divisions'
import { type SalesOrderModel, type InvoiceModel } from 'resources/invoices'
import { type WithUnit, type UnitMeterTypes } from 'resources/units'
import { type ShopModel } from 'resourcesBase'

import { type WoPurchaseOrderModel } from './payments'

export interface WorkOrderModel extends BaseModel, WithUnit {
    number: string
    started: string
    completed: string
    created: string
    createdBy: ActionBy
    closed: string
    description: string
    engineHours: number
    jobsCount: number
    jobItemsCount: number
    odometerDistance: number
    priority: WoPriorityKeys
    repairPriorityClass: WoRepairClassKeys
    status: WoStatusKeys
    urlJobs: string
    total: number | null
    totalParts: number | null
    totalLabor: number | null
    totalFees: number | null
    totalTax: number | null
    totalServices: number | null
    cancellationReason: string
    statusUpdatedBy: ActionBy
    statusUpdated: string
    metersStart: { [key in UnitMeterTypes]?: number }
    metersEnd: { [key in UnitMeterTypes]?: number }
    purchaseOrderData: WoPurchaseOrderModel
    durationSeconds: number
    isAccessible: boolean
    salesOrderData: SalesOrderModel
    // in client only
    purchaseOrder: Identifier
    purchaseOrderTotal: string
    vendor: Identifier
    shop: Identifier
    shopData: ShopModel
    inventoryMode: boolean
    costCenterData: CostCenterModel
    divisionData: DivisionModel
    invoiceData: Omit<InvoiceModel, 'workOrderData'>

    unitOwner: never
}

export interface WorkOrderStatusPermissions {
    reopen: boolean
    close: boolean
    cancel: boolean
}

export type WorkOrderType = 'internal' | 'vendor'

export interface WorkOrderAggregates {
    total: number
}

export interface WithWo {
    workOrder: Identifier
    workOrderData: WorkOrderModel
}

export const enum WoStatusKeys {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}

export const enum WoPriorityKeys {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
}

export const enum WoRepairClassKeys {
    SCHEDULED = 'SCHEDULED',
    NON_SCHEDULED = 'NON_SCHEDULED',
    EMERGENCY = 'EMERGENCY',
}

export type WoKeys = NestedKeys<WorkOrderModel>
