import { type ReactElement, useState, type FC } from 'react'

import { useGetList } from 'react-admin'

import { type Identifier, Order, type WithChildrenNode } from 'appTypes'
import { UtilityDrawerNoResults, type UtilityDrawerProps, useOpenUtilityDrawer } from 'components'
import { getListData, perPageLimit } from 'core'
import { unitResource } from 'resources/units'
import { WoStatusKeys } from 'resources/workOrders'
import { booleanValue } from 'utils'

import { type DateHelpers, useCostReportContext } from '../../CostReportContext'

import TotalCost from './TotalCost'
import { DrilldownProvider, useDrilldownContext } from './context'
import { type Aggregates, type GetJobsModifiers, type ResponseType } from './types'

const useDrilldownDrawer = (
    drawerParams?: Pick<UtilityDrawerProps, 'title' | 'renderBelowHeader' | 'renderContent'>,
) => {
    const open = useOpenUtilityDrawer()
    const { dateHelpers, unitId } = useCostReportContext()

    return (modifiers: GetJobsModifiers, params?: { title?: string }) => {
        open({
            drawerArgs: {
                renderAboveFooter: () => <TotalCost />,
                renderBottomRight: () => null,
                renderBottomLeft: (render) => render({ children: 'Close' }),
                renderWrapper: ({ children }) => (
                    <Wrapper
                        modifiers={modifiers}
                        dateHelpers={dateHelpers}
                        unitId={unitId}
                    >
                        {children}
                    </Wrapper>
                ),
                ...drawerParams,
                renderContent: () => <Content>{drawerParams.renderContent?.()}</Content>,
                ...params,
            },
        })
    }
}

export default useDrilldownDrawer

interface WrapperProps extends WithChildrenNode {
    modifiers: GetJobsModifiers
    dateHelpers: DateHelpers
    className?: string
    unitId: Identifier
}

const defaultSort: GetJobsModifiers['sort'] = { field: 'dateCompleted', order: Order.DESC }

const Wrapper: FC<WrapperProps> = ({
    modifiers: modifiersProp,
    children,
    className,
    dateHelpers,
    unitId,
}) => {
    const [modifiers, setModifiers] = useState<GetJobsModifiers>(modifiersProp || {})
    const jobs = useGetList<ResponseType>(
        unitResource.jobsResource(unitId),
        {
            pagination: { page: 1, perPage: perPageLimit },
            filter: {
                ...modifiers?.filter,
                hasItems: booleanValue.positive,
                status: WoStatusKeys.CLOSED,
            },
            sort: { ...defaultSort, ...modifiers?.sort },
        },
        {
            keepPreviousData: true,
        },
    )

    const extraData = getListData<Aggregates>(jobs.pageInfo)

    return (
        <DrilldownProvider
            value={{
                data: jobs.data,
                jobsCount: jobs.total,
                updateModifiers: (newModifiers) =>
                    setModifiers((old) => ({ ...old, ...newModifiers })),
                isFetching: jobs.isFetching,
                isLoading: jobs.isLoading,
                modifiers,
                dateHelpers,
                ...extraData,
            }}
        >
            <div className={className}>{children}</div>
        </DrilldownProvider>
    )
}

const Content: FC<{ children: ReactElement }> = ({ children }) => {
    const { data, isLoading } = useDrilldownContext()

    if (isLoading) {
        return null
    }

    if (!data?.length) {
        return <NoData />
    }

    return children
}

const NoData: FC = () => {
    return (
        <UtilityDrawerNoResults
            title="No Cost Data Available"
            text=""
        />
    )
}
