import { type ReactNode } from 'react'

import { ListBase, type ListControllerProps, useShowContext } from 'react-admin'

import { type SortPayload } from 'core'
import { poResource, type ReceiptModel, type PurchaseOrderModel } from 'resources/purchaseOrders'

interface ReceiptsListProps extends Omit<ListControllerProps, 'sort'> {
    children: ReactNode
    record?: PurchaseOrderModel
}

const defaultSort: SortPayload<ReceiptModel> = {
    field: 'created',
    order: 'DESC',
}

const ReceiptsList = ({ children, record: recordProp, ...rest }: ReceiptsListProps) => {
    const { record } = useShowContext()
    const _record = recordProp || record

    return (
        <ListBase
            sort={defaultSort}
            resource={`${poResource.resource}/${_record?.id}/receipts`}
            queryOptions={{ enabled: Boolean(_record) }}
            {...rest}
        >
            {children}
        </ListBase>
    )
}

export default ReceiptsList
