import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { DistanceLabel, resolveIntegerSpacedMask } from 'components'
import { createResource } from 'core'
import { type ThemeColorType } from 'lib'
import { IconElement, TextWithIcon } from 'ui'

import { type UnitSettings } from '../types'
import unitResource from '../unitResource'

import { type UnitMeterTypes, type AllMeterTypes } from './types'

export const getMetersResource = (unitId: Identifier) => {
    return createResource({
        resource: `${unitResource.resource}/${unitId}/meters`,
        name: 'unit-meters',
    })
}

export interface MeterConfig {
    Icon: FC
    name: string
    id: AllMeterTypes
    adornment: string | ReactElement
    color?: ThemeColorType
    bgcolor?: ThemeColorType
}

export const meterTypesConfig: { [key in AllMeterTypes]: MeterConfig } = {
    ENGINE_HOURS: {
        Icon: Icons.AvTimerOutlined,
        name: 'Engine Hours',
        id: 'ENGINE_HOURS',
        adornment: 'h',
        color: (theme) => theme.palette.success.main,
        bgcolor: (theme) => theme.palette.charts.green,
    },
    HUBOMETER: {
        Icon: Icons.Hubometer,
        name: 'Hubometer',
        id: 'HUBOMETER',
        adornment: <DistanceLabel variant="abbr" />,
        color: (theme) => theme.palette.primary.main,
    },
    APU_HOURS: {
        Icon: Icons.ApuHours,
        name: 'APU Hours',
        id: 'APU_HOURS',
        adornment: 'h',
        color: (theme) => theme.palette.charts.purple,
    },
    ODOMETER: {
        Icon: Icons.Odometer,
        name: 'Odometer',
        id: 'ODOMETER',
        adornment: <DistanceLabel variant="abbr" />,
        color: (theme) => theme.palette.primary.main,
    },
    TIME: { Icon: Icons.CalendarTodayOutlined, name: 'Time', id: 'TIME', adornment: '' },
}

export const meterTypesForUnit: UnitMeterTypes[] = [
    'ODOMETER',
    'ENGINE_HOURS',
    'HUBOMETER',
    'APU_HOURS',
]

export const renderMeter = (type: AllMeterTypes) => {
    const meter = meterTypesConfig[type]

    if (!meter) {
        return null
    }

    return <TextWithIcon icon={<IconElement component={meter.Icon} />}>{meter.name}</TextWithIcon>
}

export const meterValue = (value: number, type: AllMeterTypes) => {
    if (value == null) {
        return null
    }

    const meter = meterTypesConfig[type]

    if (!meter) {
        return null
    }

    return (
        <>
            {resolveIntegerSpacedMask(value)} {meter.adornment}
        </>
    )
}

export const getMeterTypeAdornment = (type: AllMeterTypes) => {
    return meterTypesConfig[type]?.adornment
}

export const meterTypeChoices: MeterConfig[] = [
    meterTypesConfig.ODOMETER,
    meterTypesConfig.ENGINE_HOURS,
    meterTypesConfig.HUBOMETER,
    meterTypesConfig.APU_HOURS,
]

export const getMeterConfig = (type: AllMeterTypes) => {
    return meterTypesConfig[type]
}

export const getMeterIcon = (type: AllMeterTypes) => {
    const Comp = meterTypesConfig[type]
    if (!Comp) {
        return null
    }
    return <Comp.Icon />
}

export const hasActiveMeters = (meters: UnitSettings): boolean => {
    return meters ? Object.values(meters).some((meter) => meter.tracked) : false
}
