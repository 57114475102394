import { type FC } from 'react'

import { useRecordContext } from 'core'
import { type InvoiceModel, InvoiceStatusKeys, invoicesResource } from 'resources/invoices'
import { PaymentsList, PaymentsPage } from 'resources/payments'

const InvoicePayments: FC = () => {
    const record = useRecordContext<InvoiceModel>()
    const readOnly = record?.status === InvoiceStatusKeys.CANCELED

    const id = record?.id

    const pamentMethod = record?.customerData.paymentMethod

    return (
        <PaymentsList
            resource={invoicesResource.payments(id)}
            enabled={Boolean(id)}
        >
            <PaymentsPage
                readOnly={readOnly}
                createDefaultValues={{ method: pamentMethod }}
            />
        </PaymentsList>
    )
}

export default InvoicePayments
