import { useShowContext } from 'core'
import { type PartModel } from 'resources/parts'

import CrossReferenceList from './CrossReferenceList'
import PartCrossReferenceSection from './PartCrossReferenceSection'

const PartCrossReference = () => {
    const { record } = useShowContext<PartModel>()
    return (
        <CrossReferenceList record={record}>
            <PartCrossReferenceSection />
        </CrossReferenceList>
    )
}

export default PartCrossReference
