import { type FC } from 'react'

import { useRecordContext } from 'core'
import { InfoBadge } from 'ui'

import { type UnitModel } from '../types'
import { isUnitArchived } from '../utils'

const UnitArchivedStatus: FC = () => {
    const record = useRecordContext<UnitModel>()

    if (!record || !isUnitArchived(record)) {
        return null
    }

    return <InfoBadge badgeContent="Archived" />
}

export default UnitArchivedStatus
