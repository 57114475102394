import { useRecordContext } from 'core'

import { type WorkOrderModel } from './types'

const useWoActionIsDisabled = ({
    workOrder,
    condition,
}: {
    noFlag?: boolean
    condition?: boolean
    workOrder?: WorkOrderModel
} = {}) => {
    const record = useRecordContext<WorkOrderModel>({ record: workOrder }) || ({} as WorkOrderModel)

    const isStatusClosed = condition ?? record.status === 'CLOSED'
    return isStatusClosed || !record.unitData.isAccessible
}

export default useWoActionIsDisabled
