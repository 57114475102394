import { Button } from 'ui'

const FilterApplyButton = () => {
    return (
        <Button
            size="large"
            variant="contained"
            type="submit"
        >
            APPLY
        </Button>
    )
}

export default FilterApplyButton
