import { useEffect } from 'react'

import { FormProvider, useForm, useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { UtilityDrawer } from 'components/UtilityDrawer'
import { useListContext } from 'core/list'
import { Button } from 'ui'

import { useListConfig, useListPure } from '../ListContext'

import Content from './SortDrawerContent'

import type { RaSortPayload, SortPayload } from 'core/types'

const ResetButton = () => {
    const { snap, state } = useListConfig()
    const { reset, watch } = useFormContext()
    const formValues = watch()

    return (
        <Button
            size="large"
            variant="text"
            color="error"
            disabled={state.functions.sort.areEqual(
                formValues as unknown as SortPayload,
                snap.config.sort,
            )}
            startIcon={<Icons.Refresh fontSize="large" />}
            onClick={() => {
                reset(state.config.sort)
            }}
        >
            RESET
        </Button>
    )
}

const ApplyButton = () => {
    const state = useListPure()
    const { setSort, sort } = useListContext()
    const { getValues } = useFormContext()

    return (
        <Button
            size="large"
            variant="contained"
            onClick={() => {
                const value = getValues() as unknown as SortPayload
                // RA sets the opposite order if the field is the same
                // prevent settings sort if the sort is the same
                if (!state.functions.sort.areEqual(value, sort as SortPayload)) {
                    setSort(value)
                }

                state.functions.sort.close()
            }}
        >
            APPLY
        </Button>
    )
}

const ListSortDrawer = () => {
    const { snap, state } = useListConfig()
    const { sort } = useListContext()

    const form = useForm<RaSortPayload>({ defaultValues: sort })

    useEffect(() => {
        if (snap.sort) {
            form.reset(sort)
        }
    }, [snap.sort])

    return (
        <FormProvider<RaSortPayload> {...form}>
            <UtilityDrawer
                onClose={state.functions.sort.close}
                open={snap.sort}
                setOpen={state.functions.sort.setOpen}
                title="Sort by"
                anchorBottomHeightSize="small"
                renderContent={() => <Content />}
                renderBottomLeft={() => {
                    return <ResetButton />
                }}
                renderBottomRight={() => {
                    return <ApplyButton />
                }}
            />
        </FormProvider>
    )
}

export default ListSortDrawer
