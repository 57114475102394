import { type FC } from 'react'

import images from 'assets/images'
import { NoResultsCard } from 'components'
import { Anchor, Box } from 'ui'

import PaymentDrawerToggler from './PaymentDrawerToggler'
import { type CreateDefaultValues } from './types'

interface Props extends CreateDefaultValues {
    readOnly?: boolean
    refund?: boolean
    invoicesUrl?: string
}

const PaymentsSectionEmpty: FC<Props> = ({
    invoicesUrl,
    refund,
    readOnly,
    createDefaultValues,
}) => {
    return (
        <NoResultsCard
            title="No Items"
            direction="row"
            height="180px"
            heightsm="280px"
            directionsm="column"
            imageSrc={images.noPayments}
            imageProps={{
                alt: 'No Items',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '23px',
                },
            }}
            action={
                readOnly ? null : (
                    <PaymentDrawerToggler
                        invoicesUrl={invoicesUrl}
                        defaultValues={createDefaultValues}
                        refund={refund}
                    >
                        {(open) => (
                            <Anchor
                                component={Box}
                                onClick={open}
                                variant="body2"
                            >
                                Add {refund ? 'Refund' : 'Payment'}
                            </Anchor>
                        )}
                    </PaymentDrawerToggler>
                )
            }
        />
    )
}

export default PaymentsSectionEmpty
