import { type NoInfer } from 'appTypes'
import { type FilterBy, type SortBy, type Column, type DataListDetails } from 'components'

import { type CustomerModel } from '../types'

import name from './Name'

const source = 'customer' as const
const label = 'Customer'

const value = (record: CustomerModel, withLink?: boolean) => {
    if (withLink) {
        return <name.valueWithLink {...record} />
    }
    return <name.Value record={record} />
}

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
    label: labelProp,
    withLink,
}: {
    id?: Source
    label?: string
    withLink?: boolean
    dataToRecord: (data) => CustomerModel
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => value(dataToRecord(row), withLink),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
    label: labelProp,
    withLink,
}: {
    id?: Source
    label?: string
    withLink?: boolean
    dataToRecord: (data) => CustomerModel
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label: labelProp || label,
        render: (_, data) => value(dataToRecord(data), withLink),
    }) as const satisfies DataListDetails<any, any>

const filter = ({ label: labelProp }: { label?: string } = {}) =>
    ({
        id: source,
        label: labelProp || label,
    }) as const satisfies FilterBy

const sort = ({ label: labelProp }: { label?: string } = {}) =>
    ({
        id: source,
        label: labelProp || label,
    }) as const satisfies SortBy

const self = {
    source,
    label,
    value,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}

export default self
