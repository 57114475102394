import { type Identifier, type BaseModel, type ActionBy } from 'appTypes'
import { type WithCreatedField } from 'resources/base'
import { type CustomerModel, type WithCustomer } from 'resources/customers'
import { type WithPaymentTerm } from 'resources/paymentTerms'
import {
    type PtLaborModel,
    type PtPartModel,
    type PtServiceModel,
    type PtTaxModel,
} from 'resources/pricingTiers'
import { type WithUnit } from 'resources/units'
import { type JobModel, type WithWo } from 'resources/workOrders'
import { type WithShop } from 'resourcesBase'

import { type InvoiceTax, type InvoiceItem, type InvoiceFee } from './items'
export interface InvoiceJobModel extends JobModel {
    workOrder: Identifier
    invoiceItems: InvoiceItem[]
}
export interface InvoiceModel
    extends BaseModel,
        WithCreatedField,
        WithShop,
        WithWo,
        WithCustomer,
        WithPaymentTerm,
        WithUnit {
    number: string
    type: InvoiceTypeKeys
    status: InvoiceStatusKeys
    dueDate: string
    invoiceDate: string
    closedOn: string
    amountPaid: number
    amountDue: number
    poNumber: string
    statusUpdatedBy: ActionBy
    statusUpdated: string
    cancellationReason: string
    itemsCount: number
    total: number
    totalFees: number
    totalLabor: number
    totalParts: number
    totalServices: number
    totalTax: number
    jobs: InvoiceJobModel[]
    directlyAdded: InvoiceItem[]
    pricingTiers: {
        laborTier: Pick<PtLaborModel, 'name' | 'isDefault'>
        partTier: Pick<PtPartModel, 'name' | 'isDefault'>
        serviceTier: Pick<PtServiceModel, 'name' | 'isDefault'>
        taxTier: Pick<PtTaxModel, 'name' | 'isDefault'>
        feeTiers: string
    }
    taxes: InvoiceTax[]
    fees: InvoiceFee[]
    removeFees: boolean
    removeTaxes: boolean
    totalOriginDirect: number
    totalOriginWorkOrder: number
}

export interface SalesOrderModel extends BaseModel, WithCreatedField, WithCustomer {
    canceledOn: string
    cancellationReason: string
    closedOn: string
    description: string
    invoiceData: InvoiceModel
    number: string
    poNumber: string
    type: InvoiceTypeKeys
    status: InvoiceStatusKeys
    customerData: CustomerModel
}

export enum InvoiceTypeKeys {
    STANDARD = 'STANDARD',
    WORK_ORDER = 'WORK_ORDER',
}

export enum InvoiceStatusKeys {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}
