import { forwardRef, useRef, type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { ErrorBoundary } from 'lib'

import { useWidgetContext } from '../GridLayout'

import { WidgetHeader, type WidgetHeaderProps, WidgetHeaderSkeleton } from './WidgetHeader'
import { type WidgetInfoProps, WidgetInfo, WidgetInfoSkeleton } from './WidgetInfo'
import {
    StyledWidgetContainer,
    WidgetBaseDragIndicator,
    WidgetBaseLink,
    WidgetBaseResizeIndicator,
} from './styled'
import { checkWidgetDimensions } from './useCollapseWidget'

// TODO: Rename p
export interface WidgetBaseProps {
    headerProps: Omit<WidgetHeaderProps, 'separator'>
    infoProps?: WidgetInfoProps
    link?: string
    separator?: WidgetHeaderProps['separator']
}
export interface WidgetSkeletonProps
    extends Omit<WidgetBaseProps, 'headerProps' | 'infoProps' | 'link' | 'separator' | 'children'> {
    disableInfo?: boolean
}
export const WidgetSkeleton = ({ disableInfo, ...rest }: WidgetSkeletonProps) => {
    const dimension = useWidgetContext()

    return (
        <WidgetBaseContainer {...rest}>
            <WidgetHeaderSkeleton />
            {!disableInfo && checkWidgetDimensions(dimension, ['lg', 'mdY']) ? (
                <WidgetInfoSkeleton />
            ) : null}
        </WidgetBaseContainer>
    )
}

const WidgetBase = forwardRef(
    (
        { link, headerProps, infoProps, separator, ...rest }: WidgetBaseProps,
        ref: React.MutableRefObject<HTMLDivElement>,
    ) => {
        const widgetRef = useRef<HTMLDivElement>()
        const currentRef = ref || widgetRef
        const dimension = useWidgetContext()

        return (
            <WidgetBaseContainer
                {...rest}
                ref={currentRef}
            >
                {link ? <WidgetBaseLink link={link} /> : null}
                <WidgetHeader
                    {...headerProps}
                    separator={separator}
                />
                {infoProps && checkWidgetDimensions(dimension, ['lg', 'mdY']) ? (
                    <WidgetInfo {...infoProps} />
                ) : null}
            </WidgetBaseContainer>
        )
    },
)

export default WidgetBase

interface Props extends WithChildrenNode {
    ref?: any
}

export const WidgetBaseContainer: FC<Props> = forwardRef(({ children, ...rest }: Props, ref) => {
    return (
        <StyledWidgetContainer
            {...rest}
            ref={ref as any}
        >
            <ErrorBoundary>
                <WidgetBaseDragIndicator />
                <WidgetBaseResizeIndicator />
                {children}
            </ErrorBoundary>
        </StyledWidgetContainer>
    )
})
