import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { DateInput, SelectInput } from 'components/inputs'
import { dateTimeRangeFormats, dateRanges, getDateRangeDates } from 'lib'

import { type FilterRenderProps } from '../../types'

interface Props extends FilterRenderProps {}

const FilterDateRangeValue: FC<Props> = ({ valueSource, dataSource }) => {
    const { setValue } = useFormContext()
    const sourceMin = valueSource + '.min'
    const sourceMax = valueSource + '.max'
    const sourcePre = dataSource + '.pre'

    const preChange = (event) => {
        // reset select input might return null
        const value = event?.target?.value
        if (!value) {
            return
        }

        const [from, to] = getDateRangeDates(value)
        setValue(sourceMin, from)
        setValue(sourceMax, to)
    }

    const minMaxChange = () => {
        setValue(sourcePre, null)
    }

    return (
        <div>
            <SelectInput
                onChange={preChange}
                choices={dateRanges}
                source={sourcePre}
                label="Predefined range"
            />

            <DateInput
                returnDateFormat={dateTimeRangeFormats.from}
                source={sourceMin}
                label="From"
                onChange={minMaxChange}
            />

            <DateInput
                returnDateFormat={dateTimeRangeFormats.to}
                source={sourceMax}
                label="To"
                onChange={minMaxChange}
            />
        </div>
    )
}

export default FilterDateRangeValue
