import { type NoInfer } from 'appTypes'
import { type SortBy, type FilterBy, type Column, PhotoButton, type Photos } from 'components'

import { type PartModel } from '../types'

import { partLongSource } from './utils'

const source = 'photosCount' satisfies keyof PartModel
const longSource = partLongSource(source)

const label = 'Photos'

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const getPartPhotos = (record: Photos) => {
    let photos = []
    for (let i = 0; i <= 9; i += 1) {
        if (record['photo' + i]) {
            photos = [...photos, record['photo' + i]]
        }
    }
    return photos
}

const tableColumn = <Source extends string = typeof source>({
    id,
    label: labelProp,
    getData,
}: {
    id?: Source
    label?: string
    getData: (data) => Photos
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => <PhotoButton files={getPartPhotos(getData(row))} />,
    }) as const satisfies Column

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({ id: (id || source) as NoInfer<Source>, label }) as const satisfies FilterBy

export const photos = {
    source,
    longSource,
    label,
    sort,
    filter,
    tableColumn,
    getPartPhotos,
}
