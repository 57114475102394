import { type NoInfer } from 'appTypes'
import { type FilterBy, FilterDateRangeValue, type SortBy, type Column } from 'components'
import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'workOrderCompleted' as const
const label = 'WO Completed on'

interface Data extends Pick<InvoiceModel, 'workOrderData'> {}

const value = (record: Data) =>
    record.workOrderData
        ? formatDate(record.workOrderData.completed, (format) => format.shortenedDateTime)
        : null

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    }) as const satisfies FilterBy

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const woCompletedDate = {
    source,
    label,
    value,
    filter,
    sort,
    tableColumn,
}

export default woCompletedDate
