import { type FC } from 'react'

import { CheckboxInput } from 'components'
import { getMeterConfig, meterTypesForUnit, type UnitSettings } from 'resources/units'
import { Alert } from 'ui'

const SettingsForm: FC<{ settings: UnitSettings }> = ({ settings }) => {
    return (
        <>
            <Alert
                severity="info"
                sx={{ mb: '20px' }}
            >
                Only the selected meters will be available for logging readings and creating PM
                intervals.
            </Alert>

            {meterTypesForUnit.map((meterType) => {
                const meterConfig = getMeterConfig(meterType)
                const disabled = settings[meterType]?.canUntrack === false

                return (
                    <CheckboxInput
                        key={meterType}
                        source={meterType}
                        label={meterConfig.name}
                        disabled={disabled}
                        defaultValue={settings[meterType]?.tracked}
                        helperText={
                            disabled
                                ? 'This meter is used in an active PM interval and cannot be disabled.'
                                : ''
                        }
                    />
                )
            })}
        </>
    )
}

export default SettingsForm
