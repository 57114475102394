import { type Identifier } from 'appTypes'
import { urls } from 'configs'
import { pathJoin } from 'utils'

import { type UnitMeterTypes } from './meters'
import { type UnitModel } from './types'

export const isUnitArchived = (unit: Pick<UnitModel, 'archived'>) => Boolean(unit?.archived)

export const unitAspects = {
    totals: 'totals',
    costPerMeter: 'cost_per_meter',
    downtime: 'downtime',
}

export const equipmentHasBodySource = 'equipmentHasBody' as keyof UnitModel

export const unitUrls = {
    getMeters: (unitId: Identifier, meterType?: UnitMeterTypes) =>
        pathJoin(urls.units, unitId, 'meters', meterType),
    meters: 'meters',
}
