import { type NoInfer } from 'appTypes'
import { type SortBy, type FilterBy, type Column, type DataListDetails } from 'components'
import { type AuthStore } from 'core'
import { type ShopModel } from 'resourcesBase'

import name from './name'

const source = 'shop' as const
const label = 'Repair Shop'

const value = (record: ShopModel, withLink?: boolean) => {
    if (withLink) {
        return name.valueWithLink(record)
    }

    return name.value(record)
}

const tableColumn = ({
    dataToRecord,
    withLink,
    withShopContext,
}: {
    dataToRecord: (data) => ShopModel
    withLink?: boolean
    withShopContext?: AuthStore
}) =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row), withLink),
        alwaysHidden: !shouldShow(withShopContext),
    }) as const satisfies Column

const dataCardRow = ({
    dataToRecord,
    withShopContext,
}: {
    dataToRecord: (data) => ShopModel
    withShopContext?: AuthStore
}) =>
    shouldShow(withShopContext)
        ? ({
              source,
              label,
              render: (_, data) => value(dataToRecord(data)),
          } as const satisfies DataListDetails<any, any>)
        : null

const filter = <Source extends string = 'shop'>({
    id,
    withShopContext,
}: { id?: Source; withShopContext?: AuthStore } = {}) =>
    shouldShow(withShopContext)
        ? ({
              id: (id || source) as NoInfer<Source>,
              label,
          } as const satisfies FilterBy)
        : null

const sort = ({ withShopContext }: { withShopContext?: AuthStore } = {}) =>
    shouldShow(withShopContext)
        ? ({
              id: source,
              label,
          } as const satisfies SortBy)
        : null

const shouldShow = (auth: AuthStore | undefined) => !auth?.shop

const self = {
    value,
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
    shouldShow,
    allSelectedText: 'All Shops Selected',
}

export default self
