import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import {
    arrayControllerBoxBackground,
    ArrayControllerDeleteButton,
    useArrayControllerContext,
    useArrayControllerElementContext,
} from 'components/ArrayController'
import { Box, BoxContainer, Stack, Typography } from 'ui'

import { type FilterRenderProps, type FilterBy } from '../types'

import FilterSourceInput from './FilterSourceInput'
import OperatorInput from './OperatorInput'
import { type ArrayExtra } from './types'
import { FilterValueInput } from './valueInputs'

const FilterBox: FC = () => {
    const { extra } = useArrayControllerContext()
    const { index } = useArrayControllerElementContext()
    const rowSource = `filters.${index}`
    const nameSource = `${rowSource}.name`
    const { watch, setValue } = useFormContext()
    const sourceValue = watch(nameSource)

    const { config: configBase, options } = (extra as ArrayExtra).getConfig(sourceValue)
    const config = configBase || ({} as FilterBy<any>)

    const dataSource = `values.${config.id as string}`
    const valueSource = `${dataSource}.value`

    const filterProps: FilterRenderProps = {
        filterName: config.id as string,
        valueSource,
        dataSource,
    }

    return (
        <Box
            bgcolor={arrayControllerBoxBackground}
            p="20px"
            mb="20px"
            className="filter-box"
        >
            <BoxContainer
                justifyContent="space-between"
                height="41px"
            >
                <Typography variant="subtitle2">Filter</Typography>
                {extra.isFilterEmpty ? null : (
                    <ArrayControllerDeleteButton controller={{ alwaysVisible: true }} />
                )}
            </BoxContainer>
            <Stack gap="16px">
                <FilterSourceInput
                    source={nameSource}
                    options={options}
                    onChange={() => {
                        setValue(dataSource, null)
                    }}
                />

                <div
                    key={sourceValue}
                    className="filter-value"
                >
                    {config.withOperator ? (
                        <OperatorInput source={`${dataSource}.operator`} />
                    ) : null}
                    {config.renderComponent ? (
                        config.renderComponent(filterProps)
                    ) : (
                        <FilterValueInput {...filterProps} />
                    )}
                </div>
            </Stack>
        </Box>
    )
}

export default FilterBox
