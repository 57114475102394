import { UtilityDrawer } from 'components/UtilityDrawer'

import { useListConfig } from '../ListContext'

import FilterContent from './FilterContent'
import FilterWrapper from './FilterWrapper'
import { FilterAddButton, FilterApplyButton, FilterClearButton } from './buttons'

const FilterDrawer = () => {
    const { snap, state } = useListConfig()

    return (
        <UtilityDrawer
            onForceClose={state.functions.filter.close}
            onClose={state.functions.filter.close}
            open={snap.filter.isOpen}
            setOpen={state.functions.filter.setOpen}
            title="Filters"
            anchorBottomHeightSize="big"
            renderContent={() => (snap.filter ? <FilterContent /> : null)}
            renderTopRight={() => <FilterAddButton />}
            renderWrapper={(params) => <FilterWrapper {...params} />}
            renderBottomLeft={() => <FilterClearButton />}
            renderBottomRight={() => <FilterApplyButton />}
        />
    )
}

export default FilterDrawer
