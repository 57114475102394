import { type NoInfer } from 'appTypes'
import { type FilterBy, FilterDateRangeValue, type Column, type DataListDetails } from 'components'
import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'closedOn' as const satisfies keyof InvoiceModel
const label = 'Closed on'

const value = (record: Pick<InvoiceModel, 'closedOn'>) =>
    formatDate(record.closedOn, (format) => format.shortenedDateTime)

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    }) as const satisfies FilterBy

const closedDate = {
    source,
    label,
    value,
    tableColumn,
    sort,
    filter,
    dataCardRow,
}

export default closedDate
