import { type NoInfer } from 'appTypes'
import { type FilterBy, type SortBy, type Column } from 'components'

import { type PartModel } from '../types'

import { partLongSource } from './utils'

const source = 'number' satisfies keyof PartModel
const longSource = partLongSource(source)

const label = 'Part Number'

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({ id: (id || source) as NoInfer<Source>, label }) as const satisfies FilterBy

const tableColumn = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToValue,
}: {
    id?: Source
    label?: string
    dataToValue: (data) => PartModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => dataToValue(row),
    }) as const satisfies Column

export const number = {
    source,
    longSource,
    label,
    sort,
    filter,
    tableColumn,
}
