import { ViewHeader } from 'components'
import { GridContainer, GridItemLayout, PageContent } from 'ui'

import { MetersSettingsSection } from './sections'

const UnitSettings = () => {
    return (
        <PageContent>
            <GridContainer>
                <GridItemLayout>
                    <ViewHeader title="Settings" />
                    <MetersSettingsSection />
                </GridItemLayout>
            </GridContainer>
        </PageContent>
    )
}

export default UnitSettings
