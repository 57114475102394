import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEdit } from 'components'
import { type Serializer, useDataProviderConfig, useShowContext } from 'core'
import { type CustomerPricingModel } from 'resources/customers'
import { ptLaborFields, ptServiceFields } from 'resources/pricingTiers'

import { CustomerPricingForm } from './CustomerPricingForm'
import { customerPricingResourceName, getDefaultOptionId, isIdDefaultOption } from './utils'
interface CustomerPricingProps {
    children: (params: { onClick: () => void }) => ReactElement
    resource: string
    record: CustomerPricingModel
}

export const CustomerPricingDrawerToggler: FC<CustomerPricingProps> = ({
    children,
    resource,
    record,
}: CustomerPricingProps) => {
    const open = useOpenUtilityDrawer()
    const { record: customer } = useShowContext()

    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Edit Customer Pricing',
                    renderWrapper: (params) => (
                        <Editor
                            params={params}
                            resource={resource}
                            customerId={customer.id}
                        />
                    ),
                    renderContent: () => <CustomerPricingForm />,
                },
                extraArgs: {
                    id: record.id,
                    resource: {
                        resource,
                        name: customerPricingResourceName,
                    },
                },
            })
        },
    })
}

const Editor: FC<{ resource: string; params: any; customerId: Identifier }> = ({
    resource,
    params,
    customerId,
}) => {
    useDataProviderConfig(resource, {
        getOne: {
            prepareDataAfterResponse: (data: CustomerPricingModel): CustomerPricingModel => {
                return {
                    ...data,
                    partTier: data.partTierData.isDefault
                        ? getDefaultOptionId(data.partTier)
                        : data.partTier,
                    laborTier: data.laborTierData.isDefault
                        ? getDefaultOptionId(data.laborTier)
                        : data.laborTier,
                    serviceTier: data.serviceTierData.isDefault
                        ? getDefaultOptionId(data.serviceTier)
                        : data.serviceTier,
                    taxTier: data.taxTierData.isDefault
                        ? getDefaultOptionId(data.taxTier)
                        : data.taxTier,
                }
            },
        },
    })

    return (
        <UtilityDrawerEdit
            {...params}
            serializer={(formData) => serializer(formData.isTaxExempt)}
            queryOptions={{
                meta: {
                    cacheKey: 'customer-pricing-editor',
                },
            }}
            defaultValues={(record: CustomerPricingModel) => {
                if (record) {
                    return {
                        customer: customerId,
                        percentageOnTotal: record.taxTierData.markupPercent,
                        [ptLaborFields.rate.source]: record.laborTierData.price,
                        [ptServiceFields.markup.source]: record.serviceTierData.markupPercent,
                    }
                }
            }}
        />
    )
}

const ptParse = (id: Identifier) => (isIdDefaultOption(id) ? null : id)

const serializer: (isTaxExempt: boolean) => Serializer<CustomerPricingModel> = (isTaxExempt) => {
    const baseSerializer: Serializer<CustomerPricingModel> = [
        { name: 'partTier', parse: ptParse },
        { name: 'laborTier', parse: ptParse },
        { name: 'serviceTier', parse: ptParse },
        { name: 'isTaxExempt', parse: 'boolean' },
    ]
    if (isTaxExempt) {
        return [...baseSerializer, 'taxExemptNumber']
    }
    return [...baseSerializer, { name: 'taxTier', parse: ptParse }]
}
