import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { ListController, ListUi, Header, ListTotalBadge, MainHeader } from 'components'
import { type AuthStore } from 'core'
import { PoTypeKeys, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { woResource } from 'resources/workOrders'
import { PageContent } from 'ui'

import { getPoConfigs } from './PurchaseOrdersList'

const VendorPoList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { filters, sorts, columnsConfig, cardsConfig } = getPoConfigs(auth, navigateTo)

        return (
            <ListController
                filter={{
                    type: PoTypeKeys.WORK_ORDER,
                }}
                sorts={sorts}
                filters={filters}
            >
                <MainHeader>
                    <Header.Info>
                        <Header.Content>
                            <Header.Title>Purchase Orders</Header.Title>
                        </Header.Content>
                        <Header.Content mobilePlacement="bottom">
                            <ListTotalBadge />
                        </Header.Content>
                    </Header.Info>
                </MainHeader>
                <PageContent>
                    <ListUi
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        listFTUProps={{
                            linkText: null,
                        }}
                    />
                </PageContent>
            </ListController>
        )
    }),
)

export default VendorPoList

const navigateTo = (record: PurchaseOrderModel) =>
    `${woResource.resource}/${record.workOrder}/invoice`
