import { useRef, type FC } from 'react'

import { useCreatePath } from 'react-admin'

import { useListController } from 'core'
import { cssUtils, globalClassNames, Link } from 'lib'
import { useResources } from 'resources'
import { unitResource, unitStatusResource, type UnitStatusModel } from 'resources/units'
import { Anchor, Box, BoxContainer, Scrollbar, Stack, Typography } from 'ui'

import { WidgetListContainer, WidgetTitle, WidgetBaseContainer } from '../../WidgetBase'
import { WidgetSkeleton } from '../../WidgetBase/WidgetBase'

const UnitStatusesWidget = () => {
    const listController = useListController<UnitStatusModel>({
        resource: unitStatusResource.resource,
        filter: { activeOnly: true },
        perPage: 1000,
    })
    const data = listController.data

    const widgetRef = useRef<HTMLDivElement>()

    if (!data) {
        return <WidgetSkeleton />
    }

    return (
        <WidgetBaseContainer ref={widgetRef}>
            <Box
                sx={{
                    ...cssUtils.applyPadding('20px'),
                    boxSizing: 'border-box',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <WidgetTitle mb="11px">Unit Statuses</WidgetTitle>

                <Scrollbar
                    replaceP="xb"
                    alwaysVisible
                    shadow="t"
                    scrollbarMinSize={50}
                >
                    <StatusesList data={data} />
                </Scrollbar>
            </Box>
        </WidgetBaseContainer>
    )
}

export default UnitStatusesWidget

const StatusesList: FC<{ data: UnitStatusModel[] }> = ({ data }) => {
    const { unitStatus } = useResources()
    const createPath = useCreatePath()

    return (
        <WidgetListContainer sx={{ gap: '8px' }}>
            {data.map((status) => {
                return (
                    <Typography
                        component={Stack}
                        key={status.id}
                        direction="row"
                        variant="chartsBody"
                        justifyContent="space-between"
                    >
                        <BoxContainer
                            sx={{
                                pr: '10px',
                            }}
                            className={globalClassNames.ellipsis}
                        >
                            {unitStatus.value(status)}
                        </BoxContainer>
                        <Anchor
                            component={Link}
                            to={
                                createPath({ resource: unitResource.resource, type: 'list' }) +
                                '?filter=' +
                                encodeURIComponent(
                                    JSON.stringify({
                                        [unitStatus.source]: [status.id],
                                    }),
                                )
                            }
                        >
                            {status.usage || 0}
                        </Anchor>
                    </Typography>
                )
            })}
        </WidgetListContainer>
    )
}
