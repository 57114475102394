import { displayBooleanValue } from 'utils'

export const excludeNulls = { excludeNulls: displayBooleanValue(true) }

export const getFilterReference = (base: string, source: string, itemReference?: string) =>
    `filter::${base}||${source}${itemReference ? `||${itemReference}` : ''}`

export const isFilterReference = (resource: string) => {
    return resource?.startsWith('filter::')
}

export const prepareFilterResource = (resource: string, item?: boolean) => {
    const parts = resource.split('filter::')[1].split('||')
    if (item) {
        return parts[2]
    }

    return parts[0] + '/filter_values'
}

export const createResource = <ResourceType extends string, NameType extends string>({
    name,
    resource,
}: {
    name: NameType
    resource: ResourceType
}) => {
    return {
        name,
        resource,
    } as const
}

export interface ResourceConfig {
    name: string
    resource: string
    [key: string]: any
}
